// Imports
import React, { useState, useEffect, useRef } from 'react';
import './projectsviewview.scss';

// Components
import Meta from '../../../Meta';
import Table from '../../../Table';
import Spinner from '../../../spinners/Spinner';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import validate from '../../../../utils/validate';

// ProjectsViewView component
const ProjectsViewView = () => {

    // Refs
    const spinnerRef = useRef();

    // State
    const [projects, setProjects] = useState([]);
    const [projectsStatic, setProjectsStatic] = useState([]);

    // Get projects
    useEffect(() => {

        getProjects();

    }, []);

    // Get projects
    const getProjects = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/projects', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.projects, 'name', 'asc');
            setProjects(formatted);
            setProjectsStatic(formatted);
            notification.success('Projects loaded.');
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // User clicked
    const tableRowClickedCallback = (project) => {

        window.location.href = `/project/${project._id}`;

    };

    // Search input changed
    const searchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = projectsStatic;

        } else {

            projectsStatic.forEach((project) => {

                // Check name
                if (project.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

                // Check client
                if (project.client.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

                // Check venue
                if (project.venue.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

                // Check start date
                if (project.startDate.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

                // Check end date
                if (project.endDate.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

                // Check status
                if (project.status.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === project._id)) {
                        results.push(project);
                    }
                }

            });

        }

        setProjects(results);

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Projects - View"
            />

            <h1>Projects</h1>

            <Table headerItems={[
                {
                    title: "Name",
                    width: "290px",
                    isSortable: true,
                },
                {
                    title: "Client",
                    width: "310px",
                    isSortable: true,
                },
                {
                    title: "Venue",
                    width: "210px",
                    isSortable: true,
                },
                {
                    title: "Start Date",
                    width: "100px",
                    isSortable: true,
                },
                {
                    title: "End Date",
                    width: "100px",
                    isSortable: true,
                },
                {
                    title: "Status",
                    width: "80px",
                    isSortable: true,
                }
            ]}
            data={projects}
            tableRowClickedCallback={tableRowClickedCallback}
            searchInputChangedCallback={searchInputChangedCallback}
            reloadCallback={() => { getProjects(true); }}
            />

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

)

};

// Export Component
export default ProjectsViewView;
