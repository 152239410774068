// Full name
const fullName = (value) => {

    if (value === "" || value.indexOf(' ') < 0) {
        return false;
    }

    return true;

}

// Email
const email = (value) => {

    return value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

}

// Phone
const phone = (value) => {

    if (value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
        return true
    }

    return false;

};

// Password
const password = (value) => {

    let chars8 = false;
    let lowercase = false;
    let uppercase = false;
    let number = false;
    let special = false;

    // 8 chars
    if (value.length >= 8) {
        chars8 = true;
    }

    // lowercase
    if (value.match(/[a-z]/)) {
        lowercase = true;
    }

    // uppercase
    if (value.match(/[A-Z]/)) {
        uppercase = true;
    }

    // number
    if (value.match(/[0-9]/)) {
        number = true;
    }

    // special char
    if (value.match(/[!@#$%^&*(),.?":{}|<>]/)) {
        special = true;
    }

    return {
        chars8: chars8,
        lowercase: lowercase,
        uppercase: uppercase,
        number: number,
        special: special
    }

}

export default {
    fullName: fullName,
    email: email,
    phone: phone,
    password: password
}