// Imports
import React, { useState, useEffect, useRef } from 'react';
import './clientsviewview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import SubmitButton from '../../../buttons/SubmitButton';
import Table from '../../../Table';
import TableDataPopup from '../../../popups/TableDataPopup';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import validate from '../../../../utils/validate';

// ClientsViewView component
const ClientsViewView = () => {

    // Refs
    const spinnerRef = useRef(null);
    const nameRef = useRef(null);
    const companyRef = useRef(null);
    const emailRef = useRef(null);
    const invoiceEmailRef = useRef(null);
    const phoneRef = useRef(null);
    const address1Ref = useRef(null);
    const address2Ref = useRef(null);
    const addressCityRef = useRef(null);
    const addressCountyRef = useRef(null);
    const addressPostcodeRef = useRef(null);
    const statusRef = useRef(null);

    // State
    const [clients, setClients] = useState([]);
    const [clientsStatic, setClientsStatic] = useState([]);
    const [selectedClientDetailsEditing, setSelectedClientDetailsEditing] = useState(false);
    const [selectedClientIsOpen, setSelectedClientIsOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});

    // Get clients
    useEffect(() => {

        getClients();

    }, []);

    const getClients = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/clients', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.clients, 'name', 'asc');
            setClients(formatted);
            setClientsStatic(formatted);
            notification.success('Clients loaded.');
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Client clicked
    const tableRowClickedCallback = (client) => {

        if (client.address.line1 || client.address.line2 || client.address.city || client.address.county || client.address.postcode) {
            client.hasAddress = true;
        } else {
            client.hasAddress = false;
        }

        setSelectedClient(client);
        setSelectedClientIsOpen(true);

    };

    // Close selected client
    const closeSelectedClient = () => {

        setSelectedClient({});
        setSelectedClientIsOpen(false);

    }

    // Search input changed
    const searchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = clientsStatic;

        } else {

            clientsStatic.forEach((client) => {

                // Check name
                if (client.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === client.email)) {
                        results.push(client);
                    }
                }

                // Check email
                if (client.email.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === client.email)) {
                        results.push(client);
                    }
                }

                // Check phone
                if (client.phone.includes(value)) {
                    if (!results.some(x => x.email === client.email)) {
                        results.push(client);
                    }
                }

                // Check company
                if (client.company.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === client.email)) {
                        results.push(client);
                    }
                }

            });

        }

        setClients(results);

    };

    // Update client callback
    const updateClientCallback = async () => {

        // Get values
        let name = nameRef.current.value;
        let company = companyRef.current.value;
        let email = emailRef.current.value;
        let invoiceEmail = invoiceEmailRef.current.value;
        let phone = phoneRef.current.value;
        let address1 = address1Ref.current.value;
        let address2 = address2Ref.current.value;
        let addressCity = addressCityRef.current.value;
        let addressCounty = addressCountyRef.current.value;
        let addressPostcode = addressPostcodeRef.current.value;
        let status = statusRef.current.value;

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(name)) {
            notification.error("Please enter a valid full name");
            readyToSubmit = false;
        }

        if (!validate.email(email)) {
            notification.error("Please enter a valid email address");
            readyToSubmit = false;
        }

        if (!validate.email(invoiceEmail)) {
            notification.error("Please enter a valid invoice email address");
            readyToSubmit = false;
        }

        if (!validate.phone(phone)) {
            notification.error("Please enter a valid phone number");
            readyToSubmit = false;
        }

        if (!status) {
            notification.error("Please select a status");
            readyToSubmit = false;
        }

        // Submit

        if (readyToSubmit) {

            spinnerRef.current.show();

            let client = {
                _id: selectedClient._id,
                name: name,
                company: company,
                email: email,
                invoiceEmail: invoiceEmail,
                phone: phone,
                address: {
                    line1: address1,
                    line2: address2,
                    city: addressCity,
                    county: addressCounty,
                    postcode: addressPostcode
                },
                status: status
            }

            let response = await api.post('/clients/update', {
                client: client
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Client updated.");
                    spinnerRef.current.hide();

                    if (client.address.line1 || client.address.line2 || client.address.city || client.address.county || client.address.postcode) {
                        client.hasAddress = true;
                    } else {
                        client.hasAddress = false;
                    }

                    setSelectedClient(client);
                    setSelectedClientDetailsEditing(false);

                }

            } else {

                notification.error("Error updating client.");
                spinnerRef.current.hide();

            }

        }

    }

    // Return component
    return (

        <div className="view">

            <Meta
                title="Clients - View"
            />

            <h1>Clients</h1>

            <Table headerItems={[
                {
                    title: "Name",
                    width: "240px",
                    isSortable: true,
                },
                {
                    title: "Email",
                    width: "300px",
                    isSortable: true,
                },
                {
                    title: "Phone",
                    width: "150px",
                    isSortable: true,
                },
                {
                    title: "Company",
                    width: "300px",
                    isSortable: true,
                },
                {
                    title: "Status",
                    width: "90px",
                    isSortable: true,
                }
            ]} 
            data={clients} 
            tableRowClickedCallback={tableRowClickedCallback} 
            searchInputChangedCallback={searchInputChangedCallback} 
            reloadCallback={() => { getClients(true); }}
            />

            {selectedClientIsOpen ?

                <TableDataPopup closeCallback={closeSelectedClient} isEditingState={selectedClientDetailsEditing} setIsEditingState={setSelectedClientDetailsEditing}>

                    {selectedClientDetailsEditing ?

                    <div className="tdp-section">

                        <TextInput title="Full Name" type="text" ref={nameRef} initialValue={selectedClient.name} />

                        <TextInput title="Company" type="text" ref={companyRef} initialValue={selectedClient.company} />

                        <TextInput title="Email" type="email" ref={emailRef} initialValue={selectedClient.email} />

                        <TextInput title="Invoice Email" type="email" ref={invoiceEmailRef} initialValue={selectedClient.invoiceEmail} />

                        <TextInput title="Phone Number" type="tel" ref={phoneRef} initialValue={selectedClient.phone} />

                        <TextInput title="Address" placeholder="1st Line of Address" type="text" ref={address1Ref} initialValue={selectedClient.address.line1} />

                        <TextInput title="2nd Line of Address" type="text" ref={address2Ref} hideLabel initialValue={selectedClient.address.line2} />

                        <TextInput title="City" type="text" ref={addressCityRef} hideLabel initialValue={selectedClient.address.city} />

                        <TextInput title="County" type="text" ref={addressCountyRef} hideLabel initialValue={selectedClient.address.county} />

                        <TextInput title="Postcode" type="text" ref={addressPostcodeRef} hideLabel initialValue={selectedClient.address.postcode} />

                        <SelectInput title="Status" options={[{value: "active", title: "Active"}, {value: "archived", title: "Archived"}]} ref={statusRef} initialValue={selectedClient.status} />

                        <SubmitButton title="Update" callback={updateClientCallback} />

                    </div>

                    :

                    <div className="tdp-section">

                        <span><strong>Name:</strong> {selectedClient.name}</span>

                        <span><strong>Company:</strong> {selectedClient.company}</span>

                        <span><strong>Email:</strong> <a href={`mailto:${selectedClient.email}`}>{selectedClient.email}</a></span>

                        <span><strong>Invoice Email:</strong> <a href={`mailto:${selectedClient.invoiceEmail}`}>{selectedClient.invoiceEmail}</a></span>

                        <span><strong>Phone:</strong> <a href={`tel:${selectedClient.phone}`}>{selectedClient.phone}</a></span>

                        <span><strong>Address:</strong></span>

                        {(selectedClient.hasAddress) ?

                            <div className="tdp-section-address">

                                {selectedClient.address.line1 ? <span>{selectedClient.address.line1}</span> : ''}
                                {selectedClient.address.line2 ? <span>{selectedClient.address.line2}</span> : ''}
                                {selectedClient.address.city ? <span>{selectedClient.address.city}</span> : ''}
                                {selectedClient.address.county ? <span>{selectedClient.address.county}</span> : ''}
                                {selectedClient.address.postcode ? <span>{selectedClient.address.postcode}</span> : ''}

                            </div>

                        :

                            <span>No Address</span>

                        }

                        <span><strong>Status:</strong> {selectedClient.status}</span>

                    </div>

                    }

                </TableDataPopup>

            :
                ''
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default ClientsViewView;
