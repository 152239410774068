// Imports
import React from 'react';
import './homepage.scss';

// Components
import Meta from '../../Meta';
import NavigationCards from '../../cards/NavigationCards';

// Assets
import CrewIconPrimary from '../../../assets/icons/crew-icon-primary.png';
import StockIconPrimary from '../../../assets/icons/stock-icon-primary.png';
import ClientsIconPrimary from '../../../assets/icons/clients-icon-primary.png';
import ProjectsIconPrimary from '../../../assets/icons/projects-icon-primary.png';
import SettingsIconPrimary from '../../../assets/icons/settings-icon-primary.png';
import UsersIconPrimary from '../../../assets/icons/users-icon-primary.png';

// Utils
import session from '../../../utils/session';

// HomePage component
const HomePage = () => {

	let sessionData = session.get();
	let permissions = sessionData.user.permissions;

	let navCards = [];
	if (permissions.crew) {
		navCards.push({
			title: "Crew",
			icon: CrewIconPrimary,
			link: "/crew"
		});
	}
	if (permissions.stock) {
		navCards.push({
			title: "Stock",
			icon: StockIconPrimary,
			link: "/stock"
		});
	}
	if (permissions.clients) {
		navCards.push({
			title: "Clients",
			icon: ClientsIconPrimary,
			link: "/clients"
		});
	}
	if (permissions.projects) {
		navCards.push({
			title: "Projects",
			icon: ProjectsIconPrimary,
			link: "/projects"
		});
	}
	if (permissions.users) {
		navCards.push({
			title: "Users",
			icon: UsersIconPrimary,
			link: "/users"
		});
	}
	navCards.push({
		title: "Settings",
		icon: SettingsIconPrimary,
		link: "/settings"
	});

	// Return component
	return (

		<div className="home-page">

			<Meta
				title="Home"
			/>

			<NavigationCards cards={navCards} />

		</div>

    )

};

// Export Component
export default HomePage;
