// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './selectinput.scss';

// Components
import Meta from '../../Meta';

// Assets
import ReloadIcon from '../../../assets/icons/reload-icon-primary.png';

// SelectInput component
const SelectInput = React.forwardRef((props, ref) => {

    // State
    const [value, setValue] = useState(props.value);

    useEffect(() => {

        if (props.initialValue) {
            setValue(props.initialValue);
        }

    }, []);

    // On change
    const onChangeHandle = () => {

        setValue(ref.current.value)
        if (props.onChangeCallback) {
            props.onChangeCallback(ref.current.value);
        }

    }

    // Return component
    return (

        <div className="selectinput">

            <div className={props.hideLabel ? "text-input-hideLabel" : "text-input"}>

                {props.hideLabel ? null : 
                    <span>{props.title}: {props.showReload && <img src={ReloadIcon} alt="Reload" onClick={() => { props.reloadCallback(); }} />}</span>
                }

                <select ref={ref} value={value} onChange={() => { onChangeHandle(); }}>
                    {props.options.map((option, index) => (
                        <option value={option.value} key={index}>{option.title}</option>
                    ))}
                </select>

            </div>

        </div>

    )

});

SelectInput.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    hideLabel: PropTypes.bool,
    initialValue: PropTypes.string,
    showReload: PropTypes.bool,
    reloadCallback: PropTypes.func,
    onChangeCallback: PropTypes.func
}

// Export Component
export default SelectInput;
