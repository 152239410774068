// Imports
import React, { useState, useEffect, useRef } from 'react';
import './crewviewview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';
import TextInput from '../../../inputs/TextInput';
import CheckboxInput from '../../../inputs/CheckboxInput';
import RadioInput from '../../../inputs/RadioInput';
import SubmitButton from '../../../buttons/SubmitButton';
import Table from '../../../Table';
import TableDataPopup from '../../../popups/TableDataPopup';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import validate from '../../../../utils/validate';

// CrewViewView component
const CrewViewView = () => {

    // Data
    let licencesDefault = [
        {
            value: "Car",
            isChecked: false,
        },
        {
            value: "Class 1 HGV",
            isChecked: false,
        },
        {
            value: "Class 2 HGV",
            isChecked: false,
        },
        {
            value: "Straight Mast Forklift",
            isChecked: false,
        },
        {
            value: "Telehandler",
            isChecked: false,
        },
        {
            value: "Cherry Picker",
            isChecked: false,
        }
    ];

    let skillsDefault = [
        {
            value: "Carpentry",
            isChecked: false,
        },
        {
            value: "Plumbing",
            isChecked: false,
        },
        {
            value: "Electrical",
            isChecked: false,
        },
        {
            value: "Metalwork",
            isChecked: false,
        },
        {
            value: "Site Management",
            isChecked: false,
        },
        {
            value: "Safety Officer",
            isChecked: false,
        },
        {
            value: "Fencing (Heras, Met, Ped)",
            isChecked: false,
        },
        {
            value: "Pit Barrier (Mojo)",
            isChecked: false,
        },
        {
            value: "Staging",
            isChecked: false,
        },
        {
            value: "Scaffolding",
            isChecked: false,
        },
        {
            value: "Rigging",
            isChecked: false,
        },
        {
            value: "Lighting, Audio, Video & Other Tech",
            isChecked: false,
        }
    ];

    let hasCarDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        }
    ];

    let lastMinuteDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        }
    ];

    let campingDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        },
        {
            value: "Maybe",
            isChecked: false
        }
    ];

    let prefersDefaults= [
        {
            value: "Single Day Jobs",
            isChecked: false,
        },
        {
            value: "Short Jobs (1-3 Days)",
            isChecked: false,
        },
        {
            value: "Long Jobs (4-10 Days)",
            isChecked: false,
        },
        {
            value: "Any length",
            isChecked: false,
        }
    ];

    // Refs
    const spinnerRef = useRef(null);
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const locationRef = useRef();
    const ageRef = useRef();
    const licencesOtherRef = useRef();
    const skillsOtherRef = useRef();

    // State
    const [licences, setLicences] = useState(licencesDefault);
    const [skills, setSkills] = useState(skillsDefault);
    const [hasCar, setHasCar] = useState(hasCarDefault);
    const [lastMinute, setLastMinute] = useState(lastMinuteDefault);
    const [camping, setCamping] = useState(campingDefault);
    const [prefers, setPrefers] = useState(prefersDefaults);
    const [crew, setCrew] = useState([]);
    const [crewStatic, setCrewStatic] = useState([]);
    const [selectedCrewDetailsEditing, setSelectedCrewDetailsEditing] = useState(false);
    const [selectedCrewIsOpen, setSelectedCrewIsOpen] = useState(false);
    const [selectedCrew, setSelectedCrew] = useState({});

    // Get crew
    useEffect(() => {

        getCrew();

    }, []);

    // Get crew
    const getCrew = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/crew', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.crew, 'name', 'asc');
            setCrew(formatted);
            setCrewStatic(formatted);
            notification.success("Crew loaded.");
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Close selected crew
    const closeSelectedCrew = () => {

        setSelectedCrew({});
        setLicences(licencesDefault);
        setSkills(skillsDefault);
        setHasCar(hasCarDefault);
        setLastMinute(lastMinuteDefault);
        setCamping(campingDefault);
        setPrefers(prefersDefaults);
        setSelectedCrewIsOpen(false);

    }

    // Search input changed callback
    const searchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = crewStatic;

        } else {

            crewStatic.forEach((crewMember) => {

                // Check name
                if (crewMember.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === crewMember.email)) {
                        results.push(crewMember);
                    }
                }

                // Check email
                if (crewMember.email.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === crewMember.email)) {
                        results.push(crewMember);
                    }
                }

                // Check phone
                if (crewMember.phone.includes(value)) {
                    if (!results.some(x => x.email === crewMember.email)) {
                        results.push(crewMember);
                    }
                }

                // Check location
                if (crewMember.location.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === crewMember.email)) {
                        results.push(crewMember);
                    }
                }

            });

        }

        setCrew(results);

    }

    // Selected crew delete
    const selectedCrewDelete = async () => {

        if (window.confirm(`Are you sure you want to delete ${selectedCrew.name}? This cannot be undone and will complete remove them from the system.`)) {

            spinnerRef.current.show();

            let response = await api.post('/crew/delete', {
                crew: selectedCrew
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Crew member deleted.");
                    spinnerRef.current.hide();
                    let crewIndex = crew.findIndex(x => x._id === selectedCrew._id);
                    let crewStaticIndex = crewStatic.findIndex(x => x._id === selectedCrew._id);
                    let tempCrew = crew;
                    let tempCrewStatic = crewStatic;
                    if (crewIndex > -1) {
                        tempCrew.splice(crewIndex, 1);
                    }
                    if (crewStaticIndex > -1) {
                        tempCrewStatic.splice(crewStaticIndex, 1);
                    }
                    setCrew(tempCrew);
                    setCrewStatic(tempCrewStatic);
                    closeSelectedCrew();

                }

            } else {

                notification.error("Error deleting crew member.");
                spinnerRef.current.hide();

            }

        }

    };

     // Licences checkbox callback
     const licencesCheckboxCallback = (index) => {

        // Update licences
        licences[index].isChecked = !licences[index].isChecked;
        setLicences([...licences]);

    }

    // Skills checkbox callback
    const skillsCheckboxCallback = (index) => {

        skills[index].isChecked = !skills[index].isChecked;
        setSkills([...skills]);

    }

    // Has car radio callback
    const hasCarRadioCallback = (index) => {

        hasCar.forEach((item, i) => {

            hasCar[i].isChecked = false;
            if (i === index) {
                hasCar[i].isChecked = true;
            }
            setHasCar([...hasCar]);

        });

    }

    // Last minute radio callback
    const lastMinuteRadioCallback = (index) => {

        lastMinute.forEach((item, i) => {

            lastMinute[i].isChecked = false;
            if (i === index) {
                lastMinute[i].isChecked = true;
            }
            setLastMinute([...lastMinute]);

        });

    }

    // Camping radio callback
    const campingRadioCallback = (index) => {

        camping.forEach((item, i) => {

            camping[i].isChecked = false;
            if (i === index) {
                camping[i].isChecked = true;
            }
            setCamping([...camping]);

        });

    }

    // Prefers radio callback
    const prefersRadioCallback = (index) => {

        prefers.forEach((item, i) => {

            prefers[i].isChecked = false;
            if (i === index) {
                prefers[i].isChecked = true;
            }
            setPrefers([...prefers]);

        });

    }

    // Update crew member callback
    const updateCrewMemberCallback = async () => {

        // Get values
        let nameValue = nameRef.current.value;
        let emailValue = emailRef.current.value;
        let phoneValue = phoneRef.current.value;
        let locationValue = locationRef.current.value;
        let ageValue = ageRef.current.value;
        let licencesValue = [];
        let licencesOtherValue = licencesOtherRef.current.value;
        let skillsValue = [];
        let skillsOtherValue = skillsOtherRef.current.value;
        let hasCarValue = "";
        let lastMinuteValue = "";
        let campingValue = "";
        let prefersValue = "";

        licences.forEach((item) => {
            if (item.isChecked) {
                licencesValue.push(item.value);
            }
        });

        skills.forEach((item) => {
            if (item.isChecked) {
                skillsValue.push(item.value);
            }
        });

        hasCar.forEach((item) => {
            if (item.isChecked) {
                hasCarValue = item.value;
            }
        });

        lastMinute.forEach((item) => {
            if (item.isChecked) {
                lastMinuteValue = item.value;
            }
        });

        camping.forEach((item) => {
            if (item.isChecked) {
                campingValue = item.value;
            }
        });

        prefers.forEach((item) => {
            if (item.isChecked) {
                prefersValue = item.value;
            }
        });

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(nameValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid full name.");
        }

        if (!validate.email(emailValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid email address.");
        }

        if (!validate.phone(phoneValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid phone number.");
        }

        if (!locationValue) {
            readyToSubmit = false;
            notification.error("Please enter a location.");
        }

        // Submit
        let crew = {
            _id: selectedCrew._id,
            name: nameValue,
            email: emailValue,
            phone: phoneValue,
            location: locationValue,
            age: ageValue,
            licences: licencesValue,
            licencesOther: licencesOtherValue,
            skills: skillsValue,
            skillsOther: skillsOtherValue,
            hasCar: hasCarValue,
            lastMinute: lastMinuteValue,
            camping: campingValue,
            prefers: prefersValue
        };

        if (readyToSubmit) {

            spinnerRef.current.show();

            let response = await api.post('/crew/update', {
                crew: crew
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Crew member updated.");
                    spinnerRef.current.hide();
                    setSelectedCrew(crew);
                    setSelectedCrewDetailsEditing(false);

                }

            } else {

                notification.error("Error adding crew member.");
                spinnerRef.current.hide();

            }

        }

    }

    // Table row clicked callback
    const tableRowClickedCallback = (crewMember) => {

        setSelectedCrew(crewMember);

        // Set licences
        let crewLicences = licencesDefault;
        crewLicences.forEach((licence, index) => {
            if (crewMember.licences.includes(licence.value)) {
                crewLicences[index].isChecked = true;
            }
        });
        setLicences(crewLicences);

        // Set skills
        let crewSkills = skillsDefault;
        crewSkills.forEach((skill, index) => {
            if (crewMember.skills.includes(skill.value)) {
                crewSkills[index].isChecked = true;
            }
        });
        setSkills(crewSkills);

        // Set has car
        let crewHasCar = hasCarDefault;
        if (crewMember.hasCar === "Yes") {
            crewHasCar[0].isChecked = true;
        } else {
            crewHasCar[1].isChecked = true;
        }
        setHasCar(crewHasCar);

        // Set last minute
        let crewLastMinute = lastMinuteDefault;
        if (crewMember.lastMinute === "Yes") {
            crewLastMinute[0].isChecked = true;
        } else {
            crewLastMinute[1].isChecked = true;
        }
        setLastMinute(crewLastMinute);

        // Set camping
        let crewCamping = campingDefault;
        if (crewMember.camping === "Yes") {
            crewCamping[0].isChecked = true;
        } else if (crewMember.camping === "No") {
            crewCamping[1].isChecked = true;
        } else {
            crewCamping[2].isChecked = true;
        }
        setCamping(crewCamping);

        // Set prefers
        let crewPrefers = prefersDefaults;
        if (crewMember.prefers === "Single Day Jobs") {
            crewPrefers[0].isChecked = true;
        } else if (crewMember.prefers === "Short Jobs (1-3 Days)") {
            crewPrefers[1].isChecked = true;
        } else if (crewMember.prefers === "Long Jobs (4-10 Days)") {
            crewPrefers[2].isChecked = true;
        } else {
            crewPrefers[3].isChecked = true;
        }
        setPrefers(crewPrefers);

        setSelectedCrewIsOpen(true);

    };

    // Favourite callback
    const favouriteCallback = async (crewMember) => {

        let favourite = crewMember.favourite ? false : true;

        spinnerRef.current.show();

        let crewObj = {
            _id: crewMember._id,
            name: crewMember.name,
            email: crewMember.email,
            phone: crewMember.phone,
            location: crewMember.location,
            age: crewMember.age,
            licences: crewMember.licences,
            licencesOther: crewMember.licencesOther,
            skills: crewMember.skills,
            skillsOther: crewMember.skillsOther,
            hasCar: crewMember.hasCar,
            lastMinute: crewMember.lastMinute,
            camping: crewMember.camping,
            prefers: crewMember.prefers,
            favourite: favourite
        };

        let response = await api.post('/crew/update', {
            crew: crewObj
        }, true);

        if (response.status === 200) {

            if (response.error.code === "API_INTERNAL_24") {

                notification.error(response.error.detail);
                spinnerRef.current.hide();

            } else {

                if (favourite) {
                    notification.success("Crew member added to favourites.");
                } else {
                    notification.success("Crew member removed from favourites.");
                }
                
                spinnerRef.current.hide();
                let crewUpdated = crew;
                let crewIndex = crewUpdated.findIndex(x => x._id === crewMember._id);
                crewUpdated[crewIndex].favourite = favourite;
                setCrew([...crewUpdated]);
                let crewStaticUpdated = crewStatic;
                let crewStaticIndex = crewStaticUpdated.findIndex(x => x._id === crewMember._id);
                crewStaticUpdated[crewStaticIndex].favourite = favourite;
                setCrewStatic([...crewStaticUpdated]);

            }

        } else {

            notification.error("Error favouriting crew member.");
            spinnerRef.current.hide();

        }

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Crew - View"
            />

            <h1>Crew</h1>

            <Table headerItems={[
                {
                    title: "Name",
                    width: "240px",
                    isSortable: true,
                },
                {
                    title: "Email",
                    width: "300px",
                    isSortable: true,
                },
                {
                    title: "Phone",
                    width: "150px",
                    isSortable: true,
                },
                {
                    title: "Location",
                    width: "320px",
                    isSortable: true,
                },
                {
                    title: "Favourite",
                    width: "80px",
                    isSortable: true,
                }
            ]}
            data={crew}
            tableRowClickedCallback={tableRowClickedCallback}
            searchInputChangedCallback={searchInputChangedCallback}
            reloadCallback={() => { getCrew(true); }}
            favouriteCallback={favouriteCallback}
            />

            {selectedCrewIsOpen ?

                <TableDataPopup closeCallback={closeSelectedCrew} isEditingState={selectedCrewDetailsEditing} setIsEditingState={setSelectedCrewDetailsEditing}>

                {selectedCrewDetailsEditing ?

                    <div className="tdp-section">

                        <TextInput title="Full Name" type="text" ref={nameRef} initialValue={selectedCrew.name} />

                        <TextInput title="Email" type="email" ref={emailRef} initialValue={selectedCrew.email} />

                        <TextInput title="Phone Number" type="tel" ref={phoneRef} initialValue={selectedCrew.phone} />

                        <TextInput title="Location" type="text" ref={locationRef} initialValue={selectedCrew.location} />

                        <TextInput title="Age" type="number" ref={ageRef} initialValue={selectedCrew.age} />

                        <CheckboxInput title="Licences" values={licences} callback={licencesCheckboxCallback} ref={licencesOtherRef} otherInitialValue={selectedCrew.licencesOther} />

                        <CheckboxInput title="Skills" values={skills} callback={skillsCheckboxCallback} ref={skillsOtherRef} otherInitialValue={selectedCrew.licencesOther} />

                        <RadioInput title="Has use of a car" values={hasCar} callback={hasCarRadioCallback} />

                        <RadioInput title="Last minute availability" values={lastMinute} callback={lastMinuteRadioCallback} />

                        <RadioInput title="Happy Camping" values={camping} callback={campingRadioCallback} />

                        <RadioInput title="Prefers" values={prefers} callback={prefersRadioCallback} />

                        <SubmitButton title="Update" callback={updateCrewMemberCallback} />

                        <div className="tdp-delete" onClick={() => { selectedCrewDelete(); }}>Delete</div>

                    </div>

                    :

                    <div className="tdp-section">

                        <span><strong>Name:</strong> {selectedCrew.name}</span>

                        <span><strong>Email:</strong> <a href={`mailto:${selectedCrew.email}`}>{selectedCrew.email}</a></span>

                        <span><strong>Phone:</strong> <a href={`tel:${selectedCrew.phone}`}>{selectedCrew.phone}</a></span>

                        <span><strong>Location:</strong> {selectedCrew.location}</span>

                        <span><strong>Job Length Preference:</strong> {selectedCrew.prefers}</span>

                        <span><strong>Licences:</strong></span>

                        <div className="tdp-section-tags">

                            {selectedCrew.licences.map((licence, index) => (

                                <div className="tdp-section-tag" key={index}>{licence}</div>

                            ))}

                            {selectedCrew.licencesOther.split(',').map((licence, index) => (

                                licence.length > 0 &&
                                <div className="tdp-section-tag" key={index}>{licence}</div>

                            ))}

                            {(selectedCrew.licences.length === 0 && selectedCrew.licencesOther.length === 0) && (<span>None</span>)}

                        </div>

                        <span><strong>Skills:</strong></span>

                        <div className="tdp-section-tags">

                            {selectedCrew.skills.map((skill, index) => (

                                <div className="tdp-section-tag" key={index}>{skill}</div>

                            ))}

                            {selectedCrew.skillsOther.split(',').map((skill, index) => (

                                skill.length > 0 &&
                                <div className="tdp-section-tag" key={index}>{skill}</div>

                            ))}

                            {(selectedCrew.skills.length === 0 && selectedCrew.skillsOther.length === 0) && (<span>None</span>)}

                        </div>

                        <span><strong>Tags:</strong></span>

                        <div className="tdp-section-tags">

                            {(selectedCrew.hasCar === "Yes") ? <div className="tdp-section-tag">Has Car</div> : ''}

                            {(selectedCrew.lastMinute === "Yes") ? <div className="tdp-section-tag">Available Last Minute</div> : ''}

                            {(selectedCrew.camping === "Yes") ? <div className="tdp-section-tag">Happy To Camp</div> : ''}

                            {(selectedCrew.hasCar === "Maybe") ? <div className="tdp-section-tag">Open To Camping</div> : ''}

                            {(selectedCrew.hasCar === "No" && selectedCrew.lastMinute === "No" && selectedCrew.camping === "No") ? <span>None</span> : ''}

                        </div>

                    </div>

                }

                </TableDataPopup>

            :
                ''
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default CrewViewView;
