// Imports
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import './projectpage.scss';

// Components
import Meta from '../../Meta';
import Spinner from '../../spinners/Spinner';
import PrimaryButton from '../../buttons/PrimaryButton';
import ProjectInfoPopup from '../../popups/project/ProjectInfoPopup';
import ProjectAddDocumentPopup from '../../popups/project/ProjectAddDocumentPopup';
import ProjectEditDocumentPopup from '../../popups/project/ProjectEditDocumentPopup';
import ProjectAddStockPopup from '../../popups/project/ProjectAddStockPopup';
import ProjectEditStockPopup from '../../popups/project/ProjectEditStockPopup';
import ProjectInviteCrewPopup from '../../popups/project/ProjectInviteCrewPopup';
import ProjectCrewEditPopup from '../../popups/project/ProjectCrewEditPopup';
import TextInput from '../../inputs/TextInput';
import Form from '../../Form';
import Table from '../../Table';

// Utils
import api from '../../../utils/api';
import notification from '../../../utils/notification';
import format from '../../../utils/format';
import generate from '../../../utils/generate';

// Assets
import ReloadIcon from '../../../assets/icons/reload-icon-light.png';
import AddIcon from '../../../assets/icons/add-icon-light.png';
import AddDocIcon from '../../../assets/icons/add-doc-icon-light.png';
import CancelledIcon from '../../../assets/icons/cancelled-icon-light.png';
import CompleteIcon from '../../../assets/icons/complete-icon-light.png';
import ActiveIcon from '../../../assets/icons/active-icon-light.png';
import SpinnerPrimary from '../../../assets/spinners/spinner-primary.svg';
import GenerateDocumentsIcon from '../../../assets/icons/documents-icon-light.png';

// ProjectPage component
const ProjectPage = () => {

    // Get project id
    const { projectId } = useParams();

    // Refs
    const spinnerRef = useRef();
    const addToDoRef = useRef(null);
    const todoEditRef = useRef(null);

    // State
    const [project, setProject] = useState({});
    const [projectFiles, setProjectFiles] = useState([]);
    const [projectFilesStatic, setProjectFilesStatic] = useState([]);
    const [fullDescription, setFullDescription] = useState(false);
    const [statusUpdateOpen, setStatusUpdateOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [addToDoOpen, setAddToDoOpen] = useState(false);
    const [updatingToDo, setUpdatingToDo] = useState(-1);
    const [editingToDo, setEditingToDo] = useState(-1);
    const [editingFile, setEditingFile] = useState({});
    const [editingStock, setEditingStock] = useState(null);
    const [crewInvited, setCrewInvited] = useState([]);
    const [crewPending, setCrewPending] = useState([]);
    const [crewConfirmed, setCrewConfirmed] = useState([]);
    const [visibleCrewSection, setVisibleCrewSection] = useState('confirmed');
    const [availableCrew, setAvailableCrew] = useState([]);
    const [selectedCrewConfirmed, setSelectedCrewConfirmed] = useState({});

    // Sections open stats
    const [infoSectionIsOpen, setInfoSectionIsOpen] = useState(true);
    const [todoSectionIsOpen, setToDoSectionIsOpen] = useState(true);
    const [documentsSectionIsOpen, setDocumentsSectionIsOpen] = useState(true);
    const [stockSectionIsOpen, setStockSectionIsOpen] = useState(true);
    const [crewSectionIsOpen, setCrewSectionIsOpen] = useState(true);

    // Get project
    useEffect(() => {

        getProject();

    }, []);

    const getProject = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get(`/project/${projectId}`, true, force);

        if (response.status === 200) {

            updateProjectState(response.data.project);
            notification.success('Project loaded.');
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Sort todos
    const sortTodos = (todos) => {


        if (todos.length > 0) {

            // Sort todos
            let completed = [];
            let active = [];
            todos.forEach((todo) => {
                if (todo.completed) {
                    completed.push(todo);
                } else {
                    active.push(todo);
                }
            });
            let activeSorted = format.sortObjs(active, 'created', true);
            let updatedTodos = activeSorted.concat(completed);

            return updatedTodos;

        }

        return todos;

    }

    // Close all header popups
    const closeAllHeaderPopups = () => {
        setStatusUpdateOpen(false);
        setAddToDoOpen(false);
    };

    // Back button clicked
    const backButtonClicked = () => {
        window.history.go(-1);
    };

    // Reload button clicked
    const reloadButtonClicked = () => {
        getProject(true);
    };

    // Add ToDo button clicked
    const addToDoButtonClicked = () => {
        closeAllHeaderPopups();
        let current= addToDoOpen;
        let newValue = !current;
        setAddToDoOpen(newValue);
        setTimeout(() => {
            if (newValue) {
                addToDoRef.current.focus();
            }
        }, 100);
    }

    // Add Document button clicked
    const addDocumentButtonClicked = () => {
        setPopupOpen(true);
        setPopupType('addDocument');
    }

    // Status button clicked
    const statusButtonClicked = () => {
        closeAllHeaderPopups();
        setStatusUpdateOpen(!statusUpdateOpen);
    }

    // Edit info button clicked
    const editInfoButtonClicked = () => {
        setPopupOpen(true);
        setPopupType('info');
    }

    // Status update button clicked
    const statusUpdateButtonClicked = async (status) => {

        spinnerRef.current.show();

        let response = await api.post(`/project/update`, {
            project: {
                _id: project._id,
                status: status
            }
        }, true);

        if (response.status === 200) {

            let projectUpdate = project;
            projectUpdate.status = status;
            updateProjectState(projectUpdate);
            if (api.updateProjectCache(project._id, 'status', status)) {
                notification.success('Cache updated');
            } else {
                notification.error('Failed to update cache. Please reload project.');
            }
            notification.success('Status updated.');
            spinnerRef.current.hide();
            setStatusUpdateOpen(false);

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Close Popup
    const closePopup = () => {

        setPopupOpen(false);
        setPopupType('');

    }

    // Info popup success callback
    const infoPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();


    };

    // Add Document popup success callback
    const addDocumentPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();

    }

    // Add stock popup success callback
    const addStockPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();

    }

    // Edit stock popup success callback
    const editStockPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();

    };

    // Invite crew popup success callback
    const inviteCrewPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();

    }

    // Confirm crew edit popup success callback
    const confirmCrewEditPopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();


    }

    // Add ToDo submit clicked
    const addToDoSubmitClicked = async (data) => {

        event.preventDefault();

        let todo = addToDoRef.current.value;

        if (todo.length > 0) {

            spinnerRef.current.show();

            let response = await api.post('/project/todo/add', {
                todo: {
                    task: todo,
                    project: project._id
                }
            }, true);

            if (response.status === 200) {

                notification.success(`Task added.`);
                setAddToDoOpen(false);
                spinnerRef.current.hide();
                updateProjectState(response.data.project);
                if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                    notification.success('Cache updated');
                } else {
                    notification.error('Failed to update cache. Please reload project.');
                }

            } else {

                notification.error(`Error adding task.`);
                spinnerRef.current.hide();


            }

        } else {
            notification.error('Please enter a task.');
        }

    };

    // Task checkbox clicked
    const taskCheckboxClicked = async (index) => {

        setUpdatingToDo(index);
        let todo = project.todos[index];
        todo.completed = !todo.completed;

        spinnerRef.current.show();

        let response = await api.post('/project/todo/update', {
            todo: todo
        }, true);

        if (response.status === 200) {

            notification.success(`Task updated.`);
            spinnerRef.current.hide();
            updateProjectState(response.data.project);
            if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                notification.success('Cache updated');
            } else {
                notification.error('Failed to update cache. Please reload project.');
            }
            setUpdatingToDo(-1);

        } else {

            notification.error(`Error updated task.`);
            spinnerRef.current.hide();
            setUpdatingToDo(-1);


        }

    }

    // Task clicked
    const taskClicked = (index) => {
        if (!project.todos[index].completed) {
            setEditingToDo(index);
            setTimeout(() => {
                todoEditRef.current.focus();
            }, 100);
        }
    };

    // Task edit submit
    const todoEditSubmit = async (event, index) => {

        event.preventDefault();

        if (todoEditRef.current.value.length >= 1) {

            setUpdatingToDo(index);
            let todo = project.todos[index];
            todo.task = todoEditRef.current.value;

            spinnerRef.current.show();

            let response = await api.post('/project/todo/update', {
                todo: todo
            }, true);

            if (response.status === 200) {

                notification.success(`Task updated.`);
                spinnerRef.current.hide();
                updateProjectState(response.data.project);
                if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                    notification.success('Cache updated');
                } else {
                    notification.error('Failed to update cache. Please reload project.');
                }
                setUpdatingToDo(-1);
                setEditingToDo(-1);

            } else {

                notification.error(`Error updated task.`);
                spinnerRef.current.hide();
                setUpdatingToDo(-1);

            }

        } else {

            setUpdatingToDo(index);
            let todo = project.todos[index];
            spinnerRef.current.show();

            let response = await api.post('/project/todo/delete', {
                todo: todo
            }, true);

            if (response.status === 200) {

                notification.success(`Task deleted.`);
                spinnerRef.current.hide();
                updateProjectState(response.data.project);
                if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                    notification.success('Cache updated');
                } else {
                    notification.error('Failed to update cache. Please reload project.');
                }
                setUpdatingToDo(-1);
                setEditingToDo(-1);

            } else {

                notification.error(`Error deleting task.`);
                spinnerRef.current.hide();
                setUpdatingToDo(-1);

            }

        }

    }

    // File clicked callback
    const fileClickedCallback = async (file) => {

        window.open(file.url, '_blank').focus();

    };

    // Download file clicked
    const downloadFileClicked = async (file) => {

        spinnerRef.current.show();

        let download = await api.downloadFile(`/project-files/${file.fileId}`, true);

        spinnerRef.current.hide();

    };

    // File search input changed callback
    const fileSearchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = projectFilesStatic;

        } else {

            projectFilesStatic.forEach((file) => {

                // Check file name
                if (file.fileName.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === file._id)) {
                        results.push(file);
                    }
                }

                // Check category
                if (file.category.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === file._id)) {
                        results.push(file);
                    }
                }

                // Check type
                if (file.type.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x._id === file._id)) {
                        results.push(file);
                    }
                }

            });

        }

        setProjectFiles(results);

    }

    // Update project state
    const updateProjectState = (updatedProject) => {

        // Sort todos
        updatedProject.todos = sortTodos(updatedProject.todos);

        // Update State
        setProject(updatedProject);
        setProjectFiles(updatedProject.files);
        setProjectFilesStatic(updatedProject.files);

        // Update Crew
        let invited = [];
        let pending = [];
        let confirmed = [];
        updatedProject.crew.forEach((crewMember) => {
            if (crewMember.status === "invited") {
                invited.push(crewMember);
            }
            if (crewMember.status === "pending") {
                pending.push(crewMember);
            }
            if (crewMember.status === "confirmed") {
                confirmed.push(crewMember);
            }
        });
        setCrewConfirmed([...confirmed]);
        setCrewPending([...pending]);
        setCrewInvited([...invited]);

        // Update available crew
        let available = [];
        updatedProject.crewAvailability.forEach((crewMember) => {
            if (crewMember.available) {
                available.push(crewMember);
            }
        });
        setAvailableCrew([...available]);

    };

    // Edit file clicked
    const editFileClicked = async (file) => {

        setEditingFile(file);
        setPopupOpen(true);
        setPopupType('editFile');

    };

    // Edit document popup success callback
    const editFilePopupSuccessCallback = (updatedProject) => {

        updateProjectState(updatedProject);
        if (api.updateProjectCache(updatedProject._id, 'project', updatedProject)) {
            notification.success('Cache updated');
        } else {
            notification.error('Failed to update cache. Please reload project.');
        }
        closePopup();

    };

    // Add stock button clicked
    const addStockButtonClicked = () => {
        setPopupOpen(true);
        setPopupType('addStock');
    }

    // Stock item edit clicked
    const stockItemEditClicked = (item) => {

        let readyToEdit = true;

        // Check the item is not required by something else
        project.stock.forEach((stockItem) => {
            if (stockItem.item.requires) {
                stockItem.item.requires.forEach((requireItem) => {
                    if (item.item._id === requireItem.id) {
                        notification.error(`Cannot edit item. It is controlled by ${stockItem.item.name}.`);
                        readyToEdit = false;
                        return;
                    }
                });
            }
        });


        if (readyToEdit) {
            setEditingStock(item);
            setPopupOpen(true);
            setPopupType('editStock');
        }

    }

    // Stock item delete clicked
    const stockItemDeleteClicked = async (item) => {

        let readyToDelete = true;

        // Check the item is not required by something else
        project.stock.forEach((stockItem) => {
            if (stockItem.item.requires) {
                stockItem.item.requires.forEach((requireItem) => {
                    if (item.item._id === requireItem.id) {
                        notification.error(`Cannot delete item. It is required by ${stockItem.item.name}.`);
                        readyToDelete = false;
                        return;
                    }
                });
            }
        });

        // Create to delete array
        let toDelete = [item];
        if (item.item.requires) {
            item.item.requires.forEach((requireItem) => {
                let requireStockItem = project.stock.find(x => x.item._id === requireItem.id);
                if (requireStockItem) {
                    toDelete.push(requireStockItem);
                }
            });
        }

        // Delete
        if (readyToDelete) {

            let confirmMessage = `Are you sure you want to delete `;
            toDelete.forEach((deleteItem, index) => {
                if (index === 0) {
                    confirmMessage += `${deleteItem.item.name}`;
                } else {
                    confirmMessage += `, ${deleteItem.item.name}`;
                }
            });

            if (window.confirm(confirmMessage)) {

                spinnerRef.current.show();

                let response = await api.post('/project/stock/delete', {
                    delete: toDelete,
                    project: project._id
                }, true);

                if (response.status === 200) {

                    notification.success(`Stock removed.`);
                    spinnerRef.current.hide();
                    updateProjectState(response.data.project);
                    if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                        notification.success('Cache updated');
                    } else {
                        notification.error('Failed to update cache. Please reload project.');
                    }

                } else {

                    notification.error(`Error removing stock.`);
                    spinnerRef.current.hide();

                }

            }

        }

    }

    // Invite crew button clicked 
    const inviteCrewButtonClicked = () => {
        setPopupOpen(true);
        setPopupType('inviteCrew');
    }

    // Invited crew cancel button clicked
    const invitedCrewCancelButtonClicked = async (crewMember) => {
        
        spinnerRef.current.show();

        let response = await api.post('/project/crew/invite/cancel', {
            crew: crewMember,
            project: project._id
        }, true);

        if (response.status === 200) {

            notification.success(`Crew member invite cancelled.`);
            spinnerRef.current.hide();
            updateProjectState(response.data.project);
            if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                notification.success('Cache updated');
            } else {
                notification.error('Failed to update cache. Please reload project.');
            }

        } else {

            notification.error(`Error cancelling crew member invited.`);
            spinnerRef.current.hide();

        }

    }

    // Pending crew button clicked
    const pendingCrewCancelButtonClicked = async (crewMember, confirm) => {

        spinnerRef.current.show();

        let response = await api.post('/project/crew/confirm', {
            crew: crewMember,
            confirm: confirm ? true : false,
            project: project
        }, true);

        if (response.status === 200) {

            if (confirm) {
                notification.success(`Crew member confirmed.`);
            } else {
                notification.success(`Crew member declined.`);
            }
            spinnerRef.current.hide();
            updateProjectState(response.data.project);
            if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                notification.success('Cache updated');
            } else {
                notification.error('Failed to update cache. Please reload project.');
            }

        } else {

            if (confirm) {
                notification.error(`Error confirming crew member.`);
            } else {
                notification.error(`Error declining crew member.`);
            }
            spinnerRef.current.hide();

        }

    };

    // Confirmed crew remove button clicked
    const confirmedCrewRemoveButtonClicked = async (crewMember) => {

        spinnerRef.current.show();

        let response = await api.post('/project/crew/remove', {
            crew: crewMember,
            project: project
        }, true);

        if (response.status === 200) {

            notification.success(`Crew member removed.`);
            spinnerRef.current.hide();
            updateProjectState(response.data.project);
            if (api.updateProjectCache(response.data.project._id, 'project', response.data.project)) {
                notification.success('Cache updated');
            } else {
                notification.error('Failed to update cache. Please reload project.');
            }

        } else {

            notification.error(`Error removing crew member.`);
            spinnerRef.current.hide();

        }

    };

    // Generate documents button clicked
    const generateDocumentsButtonClicked = async () => { 

        // Generate files
        let empContent = generate.emp(project);

        // Download EMP
        const link = document.createElement("a");
        const file = new Blob([empContent], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = `${project.name} EMP.md`;
        link.click();
        URL.revokeObjectURL(link.href);

    }

    // Confirmed crew selected
    const confirmedCrewSelected = (crewMember) => {
        
        setSelectedCrewConfirmed(crewMember);
        setPopupOpen(true);
        setPopupType('editCrewConfirmed');

    }

    // Return component
    return (

        <div className="project">

            <Meta
                title={`Project - ${project.name ? project.name : 'loading...'}`}
            />

            {project._id ?

                <div className="project-inner">

                    <div className="project-header">

                        <div className="project-header-back" onClick={() => { backButtonClicked(); }}>{`< Back`}</div>

                        <div className="project-header-quick-actions">

                            <div className="project-header-quick-action" onClick={() => { addToDoButtonClicked(); }}>
                                <img src={AddIcon} alt="Add ToDo" />
                            </div>

                            <div className="project-header-quick-action" onClick={() => { addDocumentButtonClicked(); }}>
                                <img src={AddDocIcon} alt="Add Document" />
                            </div>

                            <div className="project-header-quick-action" onClick={() => { generateDocumentsButtonClicked(); }}>
                                <img src={GenerateDocumentsIcon} alt="Generate Documents" />
                            </div>

                            <div className="project-header-quick-action" onClick={() => { statusButtonClicked(); }}>
                                {project.status === 'active' && <img src={ActiveIcon} alt="Active" />}
                                {project.status === 'completed' && <img src={CompleteIcon} alt="Completed" />}
                                {project.status === 'cancelled' && <img src={CancelledIcon} alt="Cancelled" />}
                            </div>

                            <div className="project-header-quick-action" onClick={() => { reloadButtonClicked(); }}>
                                <img src={ReloadIcon} alt="Reload" />
                            </div>

                        </div>

                    </div>

                    <div className="project-spacer"></div>

                    <div className={infoSectionIsOpen ? "project-section" : "project-section section-closed"}>

                        <div className="project-section-header">
                            <h2 onClick={() => { setInfoSectionIsOpen(!infoSectionIsOpen) }}>INFO</h2>
                            <PrimaryButton title="Edit" callback={editInfoButtonClicked} noMargin />
                        </div>

                        <div className="project-section-content">

                            <div className="project-section-content-half">

                                <span><strong>Name:</strong> {project.name}</span>
                                <span><strong>Venue:</strong> {project.venue}</span>
                                <span><strong>Status:</strong> {project.status.charAt(0).toUpperCase() + project.status.slice(1)}</span>
                                <span><strong>Start Date:</strong> {project.dates.start.full}</span>
                                <span><strong>End Date:</strong> {project.dates.end.full}</span>
                                <span><strong>Description:</strong></span>

                                {(project.description.length > 200) ?

                                    fullDescription ?

                                        <p>{project.description} <span className="change-length" onClick={() => { setFullDescription(false); }}>Show Less</span></p>

                                    :

                                        <p>{project.description.substring(0,200)}... <span className="change-length" onClick={() => { setFullDescription(true) }}>Show More</span></p>

                                :
                                    <p>{project.description}</p>
                                }

                                <span><strong>Project Portal:</strong></span>
                                <span><strong>URL:</strong> <a href="https://projects.vikingevents.co.uk" target="_blank">https://projects.vikingevents.co.uk</a></span>
                                <span><strong>Project ID:</strong> {project.portal.id}</span>
                                <span><strong>Password:</strong> {project.portal.password}</span>

                            </div>

                            <div className="project-section-content-half">

                                <span><strong>Client:</strong> {project.client.name}</span>
                                {project.client.company && <span><strong>Company:</strong> {project.client.company}</span>}
                                <span><strong>Email:</strong> <a href={`mailto:${project.client.email}`}>{project.client.email}</a></span>
                                <span><strong>Invoice Email:</strong> <a href={`mailto:${project.client.invoiceEmail}`}>{project.client.invoiceEmail}</a></span>
                                <span><strong>Phone:</strong> <a href={`tel:${project.client.phone}`}>{project.client.phone}</a></span>
                                <span><strong>Address:</strong></span>
                                {project.client.address.line1 && <span>{project.client.address.line1}</span>}
                                {project.client.address.line2 && <span>{project.client.address.line2}</span>}
                                {project.client.address.city && <span>{project.client.address.city}</span>}
                                {project.client.address.county && <span>{project.client.address.county}</span>}
                                {project.client.address.postcode && <span>{project.client.address.postcode}</span>}

                            </div>

                        </div>

                    </div>

                    <div className={todoSectionIsOpen ? "project-section" : "project-section section-closed"}>

                        <div className="project-section-header">
                            <h2 onClick={() => { setToDoSectionIsOpen(!todoSectionIsOpen) }}>TO-DO</h2>
                        </div>

                        <div className="project-section-content">

                            {project.todos.length > 0 ?

                                <div className="project-section-todos">

                                    {project.todos.map((todo, index) => (

                                        <div className="project-section-todo" key={index}>

                                            {(updatingToDo === index) ?
                                                <div className="project-section-todo-updating">
                                                    <img src={SpinnerPrimary} alt="Updating..." />
                                                </div>
                                            :
                                                <div className={todo.completed ? "project-section-todo-checkbox todo-completed" : "project-section-todo-checkbox"} onClick={() => { taskCheckboxClicked(index); }}>{todo.completed && "✓"}</div>
                                            }

                                            <div className="project-section-todo-content">

                                                {(editingToDo === index) ?
                                                    <form onSubmit={() => { todoEditSubmit(event, index); }}>
                                                        <TextInput type="text" title="todo" hideLabel={true} initialValue={todo.task} ref={todoEditRef} />
                                                        <span onClick={() => { setEditingToDo(-1) }}>Cancel</span>
                                                    </form>
                                                :
                                                    <p className={!todo.completed ? "todo-can-click" : ""} onClick={() => { taskClicked(index) }}>{todo.task}</p>
                                                }

                                            </div>

                                        </div>

                                    ))}

                                </div>

                            :
                                <span>You have not created any tasks for this project yet.</span>
                            }

                        </div>

                    </div>

                    <div className={documentsSectionIsOpen ? "project-section" : "project-section section-closed"}>

                        <div className="project-section-header">
                            <h2 onClick={() => { setDocumentsSectionIsOpen(!documentsSectionIsOpen) }}>DOCUMENTS</h2>
                        </div>

                        <div className="project-section-content">

                        <Table headerItems={[
                            {
                                title: "File Name",
                                width: "315px",
                                isSortable: true,
                            },
                            {
                                title: "Version",
                                width: "80px",
                                isSortable: true,
                            },
                            {
                                title: "Category",
                                width: "100px",
                                isSortable: true,
                            },
                            {
                                title: "Type",
                                width: "140px",
                                isSortable: true,
                            },
                            {
                                title: "Project Portal Visible",
                                width: "190px",
                                isSortable: false,
                            },
                            {
                                title: "Crew Visible",
                                width: "120px",
                                isSortable: false,
                            },
                            {
                                title: "Download",
                                width: "80px",
                                isSortable: false
                            },
                            {
                                title: "Edit",
                                width: "60px",
                                isSortable: false
                            }
                        ]}
                        data={projectFiles}
                        tableRowClickedCallback={fileClickedCallback}
                        searchInputChangedCallback={fileSearchInputChangedCallback}
                        reloadCallback={() => { getProject(true); }}
                        noMargin
                        downloadCallback={downloadFileClicked}
                        editCallback={editFileClicked}
                        />

                        </div>

                    </div>

                    <div className={stockSectionIsOpen ? "project-section" : "project-section section-closed"}>

                        <div className="project-section-header">
                            <h2 onClick={() => { setStockSectionIsOpen(!stockSectionIsOpen) }}>STOCK</h2>
                            <PrimaryButton title="Add" callback={addStockButtonClicked} noMargin />
                        </div>

                        <div className="project-section-content">

                            <div className="project-stock-container">

                                {project.stock.map((stock, index) => (

                                <div className="project-stock-item" key={index}>

                                    <div className="project-stock-item-details">
                                        <span>{`${stock.quantity} x ${stock.item.name}`}</span>
                                    </div>

                                    <div className="project-stock-item-actions">
                                        <span onClick={() => { stockItemEditClicked(stock); }}>Edit</span>
                                        <span onClick={() => { stockItemDeleteClicked(stock); }}>Remove</span>
                                    </div>

                                </div>

                                ))}

                            </div>

                        </div>

                    </div>

                    <div className={crewSectionIsOpen ? "project-section" : "project-section section-closed"}>

                        <div className="project-section-header">
                            <h2 onClick={() => { setCrewSectionIsOpen(!crewSectionIsOpen) }}>CREW</h2>
                            <PrimaryButton title="Invite" callback={inviteCrewButtonClicked} noMargin />
                        </div>

                        <div className="project-section-crew">

                            <div className="project-crew-nav">

                                    <div 
                                        className={(visibleCrewSection === "confirmed") ? "project-crew-nav-item cni-active" : "project-crew-nav-item"}
                                        onClick={() => { setVisibleCrewSection('confirmed'); }}
                                    >Confirmed</div>

                                    <div 
                                        className={(visibleCrewSection === "pending") ? "project-crew-nav-item cni-active" : "project-crew-nav-item"}
                                        onClick={() => { setVisibleCrewSection('pending'); }}
                                    >Pending</div>

                                    <div 
                                        className={(visibleCrewSection === "invited") ? "project-crew-nav-item cni-active" : "project-crew-nav-item"}
                                        onClick={() => { setVisibleCrewSection('invited'); }}
                                    >Invited</div>

                            </div>

                            {(visibleCrewSection === "confirmed") &&
                                
                                <div className="project-crew-inner">

                                    {crewConfirmed.length > 0 ?
                                        
                                        <div className="project-crew-invited">

                                            <div className="project-crew-invited-header">

                                                <div className="project-crew-invited-item project-cii-name">Name</div>
                                                <div className="project-crew-invited-item project-cii-rate">Day Rate</div>
                                                <div className="project-crew-invited-item project-cii-start">Start Date</div>
                                                <div className="project-crew-invited-item project-cii-end">End Date</div>
                                                <div className="project-crew-invited-item project-cii-cancel">Remove</div>

                                            </div>

                                            {crewConfirmed.map((crewMember, index) => (

                                                <div className="project-crew-invited-row" key={index}>

                                                    <div className="project-crew-invited-item project-cii-name cii-name-hover" onClick={() => {
                                                        confirmedCrewSelected(crewMember);
                                                    }}>{crewMember.crew.name}</div>
                                                    <div className="project-crew-invited-item project-cii-rate">£{crewMember.pay}</div>
                                                    <div className="project-crew-invited-item project-cii-start">{crewMember.startDate}</div>
                                                    <div className="project-crew-invited-item project-cii-end">{crewMember.endDate}</div>
                                                    <div className="project-crew-invited-item project-cii-cancel" onClick={() => { confirmedCrewRemoveButtonClicked(crewMember); }}>Remove</div>

                                                </div>

                                            ))}

                                        </div>

                                    :   
                                        <span>No confirmed crew.</span>
                                    }


                                </div>

                            } 

                            {(visibleCrewSection === "pending") &&
                                
                                <div className="project-crew-inner">

                                    {crewPending.length > 0 ?
                                        
                                        <div className="project-crew-invited">

                                            <div className="project-crew-invited-header">

                                                <div className="project-crew-invited-item project-cii-name">Name</div>
                                                <div className="project-crew-invited-item project-cii-rate">Day Rate</div>
                                                <div className="project-crew-invited-item project-cii-start">Start Date</div>
                                                <div className="project-crew-invited-item project-cii-end">End Date</div>
                                                <div className="project-crew-invited-item project-cii-confirm">Confirm</div>
                                                <div className="project-crew-invited-item project-cii-decline">Decline</div>

                                            </div>

                                            {crewPending.map((crewMember, index) => (

                                                <div className="project-crew-invited-row" key={index}>

                                                    <div className="project-crew-invited-item project-cii-name">{crewMember.crew.name}</div>
                                                    <div className="project-crew-invited-item project-cii-rate">£{crewMember.pay}</div>
                                                    <div className="project-crew-invited-item project-cii-start">{crewMember.startDate}</div>
                                                    <div className="project-crew-invited-item project-cii-end">{crewMember.endDate}</div>
                                                    <div className="project-crew-invited-item project-cii-confirm" onClick={() => { pendingCrewCancelButtonClicked(crewMember, true); }}>Confirm</div>
                                                    <div className="project-crew-invited-item project-cii-decline" onClick={() => { pendingCrewCancelButtonClicked(crewMember, false); }}>Decline</div>

                                                </div>

                                            ))}

                                        </div>

                                    :   
                                        <span>No pending crew.</span>
                                    }


                                </div>

                            }

                            {(visibleCrewSection === "invited") &&
                                
                                <div className="project-crew-inner">

                                    {crewInvited.length > 0 ?
                                        
                                        <div className="project-crew-invited">

                                            <div className="project-crew-invited-header">

                                                <div className="project-crew-invited-item project-cii-name">Name</div>
                                                <div className="project-crew-invited-item project-cii-rate">Day Rate</div>
                                                <div className="project-crew-invited-item project-cii-start">Start Date</div>
                                                <div className="project-crew-invited-item project-cii-end">End Date</div>
                                                <div className="project-crew-invited-item project-cii-cancel">Cancel</div>

                                            </div>

                                            {crewInvited.map((crewMember, index) => (

                                                <div className="project-crew-invited-row" key={index}>

                                                    <div className="project-crew-invited-item project-cii-name">{crewMember.crew.name}</div>
                                                    <div className="project-crew-invited-item project-cii-rate">£{crewMember.pay}</div>
                                                    <div className="project-crew-invited-item project-cii-start">{crewMember.startDate}</div>
                                                    <div className="project-crew-invited-item project-cii-end">{crewMember.endDate}</div>
                                                    <div className="project-crew-invited-item project-cii-cancel" onClick={() => { invitedCrewCancelButtonClicked(crewMember); }}>Cancel</div>

                                                </div>

                                            ))}

                                        </div>

                                    :   
                                        <span>No crew have been invited yet.</span>
                                    }


                                </div>

                            }

                        </div>

                    </div>

                    {statusUpdateOpen ?

                        <div className="project-header-status-update">

                            <div className="project-header-status-update-arrow"></div>

                            <div className="project-header-status-update-content">

                                <div className="project-header-status-update-item" onClick={() => { statusUpdateButtonClicked('active') }}>
                                    <img src={ActiveIcon} alt="Active" />
                                </div>

                                <div className="project-header-status-update-item" onClick={() => { statusUpdateButtonClicked('completed') }}>
                                    <img src={CompleteIcon} alt="Completed" />
                                </div>

                                <div className="project-header-status-update-item" onClick={() => { statusUpdateButtonClicked('cancelled') }}>
                                    <img src={CancelledIcon} alt="Cancelled" />
                                </div>

                            </div>

                        </div>

                    :
                        null
                    }

                    {addToDoOpen ?

                        <div className="project-header-addtodo">

                            <div className="project-header-addtodo-arrow"></div>

                            <div className="project-header-addtodo-content">

                                <form onSubmit={addToDoSubmitClicked} >

                                    <TextInput title="Add To-Do" type="text" hideLabel noMargin ref={addToDoRef} />

                                    <div className="project-header-addtodo-submit" onClick={() => { addToDoSubmitClicked(); }}>+</div>

                                </form>

                            </div>

                        </div>

                    :
                        null
                    }

                    {popupOpen ?

                        <div className="project-popup-container">

                            <div className="project-popup-close" onClick={() => { closePopup() }}>X</div>

                            {(popupType === "info") && <ProjectInfoPopup project={project} spinnerRef={spinnerRef} successCallback={infoPopupSuccessCallback} />}

                            {(popupType === "addDocument") && <ProjectAddDocumentPopup project={project} spinnerRef={spinnerRef} successCallback={addDocumentPopupSuccessCallback} />}

                            {(popupType === "editFile") && <ProjectEditDocumentPopup project={project} spinnerRef={spinnerRef} successCallback={editFilePopupSuccessCallback} file={editingFile} />}

                            {(popupType === "addStock") && <ProjectAddStockPopup project={project} spinnerRef={spinnerRef} successCallback={addStockPopupSuccessCallback} projectReloadCallback={getProject} />}

                            {(popupType === "editStock") && <ProjectEditStockPopup project={project} spinnerRef={spinnerRef} successCallback={editStockPopupSuccessCallback} stockItem={editingStock} />}

                            {(popupType === "inviteCrew") && <ProjectInviteCrewPopup project={project} spinnerRef={spinnerRef} successCallback={inviteCrewPopupSuccessCallback} crew={availableCrew} />}

                            {(popupType === "editCrewConfirmed") && <ProjectCrewEditPopup project={project} spinnerRef={spinnerRef} successCallback={confirmCrewEditPopupSuccessCallback} crew={selectedCrewConfirmed} />}

                        </div> 

                    :
                        null
                    }

                </div>

            :
                <div className="project-loading">Loading Project...</div>
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default ProjectPage;
