// Imports
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

// Utils
import session from '../../utils/session';

// Components
import Header from '../Header';
import SpinnerFull from '../spinners/SpinnerFull';

// Pages
import HomePage from '../pages/HomePage';
import PageNotFoundPage from '../pages/PageNotFoundPage';
import LoginPage from '../pages/LoginPage';
import CrewPage from '../pages/CrewPage';
import StockPage from '../pages/StockPage';
import ClientsPage from '../pages/ClientsPage';
import ProjectsPage from '../pages/ProjectsPage';
import SettingsPage from '../pages/SettingsPage';
import UsersPage from '../pages/UsersPage';
import ProjectPage from '../pages/ProjectPage';

// Container component
const Container = () => {

    const [sessionStatus, setSessionStatus] = useState('checking');

    // Check session
    useEffect(() => {

        async function checkSession() {

            if (session.exists()) {

                let check = await session.check();

                if (check) {
                    setSessionStatus('valid');
                } else {
                    window.location.href = "/";
                }

            } else {
                setSessionStatus('invalid');
            }

        }

        checkSession();

    }, []);

    if (sessionStatus === 'checking') {

        return (
            <SpinnerFull title="Checking session" />
        )

    }

    if (sessionStatus === "valid") {

        return (

            <div>

                <Header />

                <div style={{ height: '100px', width: '100%' }}></div>

                <Routes>

                    <Route path="*" element={<PageNotFoundPage />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/crew" element={<CrewPage />} />
                    <Route path="/stock" element={<StockPage />} />
                    <Route path="/clients" element={<ClientsPage />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/users" element={<UsersPage />} />
                    <Route path="/project/:projectId" element={<ProjectPage />} />

                </Routes>

            </div>

        )

    }

    if (sessionStatus === "invalid") {

        return (

            <LoginPage />

        )

    }

};

export default Container;
