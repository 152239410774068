// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './form.scss';

// Components
import SubmitButton from '../buttons/SubmitButton';

// Form component
const Form = (props) => {

    // Work out classes
    let classes = "form";
    if (props.fullWidth) {
        classes += " form-full-width";
    }
    if (props.hideBackground) {
        classes += " form-no-background";
    }
    if (props.noMargin) {
        classes += " form-no-margin";
    }

    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        props.submitCallback();
    };

    // Return component
    return (

        <div className={classes}>

            <form onSubmit={handleSubmit}>

            {props.children}

            {!props.noSubmit && <SubmitButton title={props.submitTitle} /> }

            </form>

        </div>

    )

};

Form.propTypes = {
    submitCallback: PropTypes.func.isRequired,
    submitTitle: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    hideBackground: PropTypes.bool,
    noMargin: PropTypes.bool,
    noSubmit: PropTypes.bool
}

// Export Component
export default Form;
