// Imports
import React from 'react';
import './stockwriteoffsview.scss';

// Components
import Meta from '../../../Meta';;

// StockWriteOffsView component
const StockWriteOffsView = () => {

    // Return component
    return (

        <div className="view">

            <Meta
                title="Stock - Write Offs"
            />

            <h1>Write Offs</h1>

        </div>

    )

};

// Export Component
export default StockWriteOffsView;
