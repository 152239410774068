import Papa from 'papaparse';

// Test bulk add object
const testBulkAddObject = [
    {
        "name": "Test Crew",
        "email": "testcrew@vikingevents.co.uk",
        "phone": "07123456789",
        "location": "Brighton",
        "age": "30",
        "licences": [
            "Car",
            "Class 1 HGV",
            "Class 2 HGV",
            "Straight Mast Forklift",
            "Telehandler",
            "Cherry Picker"
        ],
        "licencesOther": "Test other 1, test other 2",
        "skills": [
            "Carpentry",
            "Plumbing",
            "Electrical",
            "Metalwork",
            "Site Management",
            "Safety Officer",
            "Fencing (Heras, Met, Ped)",
            "Pit Barrier (Mojo)",
            "Staging",
            "Scaffolding",
            "Rigging",
            "Lighting, Audio, Video & Other Tech"
        ],
        "skillsOther": "Test other 1, Test other 2",
        "hasCar": "Yes",
        "lastMinute": "Yes",
        "camping": "Yes",
        "prefers": "Any length"
    },
    {
        "name": "Test Crew2",
        "email": "testcrew1@vikingevents.co.uk",
        "phone": "07223456789",
        "location": "Brighton",
        "age": "30",
        "licences": [],
        "licencesOther": "",
        "skills": [],
        "skillsOther": "",
        "hasCar": "No",
        "lastMinute": "No",
        "camping": "No",
        "prefers": "Single Day Jobs"
    },
    {
        "name": "Test Crew3",
        "email": "testcrew2@vikingevents.co.uk",
        "phone": "07323456789",
        "location": "Brighton",
        "age": "30",
        "licences": [
            "Car",
            "Class 1 HGV",
            "Class 2 HGV",
            "Straight Mast Forklift",
            "Telehandler",
            "Cherry Picker"
        ],
        "licencesOther": "Test other 1, test other 2",
        "skills": [
            "Carpentry",
            "Plumbing",
            "Electrical",
            "Metalwork",
            "Site Management",
            "Safety Officer",
            "Fencing (Heras, Met, Ped)",
            "Pit Barrier (Mojo)",
            "Staging",
            "Scaffolding",
            "Rigging",
            "Lighting, Audio, Video & Other Tech"
        ],
        "skillsOther": "Test other 1, Test other 2",
        "hasCar": "Yes",
        "lastMinute": "Yes",
        "camping": "Maybe",
        "prefers": "Short Jobs (1-3 Days)"
    },
    {
        "name": "Test Crew 4",
        "email": "testcrew3@vikingevents.co.uk",
        "phone": "07423456789",
        "location": "Brighton",
        "age": "30",
        "licences": [],
        "licencesOther": "",
        "skills": [],
        "skillsOther": "",
        "hasCar": "No",
        "lastMinute": "No",
        "camping": "No",
        "prefers": "Long Jobs (4-10 Days)"
    },
    {
        "name": "Test Crew 5",
        "email": "testcrew4@vikingevents.co.uk",
        "phone": "07423456789",
        "location": "Brighton",
        "age": "30",
        "licences": [
            "Car",
            "Class 1 HGV",
            "Class 2 HGV",
            "Straight Mast Forklift",
            "Telehandler",
            "Cherry Picker"
        ],
        "licencesOther": "Test other 1, test other 2",
        "skills": [
            "Carpentry",
            "Plumbing",
            "Electrical",
            "Metalwork",
            "Site Management",
            "Safety Officer",
            "Fencing (Heras, Met, Ped)",
            "Pit Barrier (Mojo)",
            "Staging",
            "Scaffolding",
            "Rigging",
            "Lighting, Audio, Video & Other Tech"
        ],
        "skillsOther": "Test other 1, Test other 2",
        "hasCar": "No",
        "lastMinute": "No",
        "camping": "No",
        "prefers": "Any length"
    }
];

// Parse bulk add csv
const parseBulkAddCSV = (csv, callback) => {

    Papa.parse(csv, {
        error: (error) => {
            callback(null);
        },
        complete: (results) => {

            // Remove first row
            results.data.shift();

            // Format into crew objects array
            let crew = [];
            results.data.forEach((row, index) => {

                // Licences
                let licences = [];
                if (row[5].toLowerCase() === 'yes') {
                    licences.push('Car');
                }
                if (row[6].toLowerCase() === 'yes') {
                    licences.push('Class 1 HGV');
                }
                if (row[7].toLowerCase() === 'yes') {
                    licences.push('Class 2 HGV');
                }
                if (row[8].toLowerCase() === 'yes') {
                    licences.push('Straight Mast Forklift');
                }
                if (row[9].toLowerCase() === 'yes') {
                    licences.push('Telehandler');
                }
                if (row[10].toLowerCase() === 'yes') {
                    licences.push('Cherry Picker');
                }

                // Skills
                let skills = [];
                if (row[12].toLowerCase() === 'yes') {
                    skills.push('Carpentry');
                }
                if (row[13].toLowerCase() === 'yes') {
                    skills.push('Plumbing');
                }
                if (row[14].toLowerCase() === 'yes') {
                    skills.push('Electrical');
                }
                if (row[15].toLowerCase() === 'yes') {
                    skills.push('Metalwork');
                }
                if (row[16].toLowerCase() === 'yes') {
                    skills.push('Site Management');
                }
                if (row[17].toLowerCase() === 'yes') {
                    skills.push('Safety Officer');
                }
                if (row[18].toLowerCase() === 'yes') {
                    skills.push('Fencing (Heras, Met, Ped)');
                }
                if (row[19].toLowerCase() === 'yes') {
                    skills.push('Pit Barrier (Mojo)');
                }
                if (row[20].toLowerCase() === 'yes') {
                    skills.push('Staging');
                }
                if (row[21].toLowerCase() === 'yes') {
                    skills.push('Scaffolding');
                }
                if (row[22].toLowerCase() === 'yes') {
                    skills.push('Rigging');
                }
                if (row[23].toLowerCase() === 'yes') {
                    skills.push('Lighting, Audio, Video & Other Tech');
                }

                // Has car
                let hasCar = "No";
                if (row[25].toLowerCase() === "yes") {
                    hasCar = "Yes";
                }

                // Last minute
                let lastMinute = "No";
                if (row[26].toLowerCase() === "yes") {
                    lastMinute = "Yes";
                }

                // Camping
                let camping = "No";
                if (row[27].toLowerCase() === "yes") {
                    camping = "Yes";
                } else if (row[27].toLowerCase() === "maybe") {
                    camping = "Maybe";
                }

                // Prefers
                let prefers = "Any length";
                if (row[28].toLowerCase() === "single") {
                    prefers = "Single Day Jobs";
                } else if (row[28].toLowerCase() === "short") {
                    prefers = "Short Jobs (1-3 Days)";
                } else if (row[28].toLowerCase() === "long") {
                    prefers = "Long Jobs (4-10 Days)";
                }

                let crewMember = {
                    name: row[0],
                    email: row[1],
                    phone: row[2],
                    location: row[3],
                    age: row[4],
                    licences: licences,
                    licencesOther: row[11],
                    skills: skills,
                    skillsOther: row[24],
                    hasCar: hasCar,
                    lastMinute: lastMinute,
                    camping: camping,
                    prefers: prefers
                };

                crew.push(crewMember);

            });

            callback(crew);
        }
    });

};

export default {
    testBulkAddObject: testBulkAddObject,
    parseBulkAddCSV: parseBulkAddCSV,
}