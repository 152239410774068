// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './textinput.scss';

// TextInput component
const TextInput = React.forwardRef((props, ref) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, []);

    // Handle change
    const handleChange = () => {

        setValue(ref.current.value)

        if (props.onChangeCallback) {
            props.onChangeCallback(ref.current.value);
        }

    };

    // Work out classes
    let classes = "text-input";
    if (props.hideLabel) {
        classes += " text-input-hideLabel";
    }
    if (props.fixedNumber) {
        classes += " text-input-fixedNumber";
    }
    if (props.noMargin) {
        classes += " text-input-noMargin";
    }

	// Return component
	return (

		<div className={classes}>

            {props.hideLabel ? null : <span>{props.title}:</span>}

            {props.longText ?
                <textarea placeholder={props.placeholder ? props.placeholder : props.title.toLowerCase()} ref={ref} value={value} onChange={() => { handleChange(); }}></textarea>
            :
                <input type={props.type} placeholder={props.placeholder ? props.placeholder : props.title.toLowerCase()} ref={ref} value={value} onChange={ () => { handleChange(); }} />
            }

		</div>

    )

});

TextInput.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    initialValue: PropTypes.string,
    onChangeCallback: PropTypes.func,
    fixedNumber: PropTypes.bool,
    placeholder: PropTypes.string,
    longText: PropTypes.bool,
    noMargin: PropTypes.bool
}

// Export Component
export default TextInput;
