import store from 'store';
import axios from 'axios';
import session from './session';
import defaults from './defaults';
axios.default;

/*
 * Functions
*/

// Get third party
const getThirdParty = async (url) => {

    // Response
	try {

		// Perform request
		let response = await axios.get(url);

		// If dev then log response data
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

			console.log("------------------");
			console.log("------------------");
			console.log("API Third Party Log");
			console.log(`URL: ${url}`);
			console.log("Response Data:");
			console.log(response.data);
			console.log("------------------");
			console.log("------------------");

		}

		// Return data
		return response.data;

	} catch (error) {

		// If dev then log response otherwise log properly
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing') {

			// Handle Error Here
			console.error("------------------");
			console.error("------------------");
			console.error("API Third Party Error Log");
			console.error(`URL: ${url}`);
			console.error("Error:");
			console.error(error);
			console.error("------------------");
			console.error("------------------");

		} else {

			console.error("------------------");
			console.error("------------------");
			console.error("API Third Party Error Log");
			console.error(`URL: ${url}`);
			console.error("Error:");
			console.error(error);
			console.error("------------------");
			console.error("------------------");

		}

		// TODO: figure this out properly
		return {
			error: {
				status: 400
			}
		};

	}

}

// Get data
const get = async (endpoint, requiresAuth = false, force = false) => {

	// Check if cacheable and return if cached
	let cached = getCachedEndpoint(endpoint, force);
	if (cached !== false) {
		return cached;
	}

	// Response
	try {

		// Perform request
		let response = null;
		if (requiresAuth) {
			response = await axios.get(`${apiURL()}${endpoint}`, {
				headers: {
					'x-access-token': session.get().token
				}
			});
		} else {
			response = await axios.get(`${apiURL()}${endpoint}`);
		}

		// If dev then log response data
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

			console.log("------------------");
			console.log("------------------");
			console.log("API Log");
			console.log(`URL: ${apiURL()}`);
			console.log(`Endpoint: ${endpoint}`);
			console.log("Response Data:");
			console.log(response.data);
			console.log("------------------");
			console.log("------------------");

		}

		// Save new session
        if (response.data.session) {
            session.setSession(response.data.session.token);
        }

        // Save user
        if (endpoint === '/user/login' || endpoint === '/user/session/check') {
            session.setUser(response.data.data.user);
        }

        // Check if any error should be displayed
        if (response.data.error.code === 24) {
            console.log("TODO:");
            console.log("Display error to user as it is code 24.");
        }

		// Cache
		if (isCacheable(endpoint)) {
			updateCachedEndpoints(endpoint, response.data);
		}

		// Return data
		return response.data;

	} catch (error) {

		// If dev then log response otherwise log properly
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing') {

			// Handle Error Here
			console.error("------------------");
			console.error("------------------");
			console.error("API Error Log");
			console.error(`URL: ${apiURL()}`);
			console.error(`Endpoint: ${endpoint}`);
			console.error("Error:");
			console.error(error);
			console.error("------------------");
			console.error("------------------");

		} else {

			console.error("------------------");
			console.error("------------------");
			console.error("API Error Log");
			console.error(`URL: ${apiURL()}`);
			console.error(`Endpoint: ${endpoint}`);
			console.error("Error:");
			console.error(error);
			console.error("------------------");
			console.error("------------------");

		}

		// TODO: figure this out properly
		return {
			error: {
				status: 400
			}
		};

	}

};

// Post data
const post = async (endpoint, data, requiresAuth = false, form = false, formData) => {

	// Response
    try {

        // Perform request
        let headers = {};
        if (requiresAuth) {
            headers['x-access-token'] = session.get().token;
        }
		if (form) {
			headers['Content-Type'] = 'multipart/form-data';
		}
		let response = null;
		if (form) {
			response = await axios({
				method: "post",
				url: `${apiURL()}${endpoint}`,
				data: formData,
				headers: headers,
			  });
		} else {
			response = await axios.post(`${apiURL()}${endpoint}`, data, {
				headers: headers
			});
		}

        // If dev then log response data
        if (process.env.NODE_ENV === 'development') {

            console.log("------------------");
            console.log("------------------");
            console.log("API Log");
            console.log(`URL: ${apiURL()}`);
            console.log(`Endpoint: ${endpoint}`);
            console.log("Response Data:");
            console.log(response.data);
            console.log("------------------");
            console.log("------------------");

        }

        // Save new session
        if (response.data.session) {
            session.setSession(response.data.session.token);
        }

        // Save user
        if (endpoint === '/user/login' || endpoint === '/user/session/check') {
            session.setUser(response.data.data.user);
        }

        // Check if any error should be displayed
        if (response.data.error.code === 24) {
            console.log("TODO:");
            console.log("Display error to user as it is code 24.");
        }

        // Return data
        return response.data;

    } catch (error) {

        // If dev then log response otherwise log properly
        if (process.env.NODE_ENV === 'development') {

            // Handle Error Here
            console.error("------------------");
            console.error("------------------");
            console.error("API Error Log");
            console.error(`URL: ${apiURL}`);
            console.error(`Endpoint: ${endpoint}`);
            console.error("Error:");
            console.error(error);
            console.error("------------------");
            console.error("------------------");

        } else {

            console.error("------------------");
            console.error("------------------");
            console.error("API Error Log");
            console.error(`URL: ${apiURL}`);
            console.error(`Endpoint: ${endpoint}`);
            console.error("Error:");
            console.error(error);
            console.error("------------------");
            console.error("------------------");

        }

        // TODO: figure this out properly
        return {
            error: {
                status: 400
            }
        };

    }

};

const downloadFile = async (filePath, requiresAuth = false) => {

	try {

		// Get template
		const response = await axios({
			url: `${apiURL()}/assets${filePath}`,
			method: "GET",
			headers: {
				'x-access-token': requiresAuth ? session.get().token : '',
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': '0',
			},
			responseType: 'arraybuffer'
		});

		// Check status
		if (response.status === 200) {

			// Download
			const link = document.createElement("a");
			const file = new Blob([response.data], { type: response.headers['content-type'].split(';')[0] });
			link.href = URL.createObjectURL(file);
			link.download = filePath.split('/').pop();
			link.click();
			URL.revokeObjectURL(link.href);

			return true;

		} else {

			return false

		}

	} catch (error) {

		if (process.env.NODE_ENV === 'development') {
			console.log(error);
		}
		return false;

	}

};

/*
 * Helper Functions
*/

// Work out api url
const apiURL = () => {

  let apiURL = "https://internal.api.vikingevents.co.uk";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing') {
    apiURL = "http://localhost:4001";
  }

  return apiURL;

}

// Cachable endpoints
const cachableEndpoints = [
	'/crew',
	'/stock',
	'/clients',
	'/users',
	'/projects',
	'/project',
	'/crew/overview',
	'/stock/overview',
	'/projects/overview'
];

// Check if is cacheable
const isCacheable = (endpoint) => {

	let isCacheable = false;
	cachableEndpoints.forEach((item) => {
		if (endpoint === item || endpoint.includes('/project/')) {
			isCacheable = true;
		}
	});
	return isCacheable;

};

// Update cached endpoints
const updateCachedEndpoints = (endpoint, data) => {

	if (isCacheable(endpoint)) {

		// Get cached endpoints
		let cachedEndpoints = store.get(defaults.consts.CACHED_ENDPOINTS);

		let cachedIndex = -1;
		if (cachedEndpoints !== undefined) {
			cachedEndpoints.forEach((item, index) => {
				if (item.endpoint === endpoint) {
					cachedIndex = index;
				}
			});
		} else {
			cachedEndpoints = [];
		}

		if (cachedIndex === -1) {

			cachedEndpoints.push({
				endpoint: endpoint,
				updated: Date.now(),
				data: data
			});

		} else {

			cachedEndpoints[cachedIndex].updated = Date.now();
			cachedEndpoints[cachedIndex].data = data;

		}

		store.set(defaults.consts.CACHED_ENDPOINTS, cachedEndpoints);

	}

}

// Get cached endpoint
const getCachedEndpoint = (endpoint, force) => {

	if (isCacheable(endpoint) && !force) {

		// Check if cached
		let cache = store.get(defaults.consts.CACHED_ENDPOINTS);
		if (cache !== undefined) {

			let cacheIndex = -1;
			cache.forEach((item, index) => {
				if (item.endpoint === endpoint) {
					cacheIndex = index;
				}
			});

			if (cacheIndex !== -1) {

				let cacheItem = cache[cacheIndex];

				// Check if expired
				let updated = cacheItem.updated;
				let now = Date.now();
				let interval = defaults.consts.CACHE_EXPIRY;

				if ((updated + interval) < now || updated === undefined) {
					return false;
				} else {
					return cacheItem.data;
				}

			}

			return false;

		}

		return false;

	}

	return false;

};

// Update project cache
const updateProjectCache = (projectId, key, value) => {

	// Get from cache
	let cache = store.get(defaults.consts.CACHED_ENDPOINTS);
	let cacheIndex = -1;
	if (cache !== undefined) {
		cache.forEach((item, index) => {
			if (item.endpoint === `/project/${projectId}`) {
				cacheIndex = index;
			}
		});
	}
	if (cacheIndex !== -1) {
		if (key === "project") {
			cache[cacheIndex].data.data.project = value;
		} else {
			cache[cacheIndex].data.data.project[key] = value;
		}
		store.set(defaults.consts.CACHED_ENDPOINTS, cache);
		return true;
	} else {
		return false;
	}

}

/*
 * Exports
*/

export default {
	post: post,
	get: get,
    getThirdParty: getThirdParty,
	apiURL: apiURL,
	downloadFile: downloadFile,
	updateProjectCache: updateProjectCache
}
