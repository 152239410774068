// Imports
import React, { useRef, useEffect } from 'react';
import './loginpage.scss';

// Utils
import notification from '../../../utils/notification';
import api from '../../../utils/api';
import session from '../../../utils/session';

// Components
import Meta from '../../Meta';
import TextInput from '../../inputs/TextInput';
import Spinner from '../../spinners/Spinner';
import Form from '../../Form';

// Assets
import LogoTransOrange from '../../../assets/logos/logo-trans-orange.png'

// LoginPage component
const LoginPage = () => {

    // Refs
    const usernameRef = useRef();
    const passwordRef = useRef();
    const spinnerRef = useRef();

    // Submit login
    const submitLogin = async () => {

        // Get values
        let username = usernameRef.current.value;
        let password = passwordRef.current.value;

        // Check values
        if (username && password) {

            // Show spinner
            spinnerRef.current.show();

            // Send request
            let response = await api.post('/user/login', {
                username: username,
                password: password
            });

            if (response.status === 200) {

                // Save session
                session.loggedIn(response.data.user, response.session.token);

                // Reload
                window.location.reload(false);

            } else {

                // Show error
                notification.error("Invalid username or password");

            }

            // Hide spinner
            spinnerRef.current.hide();

        } else {

            // Show error
            notification.error('Please enter your username and password');

        }

    }

	// Return component
	return (

		<div className="login-page">

			<Meta
				title="Login"
			/>

            <div className="login-inner">

                <img src={LogoTransOrange} alt="Viking Logo" />

                <span>VMS</span>

                <h1>Login</h1>

                <Form submitTitle='Login' submitCallback={submitLogin} fullWidth hideBackground noMargin>

                    <TextInput title="Username" type="text" ref={usernameRef} />

                    <TextInput title="Password" type="password" ref={passwordRef} />

                </Form>

            </div>

            <Spinner small theme="primary" ref={spinnerRef} />

		</div>

    )

};

// Export Component
export default LoginPage;
