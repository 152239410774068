// Imports
import React, { useState, useRef, useEffect } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);
import './projectsoverview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';

// Assets
import ReloadIcon from '../../../../assets/icons/reload-icon-primary.png'
import { set } from 'store';

// ProjectsOverview component
const ProjectsOverview = () => {

    // Refs
    const spinnerRef = useRef(null);

    // State
    const [overview, setOverview] = useState([]);
    const [overviewAll, setOverviewAll] = useState([]);
    const [overviewActive, setOverviewActive] = useState([]);
    const [overviewCompleted, setOverviewCompleted] = useState([]);
    const [overviewCancelled, setOverviewCancelled] = useState([]);
    const [overviewType, setOverviewType] = useState('all');

    // Get overview
    useEffect(() => {

        getOverview();

    }, []);

    // Get overview
    const getOverview = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/projects/overview', true, force);

        if (response.status === 200) {

            let overviewAllTemp = [];
            let overviewActiveTemp = [];
            let overviewCompletedTemp = [];
            let overviewCancelledTemp = [];

            response.data.overview.all.forEach((item) => {
                overviewAllTemp.push({
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                });
            });

            response.data.overview.active.forEach((item) => {
                overviewActiveTemp.push({
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                });
            });

            response.data.overview.completed.forEach((item) => {
                overviewCompletedTemp.push({
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                });
            });

            response.data.overview.cancelled.forEach((item) => {
                overviewCancelledTemp.push({
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                });
            });

            setOverviewAll(overviewAllTemp);
            setOverviewActive(overviewActiveTemp);
            setOverviewCompleted(overviewCompletedTemp);
            setOverviewCancelled(overviewCancelledTemp);
            setOverview(overviewAllTemp);
            notification.success("Overview loaded.");
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Switch type
    const switchType = (type) => {

        switch (type) {
            case "all":
                setOverview(overviewAll);
                break;
            case "active":
                setOverview(overviewActive);
                break;
            case "completed":
                setOverview(overviewCompleted);
                break;
            case "cancelled":
                setOverview(overviewCancelled);
                break;
        }

        setOverviewType(type);

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Projects - Overview"
            />

            <div className="overview-header">

                <h1>Overview</h1>

                <div className="overview-header-controls">

                    <div className={(overviewType === 'all') ? "overview-header-button ohb-active" : "overview-header-button"} onClick={() => { switchType('all'); }}>All</div>
                    <div className={(overviewType === 'active') ? "overview-header-button ohb-active" : "overview-header-button"} onClick={() => { switchType('active'); }}>Active</div>
                    <div className={(overviewType === 'completed') ? "overview-header-button ohb-active" : "overview-header-button"} onClick={() => { switchType('completed'); }}>Completed</div>
                    <div className={(overviewType === 'cancelled') ? "overview-header-button ohb-active" : "overview-header-button"} onClick={() => { switchType('cancelled'); }}>Cancelled</div>

                    <div className="reload-button">
                        <img src={ReloadIcon} alt="Reload" onClick={() => { getOverview(true); }} />
                    </div>

                </div>

            </div>

            {overview.length > 0 && 
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={overview}
                    style={{ height: "750px", width: "100%", marginTop: "20px" }}
                />
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default ProjectsOverview;
