// Imports
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './projectaddstockpopup.scss';

// Components
import Form from '../../../Form';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';

// Utils
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import api from '../../../../utils/api';

// ProjectAddStockPopup component
const ProjectAddStockPopup = (props) => {

    // Refs
    const stockRef = useRef(null);
    const quantityRef = useRef(null);

    // State
    const [stock, setStock] = useState([]);
    const [stockOptions, setStockOptions] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);

    // Get stock
    useEffect(() => {

        getStock();

    }, []);

    const getStock = async (force = false) => {

        //props.spinnerRef.current.show();

        /*let response = await api.get('/stock', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.stock, 'name', 'asc');
            setStock(formatted);

            let options = [{
                value: 'select',
                title: "Select a stock item"
            }];
            formatted.forEach((stockItem) => {
                options.push({
                    value: stockItem._id,
                    title: stockItem.name
                });
            });
            setStockOptions(options);

            props.spinnerRef.current.hide();
            notification.success("Stock loaded.");

        } else {

            notification.error("Something went wrong, please try again soon.");
            props.spinnerRef.current.hide();

        }*/

        let stockAvailability = props.project.availability;
        let formatted = format.sortObjs(stockAvailability, 'name', 'asc');
        setStock(formatted);

        let options = [{
            value: 'select',
            title: "Select a stock item"
        }];
        formatted.forEach((stockItem) => {
            options.push({
                value: stockItem._id,
                title: stockItem.name
            });
        });
        setStockOptions(options);

    };

    // Submit clicked
    const submitClicked = async () => {

        // Get values
        let item = selectedStock;
        let quantity = quantityRef.current.value;

        // Validate
        let readyToSubmit = true;
        if (!quantity) {
            notification.error("Please enter a quantity");
            readyToSubmit = false;
        }
        if (parseInt(quantity) > parseInt(item.quantity)) {
            notification.error("Quantity exceeds stock quantity");
            readyToSubmit = false;
        }
        if (parseInt(quantity) > parseInt(item.available)) {
            notification.error("Quantity exceeds available stock quantity");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            props.spinnerRef.current.show();

            let response = await api.post(`/project/stock/add`, {
                stock: {
                    item: item,
                    quantity: parseInt(quantity),
                    project: props.project._id,
                    startDate: props.project.dates.start.raw,
                    endDate: props.project.dates.end.raw
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    props.spinnerRef.current.hide();

                } else {

                    notification.success('Stock added.');
                    props.spinnerRef.current.hide();
                    props.successCallback(response.data.project);

                }


            } else {

                notification.error("Something went wrong, please try again soon.");
                props.spinnerRef.current.hide();

            }

        }

    };

    // Select changed
    const selectChanged = (option) => {

        // Get stock item
        let item = stock.find(x => x._id === option);
        if (option === "select") {
            setSelectedStock(null);
        } else {
            setSelectedStock(item);
        }


    };

    // Return component
    return (

        <div className="pas-popup">

            <h2>Add Stock</h2>

            <Form submitTitle="Add" submitCallback={submitClicked}>

                <SelectInput title="Stock" options={stockOptions} ref={stockRef} showReload reloadCallback={() => { props.projectReloadCallback(true); }} onChangeCallback={selectChanged} />

                {selectedStock &&
                    <div className="pas-popup-available">
                        <span><strong>Quantity:</strong> {selectedStock.quantity}</span>
                        <span><strong>Available:</strong> {selectedStock.available}</span>
                    </div>
                }

                <TextInput title="Quantity" type="number" ref={quantityRef} />

            </Form>

        </div>

    )

};

ProjectAddStockPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired,
    projectReloadCallback: PropTypes.func.isRequired
};

// Export Component
export default ProjectAddStockPopup;