// Imports
import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';

// Utils
import session from '../../utils/session';

// Assets
import LogoTransWhite from '../../assets/logos/logo-trans-white.png'

// Header Component
const Header = () => {

    // Get session
    let sessionObj = session.get();

    // Logout
    const logout = () => {

        // Remove session
        session.remove();

        // Reload
        window.location.href = "/";

    };

	// Return component
	return (

		<div className="header">

            <Link to="/" >

                <div className="header-logo">

                    <img src={LogoTransWhite} alt="Viking Logo" />

                    <span>VMS</span>

                </div>

            </Link>

            <div className="header-account">

                <span>{sessionObj.user.email}</span>
                <span>|</span>
                <span onClick={() => { logout(); }}>Logout</span>

            </div>

		</div>

	);

};

// Export
export default Header;
