// Imports
import React from 'react';
import './pagenotfoundpage.scss';

// Components
import Meta from '../../Meta';

// PageNotFoundPage component
const PageNotFoundPage = () => {

	// Return component
	return (

		<div className="pagenotfound-page">

			<Meta
				title="Page Not Found"
			/>

            <h1>Page Not Found Page</h1>

		</div>

    )

};

// Export Component
export default PageNotFoundPage;
