// Imports
import React, { useState } from 'react';
import './clientspage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import ClientsViewView from '../../views/clients/ClientsViewView';
import ClientsAddView from '../../views/clients/ClientsAddView';

// ClientsPage component
const ClientsPage = () => {

    // State
    const [activeView, setActiveView] = useState("View");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    const nav = [
        {
            title: "View",
            callback: navButtonClicked,
        },
        {
            title: "Add",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="clientspage">

            <Meta
                title="Clients"
            />

            <SideNavigation nav={nav} title="CLIENTS" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "View" ? <ClientsViewView /> : null}

            {activeView === "Add" ? <ClientsAddView /> : null}

        </div>

    )

};

// Export Component
export default ClientsPage;
