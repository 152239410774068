// Generate emp
const emp = (project) => {
    return `# Hello World
    
This is a sample project for ${project.name}.`;
}

// Export 
export default {
    emp: emp
}