// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './primarybutton.scss';

// PrimaryButton component
const PrimaryButton = (props) => {

    // Work out classes
    let classes = 'primarybutton';
    if (props.noMargin) {
        classes += ' primarybutton-nomargin';
    }

    // Return component
    return (

        <div className={classes} onClick={() => { props.callback(); }}>{props.title}</div>

    )

};

PrimaryButton.propTypes = {
    title: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    noMargin: PropTypes.bool
};

// Export Component
export default PrimaryButton;
