// Imports
import React, { useState } from 'react';
import './errorboundary.scss';

import LogoTransBlack from '../../assets/logos/logo-trans-black.png';

// ErrorBoundary Component
class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// LogErrorToMyService(error, errorInfo);
		console.error(error);
		console.error(errorInfo);
		// TODO: Do propper log with notification to dev team
	}

	// Go back
	goBack() {
		this.context.router.history.goBack;
	}

	// Show test error
	showTestError() {
		this.setState({
			hasError: true
		});
	}

	render() {

		// Check if error
		if (this.state.hasError) {

			// Return error page
			return (

				<div className="background-gradient">

					<div className="error-boundary">

						<div className="error-boundary-logo">

							<img src={LogoTransBlack} alt="VMS" />

						</div>

						<h1>Oops.</h1>

						<p>It looks like something has gone wrong or this page may be broken.</p>

						<p>Don't worry, our team has been notified and will get this fixed asap!</p>

						<div className="error-boundary-buttons">

							<div className="error-boundary-back" onClick={() => { this.goBack(); }}>GO BACK</div>

						</div>

					</div>

				</div>

			);
		}

		// Return app
		return this.props.children;

	}

}

// Export
export default ErrorBoundary;
