// Imports
import { toast } from 'react-toastify';

// Show default notification
const def = (text) => {
	toast(text);
};

// Show error notification
const error = (text) => {
	toast.error(text);
};

// Show warning notification
const warning = (text) => {
	toast.warning(text);
};

// Show success notification
const success = (text) => {
	toast.success(text);
};

// Show info notification
const info = (text) => {
	toast.info(text);
};

export default {
	default: def,
	error: error,
	warning: warning,
	success: success,
	info: info
};
