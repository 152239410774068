// Imports
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './stockviewview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';
import TextInput from '../../../inputs/TextInput';
import SubmitButton from '../../../buttons/SubmitButton';
import Table from '../../../Table';
import TableDataPopup from '../../../popups/TableDataPopup';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';

// StockViewView component
const StockViewView = () => {

    // Refs
    const nameRef = useRef(null);
    const quantityRef = useRef(null);
    const warehouseLocationRef = useRef(null);
    const spinnerRef = useRef(null);

    // State
    const [stock, setStock] = useState([]);
    const [stockStatic, setStockStatic] = useState([]);
    const [stockOptions, setStockOptions] = useState([]);
    const [selectedStock, setSelectedStock] = useState({});
    const [selectedStockIsOpen, setSelectedStockIsOpen] = useState(false);
    const [selectedStockDetailsEditing, setSelectedStockDetailsEditing] = useState(false);
    const [selectedRequires, setSelectedRequires] = useState([]);

    // Get stock
    useEffect(() => {

        getStock();

    }, []);

    // Get stock
    const getStock = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/stock', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.stock, 'name', 'asc');
            setStock(formatted);
            setStockStatic(formatted);

            let options = [];
            formatted.forEach((stockItem) => {
                options.push({
                    value: stockItem._id,
                    label: stockItem.name
                });
            });
            setStockOptions(options);

            spinnerRef.current.hide();
            notification.success("Stock loaded.");

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Stock item clicked
    const tableRowClickedCallback = (stockItem) => {

        if (stockItem.requires) {

            let requires = [];
            stockItem.requires.forEach((require) => {
                requires.push({
                    value: require.id,
                    label: require.name,
                    quantity: require.quantity
                });
            });
            setSelectedRequires(requires);

        }
        setSelectedStock(stockItem);
        setSelectedStockIsOpen(true);

    };

    // Search input changed
    const searchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = stockStatic;

        } else {

            stockStatic.forEach((stockItem) => {

                // Check name
                if (stockItem.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.name === stockItem.name)) {
                        results.push(stockItem);
                    }
                }

                // Check warehouse location
                if (stockItem.warehouseLocation.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.name === stockItem.name)) {
                        results.push(stockItem);
                    }
                }

            });

        }

        setStock(results);

    };

    // Close selected stock
    const closeSelectedStock = () => {

        setSelectedStock({});
        setSelectedStockIsOpen(false);
        setSelectedStockDetailsEditing(false);
        setSelectedRequires([]);

    };

    // Delete selected stock
    const selectedStockDelete = async () => {

        if (window.confirm(`Are you sure you want to delete ${selectedStock.name}? This cannot be undone and will complete remove it from the system.`)) {

            spinnerRef.current.show();

            let response = await api.post('/stock/delete', {
                stock: selectedStock
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Stock deleted.");
                    spinnerRef.current.hide();
                    let stockIndex = stock.findIndex(x => x._id === selectedStock._id);
                    let stockStaticIndex = stockStatic.findIndex(x => x._id === selectedStock._id);
                    let stockOptionsIndex = stockOptions.findIndex(x => x.value === selectedStock._id);
                    let tempStock = stock;
                    let tempStockStatic = stockStatic;
                    let tempStockOptions = stockOptions;
                    if (stockIndex > -1) {
                        tempStock.splice(stockIndex, 1);
                    }
                    if (stockStaticIndex > -1) {
                        tempStockStatic.splice(stockStaticIndex, 1);
                    }
                    if (stockOptionsIndex > -1) {
                        tempStockOptions.splice(stockOptionsIndex, 1);
                    }
                    setStock(tempStock);
                    setStockStatic(tempStockStatic);
                    setStockOptions(tempStockOptions);
                    closeSelectedStock();

                }

            } else {

                notification.error("Error deleting stock.");
                spinnerRef.current.hide();

            }

        }

    }

    // Update selected stock
    const updateSelectedStockCallback = async () => {

        // Get values
        let name = nameRef.current.value;
        let quantity = quantityRef.current.value;
        let warehouseLocation = warehouseLocationRef.current.value;
        let requires = [];
        let requiresGet = [];
        selectedRequires.forEach((require) => {
            requires.push({
                id: require.value,
                quantity: require.quantity
            });
            requiresGet.push({
                id: require.value,
                quantity: require.quantity,
                name: require.label
            });
        });

        // Validate
        let readyToSubmit = true;

        if (!name) {
            readyToSubmit = false;
            notification.error("Please enter a name.");
        }

        if (!quantity) {
            readyToSubmit = false;
            notification.error("Please enter a quantity.");
        }

        requires.forEach((require) => {
            if (!require.quantity) {
                readyToSubmit = false;
                notification.error("Please enter a quantity for each required item.");
            }
        });

        if (readyToSubmit) {

            let stockItem = {
                _id: selectedStock._id,
                name: name,
                quantity: quantity,
                warehouseLocation: warehouseLocation,
                requires: requires
            }

            spinnerRef.current.show();

            let response = await api.post('/stock/update', {
                stock: stockItem
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Stock updated.");
                    spinnerRef.current.hide();
                    stockItem.requires = requiresGet;
                    setSelectedStock(stockItem);
                    setSelectedStockDetailsEditing(false);

                }

            } else {

                notification.error("Error adding stock.");
                spinnerRef.current.hide();

            }

        }

    };

    // Requires select changed
    const requiresSelectChanged = (value) => {

        let exists = false;
        selectedRequires.forEach((require) => {
            if (require.value === value.value) {
                exists = true;
            }
        });

        if (!exists) {
            let requires = selectedRequires;
            requires.push({
                value: value.value,
                label: value.label,
                quantity: 1,
                ref: React.createRef()
            });
            setSelectedRequires([...requires]);
        }

    };

    // Requires select removed
    const requiresSelectRemoved = (id) => {
        let requires = selectedRequires;
        requires = requires.filter((require) => {
            return require.value !== id;
        });
        setSelectedRequires(requires);
    };

    // Requires quantity changed
    const requiresQuantityChanged = (id, value) => {
        console.log(id);
        console.log(value);
        let requires = selectedRequires;
        requires.forEach((require) => {
            if (require.value === id) {
                require.quantity = value;
            }
        });
        setSelectedRequires([...requires]);
    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Stock - View"
            />

            <h1>Stock</h1>

            <Table headerItems={[
                {
                    title: "Name",
                    width: "690px",
                    isSortable: true,
                },
                {
                    title: "Quantity",
                    width: "200px",
                    isSortable: true,
                },
                {
                    title: "Warehouse Location",
                    width: "200px",
                    isSortable: true,
                }
            ]} 
            data={stock} 
            tableRowClickedCallback={tableRowClickedCallback} 
            searchInputChangedCallback={searchInputChangedCallback} 
            reloadCallback={() => { getStock(true); }}
            />

            <Spinner small theme="white" ref={spinnerRef} />

            {selectedStockIsOpen ?

                <TableDataPopup closeCallback={closeSelectedStock} isEditingState={selectedStockDetailsEditing} setIsEditingState={setSelectedStockDetailsEditing}>

                    {selectedStockDetailsEditing ?

                    <div className="tdp-section">

                        <TextInput title="Name" type="text" ref={nameRef} initialValue={selectedStock.name} />

                        <TextInput title="Quantity" type="number" ref={quantityRef} initialValue={selectedStock.quantity} />

                        <TextInput title="Warehouse Location" type="tel" ref={warehouseLocationRef} initialValue={selectedStock.warehouseLocation} />

                        <div className="requires-input">

                            <span>Requires:</span>

                            <Select
                                onChange={requiresSelectChanged}
                                options={stockOptions}
                                isSearchable={true}
                                styles={{
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '300px',
                                    marginTop: '20px'
                                    }),
                                }}
                            />

                            <div className="requires-input-added">

                                {selectedRequires.map((require, index) => (

                                        <div className="requires-input-added-item" key={index}>
                                            <TextInput title="Quantity" type="number" initialValue={require.quantity.toString()} hideLabel fixedNumber ref={require.ref} onChangeCallback={ () => { requiresQuantityChanged(require.value, require.ref.current.value); }} />
                                            <span>{require.label}</span>
                                            <span onClick={() => { requiresSelectRemoved(require.value); }}>X</span>
                                        </div>

                                ))}

                            </div>

                        </div>

                        <SubmitButton title="Update" callback={updateSelectedStockCallback} />

                    </div>

                    :

                    <div className="tdp-section">

                        <span><strong>Name:</strong> {selectedStock.name}</span>

                        <span><strong>Quantity:</strong> {selectedStock.quantity}</span>

                        <span><strong>Warehouse Location:</strong> {selectedStock.warehouseLocation}</span>

                        <span><strong>Requires:</strong></span>

                        {selectedStock.requires ?
                            selectedStock.requires.map((require, index) => (
                                <span key={index}>{`${require.quantity} x ${require.name}`}</span>
                            ))
                            :
                            <span>No required items.</span>
                        }

                    </div>

                    }

                    <div className="tdp-delete" onClick={() => { selectedStockDelete(); }}>Delete</div>

                </TableDataPopup>

                :
                ''
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>


    )

};

// Export Component
export default StockViewView;
