// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './submitbutton.scss';

// SubmitButton component
const SubmitButton = (props) => {

    // Work out classes
    let classes = "submit-button";
    if (props.fullWidth) {
        classes += " sb-full-width";
    }
    if (props.noMargin) {
        classes += " sb-no-margin";
    }

	// Return component
	return (

		<div className={classes}>

            {props.callback ?
                <button type="submit" onClick={() => { props.callback(); }}>{props.title}</button>
            :
                <button type="submit">{props.title}</button>
            }

		</div>

    )

};

SubmitButton.propTypes = {
    title: PropTypes.string.isRequired,
    callback: PropTypes.func,
    fullWidth: PropTypes.bool,
    noMargin: PropTypes.bool,
}

// Export Component
export default SubmitButton;
