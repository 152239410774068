// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './dateinput.scss';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

// Components
import Meta from '../../Meta';

// DateInput component
const DateInput = (props) => {

    const fromYear = new Date().getFullYear();
    const toYear = fromYear + 10;

    // Return component
    return (

        <div className="date-input">

            {props.hideLabel ? null : <span>{props.title}:</span>}

            <DayPicker
            mode="single"
            selected={props.value}
            onSelect={props.setter}
            captionLayout="dropdown-buttons"
            fromYear={fromYear}
            toYear={toYear}
            showOutsideDays
            />

        </div>

    )

};

DateInput.propTypes = {
    title: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    value: PropTypes.instanceOf(Date).isRequired,
    setter: PropTypes.func.isRequired,
}

// Export Component
export default DateInput;
