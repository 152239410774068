// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files'
import './projectadddocumentpopup.scss';

// Components
import Form from '../../../Form';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';

// Utils
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import api from '../../../../utils/api';

// ProjectAddDocumentPopup component
const ProjectAddDocumentPopup = (props) => {

    const categoryOptions = [
        {
            value: 'select',
            title: "Select a category"
        },
        {
            value: 'emp',
            title: "EMP"
        },
        {
            value: 'site-plan',
            title: "Site Plan"
        },
        {
            value: 'risk-assessment',
            title: "Risk Assessment"
        },
        {
            value: 'general-document',
            title: "General Document"
        },
        {
            value: 'image',
            title: "Image"
        },
        {
            value: 'video',
            title: "Video"
        },
        {
            value: 'misc',
            title: "Misc"
        }
    ];

    // Refs
    const fileNameRef = useRef(null);
    const versionRef = useRef(null);
    const categoryRef = useRef(null);
    const projectPortalVisibleRef = useRef(null);
    const crewVisibleRef = useRef(null);

    // State
    const [file, setFile] = useState(null)
    const [showUpload, setShowUpload] = useState(true)
    const [showDetails, setShowDetails] = useState(false)

    const handleChange = (files) => {
        setFile(files[0])
        setShowUpload(false);
        setShowDetails(true);
    }

    const handleError = (error, file) => {
        notification.error(error.message);
    }

    // Back button clicked
    const backButtonClicked = () => {

        setFile(null);
        setShowUpload(true);
        setShowDetails(false);

    };

    // Submit button clicked
    const submitButtonClicked = async () => {

        // Get values
        const fileName = fileNameRef.current.value;
        const version = versionRef.current.value;
        const category = categoryRef.current.value;
        const projectPortalVisible = projectPortalVisibleRef.current.value;
        const crewVisible = crewVisibleRef.current.value;

        // Validate
        let readyToSubmit = true;

        if (fileName.length < 1) {
            notification.error("Please enter a file name");
            readyToSubmit = false;
        }
        if (category === 'select') {
            notification.error("Please select a category");
            readyToSubmit = false;
        }
        if (projectPortalVisible === 'select') {
            notification.error("Please select an option for 'Visible On Project Portal'");
            readyToSubmit = false;
        }
        if (crewVisible === 'select') {
            notification.error("Please select an option for 'Visible To Crew'");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            props.spinnerRef.current.show();

            let formData = new FormData();
            formData.append('fileName', fileName);
            formData.append('version', version);
            formData.append('category', category);
            formData.append('projectPortalVisible', projectPortalVisible);
            formData.append('crewVisible', crewVisible);
            formData.append('project', props.project._id);
            formData.append('file', file);

            let response = await api.post(`/project/files/add`, {}, true, true, formData);

            if (response.status === 200) {

                notification.success('Document uploaded.');
                props.spinnerRef.current.hide();
                props.successCallback(response.data.project);

            } else {

                notification.error("Something went wrong, please try again soon.");
                props.spinnerRef.current.hide();

            }

        }

    };

    // Return component
    return (

        <div className="pad-popup">

            {showUpload && <Files
                className='pad-popup-dropzone'
                dragActiveClassName='pad-popup-dropzone-active'
                onChange={handleChange}
                onError={handleError}
                multiple={false}
                accepts={['image/*', 'video/mp4', 'audio/*', '.pdf']}
                minFileSize={0}
                clickable>
                Drop files here or click to upload
            </Files>}

            {showDetails && <div className="pad-popup-details">

                <span className="pad-popup-back" onClick={() => { backButtonClicked(); }}>{`< back`}</span>

                <div className="pad-popup-details-info">

                    <div>
                        <span>File Name: {file.name}</span>
                    </div>

                    <div>
                        <span>File Size: {format.fileSize(file.size)}</span>
                        <span>File Type: {file.type}</span>
                    </div>

                </div>

                <Form submitTitle="Upload" submitCallback={submitButtonClicked}>

                    <input type="hidden" name="file" value={file} />

                    <TextInput title="File Name" type="text" ref={fileNameRef} />

                    <TextInput title="Version" type="text" ref={versionRef} />

                    <SelectInput title="Category" options={categoryOptions} ref={categoryRef} />

                    <SelectInput title="Visible On Project Portal" options={[
                        {
                            value: 'select',
                            title: "Select an option"
                        },
                        {
                            value: 'yes',
                            title: "Yes"
                        },
                        {
                            value: 'no',
                            title: "No"
                        }
                    ]} ref={projectPortalVisibleRef} />

                    <SelectInput title="Visible To Crew" options={[
                        {
                            value: 'select',
                            title: "Select an option"
                        },
                        {
                            value: 'yes',
                            title: "Yes"
                        },
                        {
                            value: 'no',
                            title: "No"
                        }
                    ]} ref={crewVisibleRef} />


                </Form>

            </div>}

        </div>

    )

};

ProjectAddDocumentPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired
};


// Export Component
export default ProjectAddDocumentPopup;