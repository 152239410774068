// Imports
import React, { useRef, useEffect, useState } from 'react';
import './projectsaddview.scss';

// Components
import Meta from '../../../Meta';
import TextInput from '../../../inputs/TextInput';
import Spinner from '../../../spinners/Spinner';
import SelectInput from '../../../inputs/SelectInput';
import DateInput from '../../../inputs/DateInput';
import Form from '../../../Form';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';
import format from '../../../../utils/format';

// ProjectsAddView component
const ProjectsAddView = () => {

    // Get data
    useEffect(() => {

        getClients();

    }, []);

    const getClients = async (force = false) => {

        setClients([{
            value: "loading",
            title: "Loading..."
        }]);

        spinnerRef.current.show();

        let response = await api.get('/clients', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.clients, 'name', 'asc');
            let clientsOptions = [{
                value: "select",
                title: "Select a client..."
            }];
            formatted.forEach((client) => {
                let title = client.name;
                if (client.company) {
                    title = `${client.name} - ${client.company}`
                }
                clientsOptions.push({
                    value: client._id,
                    title: title
                });
            });
            setClients(clientsOptions);
            notification.success('Clients loaded.');
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong while loading clients, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Refs
    let spinnerRef = useRef();
    let nameRef = useRef(null);
    let clientRef = useRef(null);
    let venueRef = useRef(null);
    let descriptionRef = useRef(null);
    let statusRef = useRef(null);

    // State
    const [clients, setClients] = useState([{value: "loading", title: "Loading..."}]);
    const [startDate, setStartDate] = useState(new Date(0));
    const [endDate, setEndDate] = useState(new Date(0));

    // Submit clicked
    const submitClicked = async () => {

        // Get values
        let name = nameRef.current.value;
        let client = clientRef.current.value;
        let venue = venueRef.current.value;
        let description = descriptionRef.current.value;
        let status = statusRef.current.value;
        let startDateValue = format.projectDate(startDate);
        let endDateValue = format.projectDate(endDate);

        // Validate
        let readyToSubmit = true;
        if (name.length < 1) {
            notification.error("Please enter a project name.");
            readyToSubmit = false;
        }
        if (client === "loading" || client === "select") {
            notification.error("Please select a client.");
            readyToSubmit = false;
        }
        if (!startDate) {
            notification.error("Please select a start date.");
            readyToSubmit = false;
        }
        if (!endDate) {
            notification.error("Please select an end date.");
            readyToSubmit = false;
        }
        if (startDate > endDate) {
            notification.error("Start date must be before end date.");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            spinnerRef.current.show();

            let response = await api.post('/projects/add', {
                project: {
                    name: name,
                    client: client,
                    status: status,
                    venue: venue,
                    description: description,
                    startDate: startDateValue,
                    endDate: endDateValue
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success(`${name} added.`);
                    reset();
                    spinnerRef.current.hide();

                }

            } else {

                notification.error(`Error adding ${name}.`);
                spinnerRef.current.hide();


            }

        }

    };

     // Reset
     const reset = () => {

        setStartDate(new Date(0));
        setEndDate(new Date(0));

        // Scroll to top of page
        window.scrollTo(0, 0);

        setTimeout(() => {
            nameRef.current.value = "";
            clientRef.current.value = "select";
            venueRef.current.value = "";
            descriptionRef.current.value = "";
            statusRef.current.value = "active";
        }, 25);

    }

    // Clients reload callback
    const clientsReloadCallback = async () => {
        getClients(true);
    }

    // Return component
    return (

        <div className="view">

            <Meta
                title="Projects - Add"
            />

            <h1>Add Project</h1>

            <Form submitTitle='Add Project' submitCallback={submitClicked}>

                <TextInput title="Project Name" type="text" ref={nameRef} />

                <SelectInput title="Client" options={clients} showReload reloadCallback={clientsReloadCallback} ref={clientRef} />

                <SelectInput title="Status" options={[
                    {
                        value: "active",
                        title: "Active"
                    },
                    {
                        value: "completed",
                        title: "Completed"
                    },
                    {
                        value: "cancelled",
                        title: "Cancelled"
                    }
                ]} ref={statusRef} />

                <TextInput title="Venue" type="text" ref={venueRef} />

                <TextInput title="Description" type="text" longText ref={descriptionRef} />

                <div className="form-dates">

                    <DateInput title="Start Date" value={startDate} setter={setStartDate} />

                    <DateInput title="End Date" value={endDate} setter={setEndDate} />

                </div>

            </Form>

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default ProjectsAddView;
