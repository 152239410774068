// Imports
import React, { useRef } from 'react';
import './clientsaddview.scss';

// Components
import Meta from '../../../Meta';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import Spinner from '../../../spinners/Spinner';
import Form from '../../../Form';

// Utils
import validate from '../../../../utils/validate';
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';

// ClientsAddView component
const ClientsAddView = () => {

    // Refs
    let nameRef = useRef(null);
    let companyRef = useRef(null);
    let emailRef = useRef(null);
    let invoiceEmailRef = useRef(null);
    let phoneRef = useRef(null);
    let address1Ref = useRef(null);
    let address2Ref = useRef(null);
    let addressCityRef = useRef(null);
    let addressCountyRef = useRef(null);
    let addressPostcodeRef = useRef(null);
    let statusRef = useRef(null);
    let spinnerRef = useRef(null);

    // Add client callback
    const addClientCallback = async () => {

        // Get values
        let name = nameRef.current.value;
        let company = companyRef.current.value;
        let email = emailRef.current.value;
        let invoiceEmail = invoiceEmailRef.current.value;
        let phone = phoneRef.current.value;
        let address1 = address1Ref.current.value;
        let address2 = address2Ref.current.value;
        let addressCity = addressCityRef.current.value;
        let addressCounty = addressCountyRef.current.value;
        let addressPostcode = addressPostcodeRef.current.value;
        let status = statusRef.current.value;

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(name)) {
            notification.error("Please enter a valid full name");
            readyToSubmit = false;
        }

        if (!validate.email(email)) {
            notification.error("Please enter a valid email address");
            readyToSubmit = false;
        }

        if (!validate.email(invoiceEmail)) {
            notification.error("Please enter a valid invoice email address");
            readyToSubmit = false;
        }

        if (!validate.phone(phone)) {
            notification.error("Please enter a valid phone number");
            readyToSubmit = false;
        }

        if (!status) {
            notification.error("Please select a status");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            spinnerRef.current.show();

            let client = {
                name: name,
                company: company,
                email: email,
                invoiceEmail: invoiceEmail,
                phone: phone,
                address: {
                    line1: address1,
                    line2: address2,
                    city: addressCity,
                    county: addressCounty,
                    postcode: addressPostcode
                },
                status: status
            }

            let response = await api.post('/clients/add', {
                client: client
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Client added.");
                    spinnerRef.current.hide();
                    reset();

                }

            } else {

                notification.error("Error adding client.");
                spinnerRef.current.hide();

            }

        }

    };

    // Reset
    const reset = () => {

        nameRef.current.value = "";
        companyRef.current.value = "";
        emailRef.current.value = "";
        invoiceEmailRef.current.value = "";
        phoneRef.current.value = "";
        address1Ref.current.value = "";
        address2Ref.current.value = "";
        addressCityRef.current.value = "";
        addressCountyRef.current.value = "";
        addressPostcodeRef.current.value = "";
        statusRef.current.value = "active";

        // Scroll to top of page
        window.scrollTo(0, 0);

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Clients - Add"
            />

            <h1>Add Client</h1>

            <Form submitTitle='Add Client' submitCallback={addClientCallback}>

                <TextInput title="Full Name" type="text" ref={nameRef} />

                <TextInput title="Company" type="text" ref={companyRef} />

                <TextInput title="Email" type="email" ref={emailRef} />

                <TextInput title="Invoice Email" type="email" ref={invoiceEmailRef} />

                <TextInput title="Phone Number" type="tel" ref={phoneRef} />

                <TextInput title="Address" placeholder="1st Line of Address" type="text" ref={address1Ref} />

                <TextInput title="2nd Line of Address" type="text" ref={address2Ref} hideLabel />

                <TextInput title="City" type="text" ref={addressCityRef} hideLabel />

                <TextInput title="County" type="text" ref={addressCountyRef} hideLabel />

                <TextInput title="Postcode" type="text" ref={addressPostcodeRef} hideLabel />

                <SelectInput title="Status" options={[{value: "active", title: "Active"}, {value: "archived", title: "Archived"}]} ref={statusRef} />

            </Form>

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default ClientsAddView;
