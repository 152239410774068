// Imports
import React, { useRef, useState } from 'react';
import './stockaddview.scss';

// Components
import Meta from '../../../Meta';
import TextInput from '../../../inputs/TextInput';
import Spinner from '../../../spinners/Spinner';
import Form from '../../../Form';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';

// StockAddView component
const StockAddView = () => {

    // Refs
    const nameRef = useRef();
    const quantityRef = useRef();
    const warehouseLocationRef = useRef();
    const spinnerRef = useRef();

    // Submit clicked
    const submitClicked = async () => {

        // Get values
        const name = nameRef.current.value;
        const quantity = quantityRef.current.value;
        const warehouseLocation = warehouseLocationRef.current.value;

        // Validate
        let readyToSubmit = true;

        if (!name) {
            readyToSubmit = false;
            notification.error('Name is required');
        }

        if (!quantity) {
            readyToSubmit = false;
            notification.error('Quantity is required');
        }

        if (readyToSubmit) {

            spinnerRef.current.show();

            let response = await api.post('/stock/add', {
                stock: {
                    name: name,
                    quantity: quantity,
                    warehouseLocation: warehouseLocation,
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success(`${name} added.`);
                    nameRef.current.value = "";
                    quantityRef.current.value = "";
                    warehouseLocationRef.current.value = "";
                    spinnerRef.current.hide();

                }

            } else {

                notification.error(`Error adding ${name}.`);
                spinnerRef.current.hide();


            }

        }

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Stock - Add"
            />

            <h1>Add Stock</h1>

            <Form submitTitle='Add Stock' submitCallback={submitClicked}>

                <TextInput title="Name" type="text" ref={nameRef} />

                <TextInput title="Quantity" type="number" ref={quantityRef} />

                <TextInput title="Warehouse Location" type="text" ref={warehouseLocationRef} />

            </Form>

            <Spinner small theme="white" ref={spinnerRef} />

        </div>


    )

};

// Export Component
export default StockAddView;
