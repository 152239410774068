// Imports
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
axios.default;
import './crewaddview.scss';

// Components
import Meta from '../../../Meta';
import PrimaryButton from '../../../buttons/PrimaryButton';
import TextInput from '../../../inputs/TextInput';
import CheckboxInput from '../../../inputs/CheckboxInput';
import RadioInput from '../../../inputs/RadioInput';
import Spinner from '../../../spinners/Spinner';
import Form from '../../../Form';

// Utils
import notification from '../../../../utils/notification';
import validate from '../../../../utils/validate';
import api from '../../../../utils/api';
import crew from '../../../../utils/crew';
import session from '../../../../utils/session';

// CrewAddView component
const CrewAddView = () => {

    // Data
    let licencesDefault = [
        {
            value: "Car",
            isChecked: false,
        },
        {
            value: "Class 1 HGV",
            isChecked: false,
        },
        {
            value: "Class 2 HGV",
            isChecked: false,
        },
        {
            value: "Straight Mast Forklift",
            isChecked: false,
        },
        {
            value: "Telehandler",
            isChecked: false,
        },
        {
            value: "Cherry Picker",
            isChecked: false,
        }
    ];

    let skillsDefault = [
        {
            value: "Carpentry",
            isChecked: false,
        },
        {
            value: "Plumbing",
            isChecked: false,
        },
        {
            value: "Electrical",
            isChecked: false,
        },
        {
            value: "Metalwork",
            isChecked: false,
        },
        {
            value: "Site Management",
            isChecked: false,
        },
        {
            value: "Safety Officer",
            isChecked: false,
        },
        {
            value: "Fencing (Heras, Met, Ped)",
            isChecked: false,
        },
        {
            value: "Pit Barrier (Mojo)",
            isChecked: false,
        },
        {
            value: "Staging",
            isChecked: false,
        },
        {
            value: "Scaffolding",
            isChecked: false,
        },
        {
            value: "Rigging",
            isChecked: false,
        },
        {
            value: "Lighting, Audio, Video & Other Tech",
            isChecked: false,
        }
    ];

    let hasCarDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        }
    ];

    let lastMinuteDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        }
    ];

    let campingDefault = [
        {
            value: "Yes",
            isChecked: false,
        },
        {
            value: "No",
            isChecked: false,
        },
        {
            value: "Maybe",
            isChecked: false
        }
    ];

    let prefersDefaults= [
        {
            value: "Single Day Jobs",
            isChecked: false,
        },
        {
            value: "Short Jobs (1-3 Days)",
            isChecked: false,
        },
        {
            value: "Long Jobs (4-10 Days)",
            isChecked: false,
        },
        {
            value: "Any length",
            isChecked: false,
        }
    ]

    // Refs
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const locationRef = useRef();
    const ageRef = useRef();
    const licencesOtherRef = useRef();
    const skillsOtherRef = useRef();
    const spinnerRef = useRef();
    const spinnerBulkRef = useRef();
    const bulkAddInputRef = useRef();

    // State
    const [licences, setLicences] = useState(licencesDefault);
    const [skills, setSkills] = useState(skillsDefault);
    const [hasCar, setHasCar] = useState(hasCarDefault);
    const [lastMinute, setLastMinute] = useState(lastMinuteDefault);
    const [camping, setCamping] = useState(campingDefault);
    const [prefers, setPrefers] = useState(prefersDefaults);
    const [bulkAddIsOpen, setBulkAddIsOpen] = useState(false);
    const [bulkAddData, setBulkAddData] = useState([]);
    const [bulkAddResults, setBulkAddResults] = useState([]);
    const [bulkAddResultsIsOpen, setBulkAddResultsIsOpen] = useState(false);

    // Upload button clicked
    const uploadButtonClicked = () => {

        document.getElementById('input_file').click()

    }

    // Download button clicked
    const downloadButtonClicked = async () => {

        let response = await api.downloadFile('/templates/crew-bulk-upload-template.csv', true);

        if (!response) {
            notification.error('Failed to download template.');
        }

    }

    // Licences checkbox callback
    const licencesCheckboxCallback = (index) => {

        // Update licences
        licences[index].isChecked = !licences[index].isChecked;
        setLicences([...licences]);

    }

    // Skills checkbox callback
    const skillsCheckboxCallback = (index) => {

        skills[index].isChecked = !skills[index].isChecked;
        setSkills([...skills]);

    }

    // Has car radio callback
    const hasCarRadioCallback = (index) => {

        hasCar.forEach((item, i) => {

            hasCar[i].isChecked = false;
            if (i === index) {
                hasCar[i].isChecked = true;
            }
            setHasCar([...hasCar]);

        });

    }

    // Last minute radio callback
    const lastMinuteRadioCallback = (index) => {

        lastMinute.forEach((item, i) => {

            lastMinute[i].isChecked = false;
            if (i === index) {
                lastMinute[i].isChecked = true;
            }
            setLastMinute([...lastMinute]);

        });

    }

    // Camping radio callback
    const campingRadioCallback = (index) => {

        camping.forEach((item, i) => {

            camping[i].isChecked = false;
            if (i === index) {
                camping[i].isChecked = true;
            }
            setCamping([...camping]);

        });

    }

    // Prefers radio callback
    const prefersRadioCallback = (index) => {

        prefers.forEach((item, i) => {

            prefers[i].isChecked = false;
            if (i === index) {
                prefers[i].isChecked = true;
            }
            setPrefers([...prefers]);

        });

    }

    // Add crew callback
    const addCrewCallback = async () => {

        // Get values
        let nameValue = nameRef.current.value;
        let emailValue = emailRef.current.value;
        let phoneValue = phoneRef.current.value;
        let locationValue = locationRef.current.value;
        let ageValue = ageRef.current.value;
        let licencesValue = [];
        let licencesOtherValue = licencesOtherRef.current.value;
        let skillsValue = [];
        let skillsOtherValue = skillsOtherRef.current.value;
        let hasCarValue = "";
        let lastMinuteValue = "";
        let campingValue = "";
        let prefersValue = "";

        licences.forEach((item) => {
            if (item.isChecked) {
                licencesValue.push(item.value);
            }
        });

        skills.forEach((item) => {
            if (item.isChecked) {
                skillsValue.push(item.value);
            }
        });

        hasCar.forEach((item) => {
            if (item.isChecked) {
                hasCarValue = item.value;
            }
        });

        lastMinute.forEach((item) => {
            if (item.isChecked) {
                lastMinuteValue = item.value;
            }
        });

        camping.forEach((item) => {
            if (item.isChecked) {
                campingValue = item.value;
            }
        });

        prefers.forEach((item) => {
            if (item.isChecked) {
                prefersValue = item.value;
            }
        });

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(nameValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid full name.");
        }

        if (!validate.email(emailValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid email address.");
        }

        if (!validate.phone(phoneValue)) {
            readyToSubmit = false;
            notification.error("Please enter a valid phone number.");
        }

        if (!locationValue) {
            readyToSubmit = false;
            notification.error("Please enter a location.");
        }

        // Submit
        let crew = {
            name: nameValue,
            email: emailValue,
            phone: phoneValue,
            location: locationValue,
            age: ageValue,
            licences: licencesValue,
            licencesOther: licencesOtherValue,
            skills: skillsValue,
            skillsOther: skillsOtherValue,
            hasCar: hasCarValue,
            lastMinute: lastMinuteValue,
            camping: campingValue,
            prefers: prefersValue
        };

        if (readyToSubmit) {

            spinnerRef.current.show();

            let response = await api.post('/crew/add', {
                crew: crew
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Crew member added.");
                    spinnerRef.current.hide();
                    reset();

                }

            } else {

                notification.error("Error adding crew member.");
                spinnerRef.current.hide();

            }

        }

    };

    // Reset
    const reset = () => {

        // Reset state
        setLicences(licencesDefault);
        setSkills(skillsDefault);
        setHasCar(hasCarDefault);
        setLastMinute(lastMinuteDefault);
        setCamping(campingDefault);
        setPrefers(prefersDefaults);

        // Scroll to top of page
        window.scrollTo(0, 0);

        setTimeout(() => {
            // Reset values
            nameRef.current.value = "";
            emailRef.current.value = "";
            phoneRef.current.value = "";
            locationRef.current.value = "";
            ageRef.current.value = "";
            licencesOtherRef.current.value = "";
            skillsOtherRef.current.value = "";
        }, 25);

    };

    // Bulk file selected
    const bulkFileSelected = () => {

        let file = bulkAddInputRef.current.files[0];
        crew.parseBulkAddCSV(file, bulkFileParsedCallback);

    }

    // Bulk file parsed callback
    const bulkFileParsedCallback = (data) => {

        setBulkAddData(data);
        setBulkAddIsOpen(true);
        document.body.classList.add('stop-scrolling');

    };

    // Bulk add crew submit
    const bulkAddCrewSubmit = async () => {

        // Results
        let failedItems = [];
        let successItems = [];

        for (let i = 0; i < bulkAddData.length; i++) {

            let item = bulkAddData[i];
            let addedToFailedItems = false;

            // Validate
            let readyToSubmit = true;

            if (!validate.fullName(item.name)) {
                readyToSubmit = false;
                notification.error(`Please enter a valid full name for ${item.name}.`);
                failedItems.push(item);
                addedToFailedItems = true;
            }

            if (!validate.email(item.email)) {
                readyToSubmit = false;
                notification.error(`Please enter a valid email address for ${item.name}.`);
                failedItems.push(item);
                addedToFailedItems = true;
            }

            if (!validate.phone(item.phone)) {
                readyToSubmit = false;
                notification.error(`Please enter a valid phone number for ${item.name}.`);
                failedItems.push(item);
                addedToFailedItems = true;
            }

            if (!item.location) {
                readyToSubmit = false;
                notification.error(`Please enter a location for ${item.name}.`);
                failedItems.push(item);
                addedToFailedItems = true;
            }

            if (readyToSubmit) {

                spinnerBulkRef.current.show();

                let response = await api.post('/crew/add', {
                    crew: item
                }, true);

                if (response.status === 200) {

                    if (response.error.code === "API_INTERNAL_24") {

                        notification.error(response.error.detail);
                        failedItems.push(item);
                        addedToFailedItems = true;
                        spinnerRef.current.hide();

                    } else {

                        notification.success(`${item.name} added.`);
                        successItems.push(item);
                        spinnerRef.current.hide();

                    }

                } else {

                    notification.error(`Error adding ${item.name}.`);
                    failedItems.push(item);
                    addedToFailedItems = true;
                    spinnerRef.current.hide();


                }

            }

        }

        bulkAddCrewClose();

        setBulkAddResults({
            failed: failedItems,
            success: successItems
        });
        setBulkAddResultsIsOpen(true);
        document.body.classList.add('stop-scrolling');

    };

    // Bulk add screw close
    const bulkAddCrewClose = () => {

        setBulkAddData([]);
        setBulkAddIsOpen(false);
        document.body.classList.remove('stop-scrolling');
        bulkAddInputRef.current.value = "";
        spinnerRef.current.hide();
        spinnerBulkRef.current.hide();

    };

    // Bulk add results close
    const bulkAddResultsClose = () => {

        document.body.classList.remove('stop-scrolling');
        setBulkAddResultsIsOpen(false);
        setBulkAddResults([]);
        spinnerRef.current.hide();
        spinnerBulkRef.current.hide();

    }

    // Return component
    return (

        <div className="view">

            <Meta
                title="Crew - Add"
            />

            <h1>Add Crew</h1>

            <h2>Bulk Add</h2>

            <p>Bulk adding crew can be done through uploading a .csv file.</p>

            <div className="view-row">

                <input type="file" name="bulkAddInput" id='input_file' hidden ref={bulkAddInputRef} accept=".csv" onChange={ () => { bulkFileSelected(); } } />
                <PrimaryButton title="Upload" callback={uploadButtonClicked} />

                <PrimaryButton title="Download Template" callback={downloadButtonClicked} />

            </div>

            <h2>Single Add</h2>

            <Form submitTitle='Add Crew' submitCallback={addCrewCallback}>

                <TextInput title="Full Name" type="text" ref={nameRef} />

                <TextInput title="Email" type="email" ref={emailRef} />

                <TextInput title="Phone Number" type="tel" ref={phoneRef} />

                <TextInput title="Location" type="text" ref={locationRef} />

                <TextInput title="Age" type="number" ref={ageRef} />

                <CheckboxInput title="Licences" values={licences} callback={licencesCheckboxCallback} ref={licencesOtherRef} />

                <CheckboxInput title="Skills" values={skills} callback={skillsCheckboxCallback} ref={skillsOtherRef} />

                <RadioInput title="Has use of a car" values={hasCar} callback={hasCarRadioCallback} />

                <RadioInput title="Last minute availability" values={lastMinute} callback={lastMinuteRadioCallback} />

                <RadioInput title="Happy Camping" values={camping} callback={campingRadioCallback} />

                <RadioInput title="Prefers" values={prefers} callback={prefersRadioCallback} />

            </Form>

            <Spinner small theme="white" ref={spinnerRef} />

            <div className={bulkAddIsOpen ? "crew-bulk-add-popup" : "crew-bulk-add-popup closed"}>

                <div className="crew-bulk-add-popup-close" onClick={() => { bulkAddCrewClose(); }}>X</div>

                <div className="crew-bulk-add-popup-inner">

                    <h2>Bulk Add Crew</h2>

                    <div className="crew-bulk-add-popup-inner-table">

                        <div className="crew-bulk-add-popup-inner-table-header">

                            <div className="crew-bulk-add-popup-inner-table-item cbap-name"><span>Name</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-email"><span>Email</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-phone"><span>Phone</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-location"><span>Location</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-age"><span>Age</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-licences"><span>Licences</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-skills"><span>Skills</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-hascar"><span>Has Car</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-lastminute"><span>Last Minute</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-camping"><span>Camping</span></div>

                            <div className="crew-bulk-add-popup-inner-table-item cbap-prefers"><span>Prefers</span></div>

                        </div>

                        {bulkAddData.map((item, index) => {

                            return (

                                <div className="crew-bulk-add-popup-inner-table-row" key={index}>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-name"><span>{item.name}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-email"><span>{item.email}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-phone"><span>{item.phone}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-location"><span>{item.location}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-age"><span>{item.age}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-licences">
                                        {(item.licences.length > 0) ?
                                            item.licences.map((licence, index) => (
                                                <span key={index}>{`-${licence}`}</span>
                                            ))
                                            :
                                            <span>No Licences</span>
                                        }
                                    </div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-skills">
                                        {(item.skills.length > 0) ?
                                            item.skills.map((skill, index) => (
                                                <span key={index}>{`-${skill}`}</span>
                                            ))
                                            :
                                            <span>No Skills</span>
                                        }
                                    </div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-hascar"><span>{item.hasCar}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-lastminute"><span>{item.lastMinute}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-camping"><span>{item.camping}</span></div>

                                    <div className="crew-bulk-add-popup-inner-table-item cbap-prefers"><span>{item.prefers.replace(/ *\([^)]*\) */g, "").replace('Jobs', '')}</span></div>

                                </div>

                            )

                        })}

                    </div>

                    <p>Are you sure you want to add all crew members?</p>

                    <PrimaryButton title="Add Crew" callback={bulkAddCrewSubmit} />

                    <Spinner small theme="white" ref={spinnerBulkRef} />

                </div>

            </div>

            {bulkAddResultsIsOpen ?
                <div className="crew-bulk-add-popup">

                    <div className="crew-bulk-add-popup-close" onClick={() => { bulkAddResultsClose(); }}>X</div>

                    <div className="crew-bulk-add-results">

                        <h2>Bulk Add Results</h2>

                        <div className="crew-bulk-add-results-content">

                            <div className="crew-bulk-add-results-section">

                                <h3>Added</h3>

                                <ul>
                                    {bulkAddResults.success.map((item, index) => (
                                        <li key={index}>{item.name}</li>
                                    ))}
                                </ul>

                            </div>

                            <div className="crew-bulk-add-results-section">

                                <h3>Failed</h3>

                                <ul>
                                    {bulkAddResults.failed.map((item, index) => (
                                        <li key={index}>{item.name}</li>
                                    ))}
                                </ul>

                            </div>

                        </div>

                    </div>

                </div>
                :
                ""
            }

        </div>

    )

};

// Export Component
export default CrewAddView;
