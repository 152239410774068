// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './projectpopupheader.scss';

// ProjectPopupHeader component
const ProjectPopupHeader = (props) => {

    // Return component
    return (

        <div className="project-popup-header">

            <div className="project-popup-header-inner">{props.title}</div>

        </div>

    )

};

ProjectPopupHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

// Export Component
export default ProjectPopupHeader;
