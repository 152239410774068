// Imports
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './navigationcards.scss';

// NavigationCards component
const NavigationCards = (props) => {

	// Return component
	return (

		<div className="navigationcards">

            {props.cards.map((card, index) => (

                <Link to={card.link} key={index}>

                    <div className="navigationcards-card">

                        <img src={card.icon} alt={card.title} />

                        <span>{card.title}</span>

                    </div>

                </Link>

            ))}

		</div>

    )

};

NavigationCards.propTypes = {
    cards: PropTypes.array.isRequired
}

// Export Component
export default NavigationCards;
