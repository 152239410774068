// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './sidenavigation.scss';

// SideNavigation component
const SideNavigation = (props) => {

    // State
    const [activeItem, setActiveItem] = useState(0);

    // Nav button clicked
    const navButtonClicked = (item, index) => {

        // Update active item
        setActiveItem(index);

        // Call callback
        item.callback(item.title);

    }

    // Return component
    return (

        <div className="sidenavigation">

            <h1>{props.title.toUpperCase()}</h1>

            <div className="sidenavigation-line"></div>

            {props.nav.map((item, index) => (

                <div className={(activeItem === index) ? "sidenavigation-item active" : "sidenavigation-item"} onClick={ () => { navButtonClicked(item, index) }} key={index}>{item.title}</div>

            ))}

        </div>

    )

};

SideNavigation.propTypes = {
    nav: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

// Export Component
export default SideNavigation;
