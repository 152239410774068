// Imports
import React, { useState, useRef, useEffect } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);
import './crewoverview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';

// Assets
import ReloadIcon from '../../../../assets/icons/reload-icon-primary.png'

// CrewOverview component
const CrewOverview = () => {

    // Refs
    const spinnerRef = useRef(null);

    // State
    const [overview, setOverview] = useState([]);

    // Get overview
    useEffect(() => {

        getOverview();

    }, []);

    // Get overview
    const getOverview = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/crew/overview', true, force);

        if (response.status === 200) {

            let overviewTemp = [];
            response.data.overview.forEach((item) => {
                overviewTemp.push({
                    title: item.title,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                });
            });
            setOverview(overviewTemp);
            notification.success("Overview loaded.");
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Return component
    return (

        <div className="view">

            <Meta
                title="Crew - Overview"
            />

            <div className="overview-header">

                <h1>Overview</h1>

                <div className="reload-button">
                    <img src={ReloadIcon} alt="Reload" onClick={() => { getOverview(true); }} />
                </div>

            </div>

            {overview.length > 0 && 
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={overview}
                    style={{ height: "750px", width: "100%", marginTop: "20px" }}
                />
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default CrewOverview;
