// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './projecteditdocumentpopup.scss';

// Components
import Form from '../../../Form';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';

// Utils
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import api from '../../../../utils/api';

// ProjectEditDocumentPopup component
const ProjectEditDocumentPopup = (props) => {

    const categoryOptions = [
        {
            value: 'select',
            title: "Select a category"
        },
        {
            value: 'emp',
            title: "EMP"
        },
        {
            value: 'site-plan',
            title: "Site Plan"
        },
        {
            value: 'risk-assessment',
            title: "Risk Assessment"
        },
        {
            value: 'general-document',
            title: "General Document"
        },
        {
            value: 'image',
            title: "Image"
        },
        {
            value: 'video',
            title: "Video"
        },
        {
            value: 'misc',
            title: "Misc"
        }
    ];

    // Refs
    const fileNameRef = useRef(null);
    const versionRef = useRef(null);
    const categoryRef = useRef(null);
    const projectPortalVisibleRef = useRef(null);
    const crewVisibleRef = useRef(null);

    // Submit button clicked
    const submitButtonClicked = async () => {

        // Get values
        const fileName = fileNameRef.current.value;
        const version = versionRef.current.value;
        const category = categoryRef.current.value;
        const projectPortalVisible = projectPortalVisibleRef.current.value;
        const crewVisible = crewVisibleRef.current.value;

        // Validate
        let readyToSubmit = true;

        if (fileName.length < 1) {
            notification.error("Please enter a file name");
            readyToSubmit = false;
        }
        if (category === 'select') {
            notification.error("Please select a category");
            readyToSubmit = false;
        }
        if (projectPortalVisible === 'select') {
            notification.error("Please select an option for 'Visible On Project Portal'");
            readyToSubmit = false;
        }
        if (crewVisible === 'select') {
            notification.error("Please select an option for 'Visible To Crew'");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            props.spinnerRef.current.show();

            let response = await api.post(`/project/files/update`, {
                file: {
                    _id: props.file._id,
                    fileName: fileName,
                    version: version,
                    category: category,
                    projectPortalVisible: projectPortalVisible,
                    crewVisible: crewVisible,
                    project: props.file.project
                }
            }, true);

            if (response.status === 200) {

                notification.success('Document updated.');
                props.spinnerRef.current.hide();
                props.successCallback(response.data.project);

            } else {

                notification.error("Something went wrong, please try again soon.");
                props.spinnerRef.current.hide();

            }

        }

    };

    // Delete clicked
    const deleteClicked = async () => {

        props.spinnerRef.current.show();

            let response = await api.post(`/project/files/delete`, {
                file: {
                    _id: props.file._id,
                    fileId: props.file.fileId,
                    project: props.file.project
                }
            }, true);

            if (response.status === 200) {

                notification.success('Document deleted.');
                props.spinnerRef.current.hide();
                props.successCallback(response.data.project);

            } else {

                notification.error("Something went wrong, please try again soon.");
                props.spinnerRef.current.hide();

            }

    }

    // Return component
    return (

        <div className="ped-popup">

            <h2>Edit</h2>

            <Form submitTitle="Update" submitCallback={submitButtonClicked}>

                <TextInput title="File Name" type="text" ref={fileNameRef} initialValue={props.file.fileName} />

                <TextInput title="Version" type="text" ref={versionRef} initialValue={props.file.version} />

                <SelectInput title="Category" options={categoryOptions} ref={categoryRef} initialValue={props.file.category} />

                <SelectInput title="Visible On Project Portal" options={[
                    {
                        value: 'select',
                        title: "Select an option"
                    },
                    {
                        value: 'yes',
                        title: "Yes"
                    },
                    {
                        value: 'no',
                        title: "No"
                    }
                ]} ref={projectPortalVisibleRef} initialValue={props.file.projectPortalVisible} />

                <SelectInput title="Visible To Crew" options={[
                    {
                        value: 'select',
                        title: "Select an option"
                    },
                    {
                        value: 'yes',
                        title: "Yes"
                    },
                    {
                        value: 'no',
                        title: "No"
                    }
                ]} ref={crewVisibleRef} initialValue={props.file.crewVisible} />


            </Form>

            <div className="ped-delete" onClick={() => { deleteClicked(); }}>Delete File</div>

        </div>

    )

};

ProjectEditDocumentPopup.propTypes = {
    file: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired
};

// Export Component
export default ProjectEditDocumentPopup;
