// Imports
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './projectinvitecrewpopup.scss';

// Components
import TextInput from '../../../inputs/TextInput';
import SubmitButton from '../../../buttons/SubmitButton';
import DateInput from '../../../inputs/DateInput';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';
import format from '../../../../utils/format';

// Assets
import ReloadIcon from '../../../../assets/icons/reload-icon-primary.png';

// ProjectInviteCrewPopup component
const ProjectInviteCrewPopup = (props) => {

    // Refs
    const payInputRef = useRef(null);
    const venueInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const licenceFiltersDefault = [
        {
            title: "Car",
            selected: false
        },
        {
            title: "Class 1 HGV",
            selected: false
        },
        {
            title: "Class 2 HGV",
            selected: false
        },
        {
            title: "Straight Mast Forklift",
            selected: false
        },
        {
            title: "Telehandler",
            selected: false
        },
        {
            title: "Cherry Picker",
            selected: false
        }
    ];
    const skillFiltersDefault = [
        {
            title: "Carpentry",
            selected: false
        },
        {
            title: "Plumbing",
            selected: false
        },
        {
            title: "Electrical",
            selected: false
        },
        {
            title: "Metalwork",
            selected: false
        },
        {
            title: "Site Management",
            selected: false
        },
        {
            title: "Safety Officer",
            selected: false
        },
        {
            title: "Fencing (Heras, Met, Ped)",
            selected: false
        },
        {
            title: "Pit Barrier (Mojo)",
            selected: false
        },
        {
            title: "Staging",
            selected: false
        },
        {
            title: "Scaffolding",
            selected: false
        },
        {
            title: "Rigging",
            selected: false
        },
        {
            title: "Lighting, Audio, Video & Other Tech",
            selected: false
        }
    ];

    // State
    const [crew, setCrew] = useState([]);
    const [crewStatic, setCrewStatic] = useState([]);
    const [crewSelected, setCrewSelected] = useState([]);
    const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
    const [inviteType, setInviteType] = useState('crew');
    const [licenceFilters, setLicenceFilters] = useState(licenceFiltersDefault);
    const [skillFilters, setSkillFilters] = useState(skillFiltersDefault);
    const [step1Visible, setStep1Visible] = useState(true);
    const [step2Visible, setStep2Visible] = useState(false);
    const [step3Visible, setStep3Visible] = useState(false);
    const [startDate, setStartDate] = useState(new Date(props.project.dates.start.raw));
    const [endDate, setEndDate] = useState(new Date(props.project.dates.end.raw));
    const [details, setDetails] = useState({});

    useEffect(() => {
        getCrew();
    }, []);

    // Get crew
    const getCrew = async (force = false) => {

        props.spinnerRef.current.show();

        let crewTemp = [];
        props.crew.forEach((crewMember, index) => {
            let member = crewMember;
            member.selected = false;
            crewTemp.push(member);
        });

        console.log(props.crew);
        setCrewStatic([...crewTemp]);
        notification.success('Crew loaded.');
        props.spinnerRef.current.hide();
        switch (inviteType) {
            case 'crew':
                showCrewPicker(crewTemp);
                break;
            case 'licence':
                showLicencePicker(crewTemp);
                break;
            case 'skill':
                showSkillPicker(crewTemp);
                break;
            case 'favourite':
                showFavouritesPicker(crewTemp);
                break;
        }

    }

    // Check selected
    const checkSelected = (crewMember) => {

        let tempCrew = [];
        crewStatic.forEach((member) => {
            if (member._id === crewMember._id) {
                crewMember.selected = !crewMember.selected;
                tempCrew.push(crewMember);
            } else {
                tempCrew.push(member);
            }
        });
        setCrewStatic([...tempCrew]);


    }

    // Select all crew
    const selectAllCrew = () => {

        let tempCrew = [];
        crew.forEach((member) => {
            member.selected = !selectAllIsChecked;
            tempCrew.push(member);
        });
        setCrew([...tempCrew]);
        setSelectAllIsChecked(!selectAllIsChecked);

    }

    // Show search
    const showSearch = () => {
        setCrew([]);
        setInviteType('search');
    };

    // Show crew picker
    const showCrewPicker = (passed = null) => {

        let crewList = passed ? passed : crewStatic;

        setCrew([...crewList]);
        setInviteType('crew');

    }

    // Show licence picker
    const showLicencePicker = (passed = null) => {

        setCrew([]);
        setInviteType('licence');

    }

    // Show skill picker
    const showSkillPicker = (passed = null) => {

        setCrew([]);
        setInviteType('skill');

    }

    // Show favourites picker
    const showFavouritesPicker = (passed = null) => {

        let crewList = passed ? passed : crewStatic;

        let tempCrew = [];
        crewList.forEach((crewMember) => {
            if (crewMember.favourite) {
                tempCrew.push(crewMember);
            }
        });
        setCrew([...tempCrew]);

        setInviteType('favourite');

    }

     // Licence filter selected
     const licenceFilterSelected = (licence) => {

        let tempLicenceFilters = [];
        licenceFilters.forEach((filter) => {
            if (filter.title === licence.title) {
                filter.selected = !filter.selected;
            }
            tempLicenceFilters.push(filter);
        });
        setLicenceFilters([...tempLicenceFilters]);

        let selectedLicences = [];
        tempLicenceFilters.forEach((filter) => {
            if (filter.selected) {
                selectedLicences.push(filter.title);
            }
        });

        if (selectedLicences.length === 0) {
            setCrew([]);
        } else {
            let tempCrew = [];
            crewStatic.forEach((crewMember) => {
                selectedLicences.forEach((licence) => {
                    if (crewMember.licences.includes(licence)) {
                        if (!tempCrew.includes(crewMember)) {
                            tempCrew.push(crewMember);
                        }
                    }
                });
            });
            setCrew([...tempCrew]);
        }

     };
 
     // Skill filter selected
     const skillFilterSelected = (skill) => {
 
        let tempSkillFilters = [];
        skillFilters.forEach((filter) => {
            if (filter.title === skill.title) {
                filter.selected = !filter.selected;
            }
            tempSkillFilters.push(filter);
        });
        setSkillFilters([...tempSkillFilters]);

        let selectedLicences = [];
        tempSkillFilters.forEach((filter) => {
            if (filter.selected) {
                selectedLicences.push(filter.title);
            }
        });

        if (selectedLicences.length === 0) {
            setCrew([]);
        } else {
            let tempCrew = [];
            crewStatic.forEach((crewMember) => {
                selectedLicences.forEach((licence) => {
                    if (crewMember.skills.includes(licence)) {
                        if (!tempCrew.includes(crewMember)) {
                            tempCrew.push(crewMember);
                        }
                    }
                });
            });
            setCrew([...tempCrew]);
        }

     };

    // Searching
    const searchInputRef = useRef(null);
    const searchInputChanged = (value) => {
        

        let tempCrew = [];

        if (!value || value === "" || value.length < 1) {

            tempCrew = [];

        } else {

            crewStatic.forEach((crewMember) => {

                // Check name
                if (crewMember.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!tempCrew.some(x => x._id === crewMember._id)) {
                        tempCrew.push(crewMember);
                    }
                }

            });

        }

        setCrew([...tempCrew]);

    }

    // Next button clicked
    const nextButtonClicked = (step) => {

        if (step === 1) {

            // Get selected crew
            let selectedCrew = [];
            crewStatic.forEach((crewMember) => {
                if (crewMember.selected) {
                    selectedCrew.push(crewMember);
                }
            });

            if (selectedCrew.length === 0) {

                notification.error("Please select at least one crew member.");

            } else {

                setCrewSelected([...selectedCrew]);

                setStep1Visible(false);
                setStep2Visible(true);

            }

        }

        if (step === 2) {

            // Get values
            let pay = payInputRef.current.value;
            let venue = venueInputRef.current.value;
            let description = descriptionInputRef.current.value;

            // Validate
            let readyForNext = true;
            if (pay.length < 1) {
                notification.error("Please enter a day rate.");
                readyForNext = false;
            }
            if (venue.length < 1) {
                notification.error("Please enter a venue.");
                readyForNext = false;
            }
            if (description.length < 1) {
                notification.error("Please enter a description.");
                readyForNext = false;
            }
            if (!startDate) {
                notification.error("Please select a start date.");
                readyForNext = false;
            }
            if (!endDate) {
                notification.error("Please select an end date.");
                readyForNext = false;
            }

            if (readyForNext) {

                setDetails({
                    pay: pay,
                    venue: venue,
                    description: description,
                    startDate: startDate,
                    endDate: endDate
                });

                setStep2Visible(false);
                setStep3Visible(true);

            }

        }

    };

    // Back to details button clicked
    const backToDetailsClicked = () => {

        setStep3Visible(false);
        setStep2Visible(true);

        setTimeout(() => {

            payInputRef.current.value = details.pay;
            venueInputRef.current.value = details.venue;
            descriptionInputRef.current.value = details.description;
            setStartDate(details.startDate);
            setEndDate(details.endDate);

        }, 50);

    }

    // Invite button clicked
    const inviteButtonClicked = async () => {
        
        // Format dates
        let updatedDetails = details;
        updatedDetails.startDate = format.projectDate(details.startDate);
        updatedDetails.endDate = format.projectDate(details.endDate);

        props.spinnerRef.current.show();

        let response = await api.post('/project/crew/invite', {
            project: props.project,
            details: updatedDetails,
            crew: crewSelected
        }, true);

        if (response.status === 200) {

            if (response.error.code === "API_INTERNAL_24") {

                notification.error(response.error.detail);
                props.spinnerRef.current.hide();

            } else {

                notification.success(`Crew invited.`);
                props.spinnerRef.current.hide();
                props.successCallback(response.data.project);

            }

        } else {

            notification.error(`Error inviting crew.`);
            props.spinnerRef.current.hide();


        }

    };


    // Return component
    if (crewStatic.length === 0) {

        return (
            
            <div className="pics-popup">
                <span>Loading...</span>
            </div>

        )

    } else {

        if (step1Visible) {

            return (

                <div className="pics-popup">
    
                    <div className="pics-popup-header">
    
                        <h2>Invite Crew - Select</h2>
    
                    </div>
    
                    <div className="pics-popup-nav">
    
                        <div 
                            className={(inviteType === "crew") ? "pics-popup-nav-item pics-active" : "pics-popup-nav-item"}
                            onClick={() => { showCrewPicker(); }}
                        >All Crew</div>
    
                        <div 
                            className={(inviteType === "licence") ? "pics-popup-nav-item pics-active" : "pics-popup-nav-item"}
                            onClick={() => { showLicencePicker(); }}
                        >Licence Picker</div>
    
                        <div 
                            className={(inviteType === "skill") ? "pics-popup-nav-item pics-active" : "pics-popup-nav-item"}
                            onClick={() => { showSkillPicker(); }}
                        >Skill Picker</div>
    
                        <div 
                            className={(inviteType === "favourite") ? "pics-popup-nav-item pics-active" : "pics-popup-nav-item"}
                            onClick={() => { showFavouritesPicker(); }}
                        >Favourites Picker</div>
    
                        <div 
                            className={(inviteType === "search") ? "pics-popup-nav-item pics-active" : "pics-popup-nav-item"}
                            onClick={() => { showSearch(); }}
                        >Search</div>
    
                    </div>
    
                    <div className="pics-popup-content">
    
                        {inviteType === "licence" &&
                          
                            <div className="pics-crew-filters">
    
                                {licenceFilters.map((filter, index) => (
    
                                    <div className="pics-crew-filters-item" key={index} onClick={() => { licenceFilterSelected(filter) }}>
                                        <div className={filter.selected ? "pics-crew-item-check check-selected" : "pics-crew-item-check"}>{filter.selected && "✓"}</div>
                                        <span>{filter.title}</span>
                                    </div>
    
                                ))}
    
                            </div>
    
                        }
    
                        {inviteType === "skill" &&
                            
                            <div className="pics-crew-filters">
    
                                {skillFilters.map((filter, index) => (
    
                                    <div className="pics-crew-filters-item" key={index} onClick={() => { skillFilterSelected(filter) }}>
                                        <div className={filter.selected ? "pics-crew-item-check check-selected" : "pics-crew-item-check"}>{filter.selected && "✓"}</div>
                                        <span>{filter.title}</span>
                                    </div>
    
                                ))}
    
                            </div>
    
                        }
    
                        {inviteType === "search" &&
                            <TextInput title="Search" type="text" ref={searchInputRef} onChangeCallback={searchInputChanged} />
                        }
    
                        <div className="pics-crew-item" onClick={() => { selectAllCrew(); }}>
                            <div className={selectAllIsChecked ? "pics-crew-item-check check-selected" : "pics-crew-item-check"}>{selectAllIsChecked && "✓"}</div>
                            <span>Select All</span>
                        </div>
    
                        <div className="pics-crew">
    
                            {crew.map((crewMember, index) => (
                                <div className="pics-crew-item" onClick={() => { checkSelected(crewMember); }} key={index}>
                                    <div className={crewMember.selected ? "pics-crew-item-check check-selected" : "pics-crew-item-check"}>{crewMember.selected && "✓"}</div>
                                    <span>{crewMember.name}</span>
                                </div>
                            ))}
    
                        </div>
    
                        <SubmitButton title="Next" callback={() => { nextButtonClicked(1); }} />
    
                    </div>
            
                </div>
    
            )

        }

        if (step2Visible) {

            return (

                <div className="pics-popup">
    
                    <div className="pics-popup-header">
    
                        <h2>Invite Crew - Details</h2>

                        <span onClick={() => { setStep1Visible(true); setStep2Visible(false); }}>Back</span>
    
                    </div>

                    <div className="pics-popup-content">

                        <TextInput title="Day Rate" placeholder="175" type="number" ref={payInputRef} />

                        <TextInput title="Venue" type="text" initialValue={props.project.venue} ref={venueInputRef} />

                        <TextInput title="Description" type="text" longText ref={descriptionInputRef} />
                        
                        <div className="form-dates">

                            <DateInput title="Start Date" value={startDate} setter={setStartDate} />

                            <DateInput title="End Date" value={endDate} setter={setEndDate} />

                        </div>

                    </div>    

                    <SubmitButton title="Next" callback={() => { nextButtonClicked(2); }} />    
            
                </div>
    
            )

        }

        if (step3Visible) {

            return (

                <div className="pics-popup">
    
                    <div className="pics-popup-header">
    
                        <h2>Invite Crew - Confirm</h2>

                        <span onClick={() => { backToDetailsClicked(); }}>Back</span>
    
                    </div>

                    <div className="pics-popup-content">

                        <span>Day Rate: £{details.pay}</span>
                        <span>Venue: {details.venue}</span>
                        <span>Description:</span>
                        <p>{details.description}</p>
                        <span>Start Date: {format.projectDate(startDate)}</span>
                        <span>End Date: {format.projectDate(endDate)}</span>
                        <span>Crew:</span>
                        <ul>
                            {crewSelected.map((crewMember, index) => (
                                <li key={index}>{crewMember.name}</li>
                            ))}
                        </ul>

                    </div>    

                    <SubmitButton title="Invite" callback={() => { inviteButtonClicked(); }} />    
            
                </div>
    
            )

        }

        

    }

};

ProjectInviteCrewPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired,
    crew: PropTypes.array.isRequired
};

// Export Component
export default ProjectInviteCrewPopup;
