// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './radioinput.scss';

// RadioInput component
const RadioInput = (props) => {

    // Update radio
    const updateRadio = (elementId) => {

        document.getElementById(elementId).click();

    };

    // Return component
    return (

        <div className="radioinput">

            <span>{props.title}</span>

            {props.values.map((value, index) => (

                <div className="radioinput-item" key={index}>

                    <input type="radio" id={`${props.title.replace(/\s/g,'').toLowerCase()}_${index}`} name={props.title.replace(/\s/g,'').toLowerCase()} value={value.value} checked={value.isChecked} onChange={() => {
                        props.callback(index);
                    }} />
                    <label onClick={() => { updateRadio(`${props.title.replace(/\s/g,'').toLowerCase()}_${index}`) }}>{value.value}</label>

                </div>

            ))}

        </div>

    )

};

RadioInput.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
};

// Export Component
export default RadioInput;
