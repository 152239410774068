// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './spinnerfull.scss';

// Assets
import SpinnerWhite from '../../../assets/spinners/spinner-white.svg';
import Spinner from '../Spinner/Spinner';

// SpinnerFull component
const SpinnerFull = (props) => {

    // Return component
    return (

        <div className="spinnerfull">

            <div className="spinnerfull-inner">

                <h2>{props.title}...</h2>

                <img src={SpinnerWhite} alt="Connecting..." />

            </div>

        </div>

    )

};

SpinnerFull.propTypes = {
    title: PropTypes.string.isRequired
};

// Export Component
export default SpinnerFull;
