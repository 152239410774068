// Imports
import React, { useState, useRef } from 'react';
import './settingsdetailsview.scss';

// Components
import Meta from '../../../Meta';
import TextInput from '../../../inputs/TextInput';
import SubmitButton from '../../../buttons/SubmitButton';
import Spinner from '../../../spinners/Spinner';

// Utils
import validate from '../../../../utils/validate';
import notification from '../../../../utils/notification';
import session from '../../../../utils/session';
import api from '../../../../utils/api';

// Assets
import TickGreen from '../../../../assets/icons/tick-green.png';
import CrossRed from '../../../../assets/icons/cross-red.png';

// SettingsDetailsView component
const SettingsDetailsView = () => {

    // Refs
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const spinnerRef = useRef();

    // State
    const [password8Chars, setPassword8Chars] = useState(false);
    const [passwordLowercase, setPasswordLowercase] = useState(false);
    const [passwordUppercase, setPasswordUppercase] = useState(false);
    const [passwordNumber, setPasswordNumber] = useState(false);
    const [passwordSpecial, setPasswordSpecial] = useState(false);

    // Password changed
    const passwordChanged = () => {

        // Validate
        let result = validate.password(passwordRef.current.value);

        // Update state
        setPassword8Chars(result.chars8);
        setPasswordLowercase(result.lowercase);
        setPasswordUppercase(result.uppercase);
        setPasswordNumber(result.number);
        setPasswordSpecial(result.special);

    };

    // Update password
    const updatePassword = async () => {

        let readyToSubmit = true;

        // Validate password
        let result = validate.password(passwordRef.current.value);
        if (!result.chars8 || !result.lowercase || !result.uppercase || !result.number || !result.special) {
            readyToSubmit = false;
            if (!result.chars8) {
                notification.error('Password must be 8 characters or more');
            }
            if (!result.lowercase) {
                notification.error('Password must contain at least one lowercase character');
            }
            if (!result.uppercase) {
                notification.error('Password must contain at least one uppercase character');
            }
            if (!result.number) {
                notification.error('Password must contain at least one number');
            }
            if (!result.special) {
                notification.error('Password must contain at least one special character');
            }
        }

        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            readyToSubmit = false;
            notification.error('Passwords do not match');
        }

        if (readyToSubmit) {

            spinnerRef.current.show();

            let sessionData = session.get();
            let email = sessionData.user.email;

            let response = await api.post('/user/update', {
                user: {
                    email: email,
                    password: passwordRef.current.value
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("Password updated.");
                    spinnerRef.current.hide();
                    passwordRef.current.value = "";
                    confirmPasswordRef.current.value = "";

                }

            } else {

                notification.error("Error updating password.");
                spinnerRef.current.hide();

            }

        }


    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Settings - Account Details"
            />

            <h1>Account Details</h1>

            <h2>Change Password</h2>

            <div className="settings-password">

                <div className="settings-password-inputs">

                    <TextInput type="password" title="Password" ref={passwordRef} onChangeCallback={passwordChanged} />

                    <TextInput type="password" title="Confirm Password" ref={confirmPasswordRef} />

                    <SubmitButton title="Change Password" callback={updatePassword} fullWidth noMargin />

                </div>

                <div className="settings-password-strength">

                    <h3>Password Strength</h3>

                    <div className="settings-password-strength-row">

                        {password8Chars ? <img src={TickGreen} alt="Tick" /> : <img src={CrossRed} alt="Cross" />}

                        <span>8 Characters or more</span>

                    </div>

                    <div className="settings-password-strength-row">

                        {passwordLowercase ? <img src={TickGreen} alt="Tick" /> : <img src={CrossRed} alt="Cross" />}

                        <span>Contains lowercase characters</span>

                    </div>

                    <div className="settings-password-strength-row">

                        {passwordUppercase ? <img src={TickGreen} alt="Tick" /> : <img src={CrossRed} alt="Cross" />}

                        <span>Contains uppercase characters</span>

                    </div>

                    <div className="settings-password-strength-row">

                        {passwordNumber ? <img src={TickGreen} alt="Tick" /> : <img src={CrossRed} alt="Cross" />}

                        <span>Contains at least one number</span>

                    </div>

                    <div className="settings-password-strength-row">

                        {passwordSpecial ? <img src={TickGreen} alt="Tick" /> : <img src={CrossRed} alt="Cross" />}

                        <span>Contains at least on special character</span>

                    </div>

                </div>

            </div>

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default SettingsDetailsView;
