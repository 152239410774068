// Imports
import React, { useState } from 'react';
import './projectspage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import ProjectsViewView from '../../views/projects/ProjectsViewView/';
import ProjectsAddView from '../../views/projects/ProjectsAddView/';
import ProjectsOverview from '../../views/projects/ProjectsOverview';

// ProjectsPage component
const ProjectsPage = () => {

    // State
    const [activeView, setActiveView] = useState("Overview");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    const nav = [
        {
            title: "Overview",
            callback: navButtonClicked,
        },
        {
            title: "View",
            callback: navButtonClicked,
        },
        {
            title: "Add",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="projectspage">

            <Meta
                title="Projects"
            />

            <SideNavigation nav={nav} title="PROJECTS" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "View" ? <ProjectsViewView /> : null}

            {activeView === "Add" ? <ProjectsAddView /> : null}

            {activeView === "Overview" ? <ProjectsOverview /> : null}

        </div>

    )

};

// Export Component
export default ProjectsPage;
