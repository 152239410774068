// Imports
import React, { useState } from 'react';
import './crewpage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import CrewViewView from '../../views/crew/CrewViewView';
import CrewAddView from '../../views/crew/CrewAddView';
import CrewOverview from '../../views/crew/CrewOverview';

// CrewPage component
const CrewPage = () => {

    // State
    const [activeView, setActiveView] = useState("Overview");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    const nav = [
        {
            title: "Overview",
            callback: navButtonClicked,
        },
        {
            title: "View",
            callback: navButtonClicked,
        },
        {
            title: "Add",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="crewpage">

            <Meta
                title="Crew"
            />

            <SideNavigation nav={nav} title="CREW" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "View" ? <CrewViewView /> : null}

            {activeView === "Add" ? <CrewAddView /> : null}

            {activeView === "Overview" ? <CrewOverview /> : null}

        </div>

    )

};

// Export Component
export default CrewPage;
