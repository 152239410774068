// Imports
import React, { useState, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import './spinner.scss';

// Spinners
import SpinnerPrimary from '../../../assets/spinners/spinner-primary.svg';
import SpinnerPrimaryDark from '../../../assets/spinners/spinner-primary-dark.svg';
import SpinnerPrimaryLight from '../../../assets/spinners/spinner-primary-light.svg';
import SpinnerGrey from '../../../assets/spinners/spinner-grey.svg';
import SpinnerGreyDark from '../../../assets/spinners/spinner-grey-dark.svg';
import SpinnerGreyLight from '../../../assets/spinners/spinner-grey-light.svg';
import SpinnerGreyVeryLight from '../../../assets/spinners/spinner-grey-very-light.svg';
import SpinnerError from '../../../assets/spinners/spinner-error.svg';
import SpinnerWarning from '../../../assets/spinners/spinner-warning.svg';
import SpinnerSuccess from '../../../assets/spinners/spinner-success.svg';
import SpinnerNotice from '../../../assets/spinners/spinner-notice.svg';
import SpinnerWhite from '../../../assets/spinners/spinner-white.svg';

// Spinner Component
const Spinner = React.forwardRef((props, ref) => {

	// State
	const [isHidden,
		setIsHidden] = useState(true);

	// Show loader
	const show = () => {
		setIsHidden(false);
	};

	// Hide loader
	const hide = () => {
		setIsHidden(true);
	};

    // Check if visible prop is passed
    useEffect(() => {
        if (props.visible !== undefined) {
            if (props.visible) {
                show();
            } else {
                hide();
            }
        }
    }, [props.visible]);

	// Open functions to outside
	useImperativeHandle(ref, () => ({ show, hide }), []);

	// Work out classes and spinner
	let spinner = SpinnerWhite;
	let classes = "spinner";
	if (props.noPosition) {
		classes = "spinner-no-position";
	}
	if (props.small) {
		classes += " spinner-small";
	}

	switch (props.theme) {
	case "primary":
		spinner = SpinnerPrimary;
		classes += " spinner-primary";
		break;
	case "primaryDark":
		spinner = SpinnerPrimaryDark;
		classes += " spinner-primary-dark";
		break;
	case "primaryLight":
		spinner = SpinnerPrimaryLight;
		classes += " spinner-primary-light";
		break;
	case "grey":
		spinner = SpinnerGrey;
		classes += " spinner-grey";
		break;
	case "greyDark":
		spinner = SpinnerGreyDark;
		classes += " spinner-grey-dark";
		break;
	case "greyLight":
		spinner = SpinnerGreyLight;
		classes += " spinner-grey-light";
		break;
	case "greyVeryLight":
		spinner = SpinnerGreyVeryLight;
		classes += " spinner-grey-very-light";
		break;
	case "error":
		spinner = SpinnerError;
		classes += " spinner-error";
		break;
	case "warning":
		spinner = SpinnerWarning;
		classes += " spinner-warning";
		break;
	case "success":
		spinner = SpinnerSuccess;
		classes += " spinner-success";
		break;
	case "notice":
		spinner = SpinnerNotice;
		classes += " spinner-notice";
		break;
	case "white":
		spinner = SpinnerWhite;
		classes += " spinner-white";
		break;
	}

	if (props.center) {
		classes += " spinner-center";
	}

	// Return component
	return (

		<div className={isHidden ? `${classes} spinner-hidden` : classes}>

			<div className={props.small ? "spinner-image spinner-image-small" : "spinner-image"}>

				<img src={spinner} alt="Loading..." />

			</div>

			{props.small ?
				''
				:
				<div className="spinner-text">{props.label ? props.label : "Loading..."}</div>
			}


		</div>

	);

});

Spinner.propTypes = {
	small: PropTypes.bool,
	theme: PropTypes.string,
	label: PropTypes.string,
	center: PropTypes.bool,
    visible: PropTypes.bool
};

// Export
export default Spinner;
