import store from 'store';
import defaults from './defaults';
import api from './api';
import notification from './notification';

// Check if session exists in local storage
const exists = () => {
    return store.get(defaults.consts.SESSION_KEY) !== undefined;
}

// Logged in
const loggedIn = (user, token) => {

    // Save user
    setUser(user);

    // Save new session
    setSession(token);
    store.set(defaults.consts.SESSION_CHECKED, Date.now());

};

// Check session
const check = async () => {

    // Check if needs checking
    let lastChecked = store.get(defaults.consts.SESSION_CHECKED);
    let now = Date.now();
    let interval = defaults.consts.SESSION_EXPIRY;

    if ((lastChecked + interval) < now || lastChecked === undefined) {

        if (exists) {

            // Send request
            let response = await api.post('/user/session/check', {}, true);

            if (response.status === 200) {

                if (response.data.isValid) {

                    // Save user
                    setUser(response.data.user);

                    // Save new session
                    setSession(response.session.token);
                    store.set(defaults.consts.SESSION_CHECKED, Date.now());

                    return true;

                }

                // Remove session
                remove();

                // Show error
                notification.error("Session expired");

                return false;

            } else {

                // Remove session
                remove();

                // Show error
                notification.error("Session expired");

                return false;

            }

        }

        return false

    }

    // Doesn't need checking yet so assume everything is ok
    return true;

};

// Get session
const get = () => {
    return {
        token: store.get(defaults.consts.SESSION_KEY),
        user: store.get(defaults.consts.USER_KEY)
    }
}

// Set session
const setSession = (session) => {
    store.set(defaults.consts.SESSION_KEY, session);
}

// Set user
const setUser = (user) => {
    store.set(defaults.consts.USER_KEY, user);
}

// Remove
const remove = () => {
    store.remove(defaults.consts.SESSION_KEY);
    store.remove(defaults.consts.USER_KEY);
    store.remove(defaults.consts.FREEAGENT_CONNECTED);
    store.remove(defaults.consts.SESSION_CHECKED);
    store.remove(defaults.consts.CACHED_ENDPOINTS);
};

export default {
    check: check,
    get: get,
    setSession: setSession,
    setUser: setUser,
    remove: remove,
    exists: exists,
    loggedIn: loggedIn
}