// sort objects (asc by default)
const sortObjs = (data, key, isDesc = false) => {

    let sortedData = data.sort((a,b) => {

        let x = a[key].toLowerCase();
        let y = b[key].toLowerCase();

        if(isDesc){

            if(x<y){return 1;}
            if(x>y){return -1;}
            return 0;

        } else {

            if(x>y){return 1;}
            if(x<y){return -1;}
            return 0;

        }

    });

    return sortedData;

};

// Make camel case
const camelCase = (string) => {

    let output = "";
    if (/\s/g.test(string)) {
        let stringSplit = string.split(" ");
        stringSplit.forEach((part, index) => {
            if (index === 0) {
                output = part.toLowerCase();
            } else {
                part = part.toLowerCase();
                part = part.charAt(0).toUpperCase() + part.slice(1);
                output += part;
            }
        });
    } else {
        output = string.toLowerCase();
    }

    return output;

};

// Pretty date
const prettyDate = (string, emptyText = "") => {

    if (string) {

        // Split parts
        let parts = string.split("T");
        let date = parts[0];
        let time = parts[1];

        // Format date
        let dateParts = date.split("-");
        let day = dateParts[0];
        let month = dateParts[1];
        let year = dateParts[2];

        switch (day) {
            case "01":
                day = "1st";
                break;
            case "02":
                day = "2nd";
                break;
            case "03":
                day = "3rd";
                break;
            case "04":
                day = "4th";
                break;
            case "05":
                day = "5th";
                break;
            case "06":
                day = "6th";
                break;
            case "07":
                day = "7th";
                break;
            case "08":
                day = "8th";
                break;
            case "09":
                day = "9th";
                break;
            case "10":
                day = "10th";
                break;
            case "11":
                day = "11th";
                break;
            case "12":
                day = "12th";
                break;
            case "13":
                day = "13th";
                break;
            case "14":
                day = "14th";
                break;
            case "15":
                day = "15th";
                break;
            case "16":
                day = "16th";
                break;
            case "17":
                day = "17th";
                break;
            case "18":
                day = "18th";
                break;
            case "19":
                day = "19th";
                break;
            case "20":
                day = "20th";
                break;
            case "21":
                day = "21st";
                break;
            case "22":
                day = "22nd";
                break;
            case "23":
                day = "23rd";
                break;
            case "24":
                day = "24th";
                break;
            case "25":
                day = "25th";
                break;
            case "26":
                day = "26th";
                break;
            case "27":
                day = "27th";
                break;
            case "28":
                day = "28th";
                break;
            case "29":
                day = "29th";
                break;
            case "30":
                day = "30th";
                break;
            case "31":
                day = "31st";
                break;
        }

        switch (month) {
            case "01":
                month = "January";
                break;
            case "02":
                month = "February";
                break;
            case "03":
                month = "March";
                break;
            case "04":
                month = "April";
                break;
            case "05":
                month = "May";
                break;
            case "06":
                month = "June";
                break;
            case "07":
                month = "July";
                break;
            case "08":
                month = "August";
                break;
            case "09":
                month = "September";
                break;
            case "10":
                month = "October";
                break;
            case "11":
                month = "November";
                break;
            case "12":
                month = "December";
                break;
        }

        // Return
        return `${day} ${month} ${year} - ${time}`;

    }

    return emptyText;

};

// File size
const fileSize = (bytes, decimals = 2) => {

    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];

}

// Project date
const projectDate = (date) => {

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 9) {
        month = `0${month}`;
    }

    if (day < 9) {
        day = `0${day}`;
    }

    return `${year}-${month}-${day}`;

}

export default {
    sortObjs: sortObjs,
    camelCase: camelCase,
    prettyDate: prettyDate,
    fileSize: fileSize,
    projectDate: projectDate
}