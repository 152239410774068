// Imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './projectinfopopup.scss';

// Components
import ProjectPopupHeader from '../ProjectPopupHeader';
import Form from '../../../Form';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import DateInput from '../../../inputs/DateInput';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';
import format from '../../../../utils/format';

// ProjectInfoPopup component
const ProjectInfoPopup = (props) => {

    // Refs
    const nameRef = useRef();
    const clientRef = useRef();
    const venueRef = useRef();
    const descriptionRef = useRef();
    const statusRef = useRef();

    // State
    const [clients, setClients] = useState([{
        value: "loading",
        title: "Loading..."
    }]);
    const [startDate, setStartDate] = useState(new Date(props.project.dates.start.raw));
    const [endDate, setEndDate] = useState(new Date(props.project.dates.end.raw));

    // Get clients
    useEffect(() => {

        getClients();

    }, []);

    const getClients = async (force = false) => {

        setClients([{
            value: "loading",
            title: "Loading..."
        }]);

        props.spinnerRef.current.show();

        let response = await api.get('/clients', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.clients, 'name', 'asc');
            let clientsOptions = [{
                value: "select",
                title: "Select a client..."
            }];
            formatted.forEach((client) => {
                let title = client.name;
                if (client.company) {
                    title = `${client.name} - ${client.company}`
                }
                clientsOptions.push({
                    value: client._id,
                    title: title
                });
            });
            setClients(clientsOptions);
            notification.success('Clients loaded.');
            props.spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong while loading clients, please try again soon.");
            props.spinnerRef.current.hide();

        }

    }

    // Submit callback
    const submitCallback = async () => {

        // Get values
        let name = nameRef.current.value;
        let client = clientRef.current.value;
        let venue = venueRef.current.value;
        let description = descriptionRef.current.value;
        let status = statusRef.current.value;
        let startDateValue = format.projectDate(startDate);
        let endDateValue = format.projectDate(endDate);

        // Validate
        let readyToSubmit = true;
        if (name.length < 1) {
            notification.error("Please enter a project name.");
            readyToSubmit = false;
        }
        if (client === "loading" || client === "select") {
            notification.error("Please select a client.");
            readyToSubmit = false;
        }
        if (!startDate) {
            notification.error("Please select a start date.");
            readyToSubmit = false;
        }
        if (!endDate) {
            notification.error("Please select an end date.");
            readyToSubmit = false;
        }
        if (startDate > endDate) {
            notification.error("Start date must be before end date.");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            props.spinnerRef.current.show();

            let response = await api.post('/project/update', {
                project: {
                    _id: props.project._id,
                    name: name,
                    client: client,
                    status: status,
                    venue: venue,
                    description: description,
                    startDate: startDateValue,
                    endDate: endDateValue,
                    portal: {
                        id: props.project.portal.id,
                        password: props.project.portal.password
                    }
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    props.spinnerRef.current.hide();

                } else {

                    notification.success(`Project updated.`);
                    props.spinnerRef.current.hide();
                    props.successCallback(response.data.project);

                }

            } else {

                notification.error(`Error updating project.`);
                props.spinnerRef.current.hide();


            }

        }

    }

    // Clients reload callback
    const clientsReloadCallback = async () => {
        getClients(true);
    }

    // Return component
    return (

        <div className="project-info-popup">

            <ProjectPopupHeader title="Update Info" />

            <Form submitTitle='Update' submitCallback={submitCallback} noMargin>

                <TextInput title="Name" type="text" initialValue={props.project.name} ref={nameRef} />

                <TextInput title="Venue" type="text" initialValue={props.project.venue} ref={venueRef} />

                <TextInput title="Description" type="text" initialValue={props.project.description} longText ref={descriptionRef} />

                <SelectInput title="Status" options={[
                    {
                        value: 'active',
                        title: 'Active'
                    },
                    {
                        value: 'completed',
                        title: 'Completed'
                    },
                    {
                        value: 'cancelled',
                        title: 'Cancelled'
                    }
                ]} initialValue={props.project.status} ref={statusRef} />

                <SelectInput title="Client" options={clients} initialValue={props.project.client._id} showReload reloadCallback={clientsReloadCallback} ref={clientRef} />

                <div className="form-dates">

                    <DateInput title="Start Date" value={startDate} setter={setStartDate} />

                    <DateInput title="End Date" value={endDate} setter={setEndDate} />

                </div>

            </Form>

        </div>

    )

};

ProjectInfoPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired
};

// Export Component
export default ProjectInfoPopup;
