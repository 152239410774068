// Imports
import React, { useRef, useState } from 'react';
import './usersaddview.scss';

// Components
import Meta from '../../../Meta';
import TextInput from '../../../inputs/TextInput';
import Spinner from '../../../spinners/Spinner';
import CheckboxInput from '../../../inputs/CheckboxInput';
import Form from '../../../Form';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';
import validate from '../../../../utils/validate';

// UsersAddView component
const UsersAddView = () => {

    let permissionsDefaults = [
        {
            value: "crew",
            isChecked: false,
        },
        {
            value: "stock",
            isChecked: false,
        },
        {
            value: "clients",
            isChecked: false,
        },
        {
            value: "projects",
            isChecked: false,
        },
        {
            value: "users",
            isChecked: false,
        },
        {
            value: "freeagent",
            isChecked: false,
        }
    ]

    // Refs
    let spinnerRef = useRef();
    let nameRef = useRef(null);
    let emailRef = useRef(null);
    let passwordRef = useRef(null);

    // State
    const [permissionsValues, setPermissionsValues] = useState(permissionsDefaults);

    // Submit clicked
    const submitClicked = async () => {

        // Get values
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const permissions = permissionsValues;

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(name)) {
            readyToSubmit = false;
            notification.error('Please enter a valid full name');
        }

        if (!validate.email(email)) {
            readyToSubmit = false;
            notification.error('Please enter a valid email address');
        }

        if (password.length < 8) {
            readyToSubmit = false;
            notification.error('Password must be at least 8 characters');
        }

        let permissionsCount = 0;
        permissions.forEach(permission => {
            if (permission.isChecked) {
                permissionsCount++;
            }
        });

        if (permissionsCount === 0) {
            readyToSubmit = false;
            notification.error('Please select at least one permission');
        }

        if (readyToSubmit) {

            spinnerRef.current.show();

            let response = await api.post('/users/add', {
                user: {
                    name: name,
                    email: email,
                    password: password,
                    permissions: permissions,
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success(`${name} added.`);
                    reset();
                    spinnerRef.current.hide();

                }

            } else {

                notification.error(`Error adding ${name}.`);
                spinnerRef.current.hide();


            }

        }

    }

    // Reset
    const reset = () => {

        setPermissionsValues([...permissionsDefaults]);

        // Scroll to top of page
        window.scrollTo(0, 0);

        setTimeout(() => {
            nameRef.current.value = "";
            emailRef.current.value = "";
            passwordRef.current.value = "";
        }, 25);

    }

    // Checkbox callback
    const checkboxCallback = (index) => {

        let newPermissionsValues = permissionsValues;

        newPermissionsValues[index].isChecked = !newPermissionsValues[index].isChecked;

        setPermissionsValues([...newPermissionsValues]);

    }

    // Return component
    return (

        <div className="view">

            <Meta
                title="Users - Add"
            />

            <h1>Add User</h1>

            <Form submitTitle='Add User' submitCallback={submitClicked}>

                <TextInput title="Full Name" type="text" ref={nameRef} />

                <TextInput title="Email" type="email" ref={emailRef} />

                <TextInput title="Password" type="password" ref={passwordRef} />

                <CheckboxInput title="Permissions" values={permissionsValues} callback={checkboxCallback} hideOther />

            </Form>

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default UsersAddView;
