// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './checkboxinput.scss';

// Components
import TextInput from '../TextInput';

// CheckboxInput component
const CheckboxInput = React.forwardRef((props, ref) => {

    // Update checkbox
    const updateCheckbox = (elementId) => {

        document.getElementById(elementId).click();

    };

    // Return component
    return (

        <div className="checkboxinput">

            <span>{props.title}:</span>

            {props.values.map((value, index) => (

                <div className="checkboxinput-item" key={index}>

                    <input type="checkbox" id={`${props.title.replace(/\s/g,'').toLowerCase()}_${index}`} name={props.title.replace(/\s/g,'').toLowerCase()} value={value.value} checked={value.isChecked} onChange={() => {
                        props.callback(index);
                    }}></input>
                    <label onClick={() => { updateCheckbox(`${props.title.replace(/\s/g,'').toLowerCase()}_${index}`) }}>{value.value}</label>

                </div>

            ))}

            {props.hideOther ?
                ''
            :
                <div className="checkboxinput-item-other">

                        <label>Other (Comma Separated):</label>
                        <TextInput title="Other" type="text" hideLabel ref={ref} initialValue={props.otherInitialValue}/>

                </div>
            }

        </div>

    )

});

CheckboxInput.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    otherInitialValue: PropTypes.string,
    hideOther: PropTypes.bool,
}

// Export Component
export default CheckboxInput;
