// Store Consts
const SESSION_KEY = 'vms_session';
const SESSION_CHECKED = 'vms_session_checked';
const USER_KEY = 'vms_user';
const CACHED_ENDPOINTS = 'vms_cached_endpoints';
const FREEAGENT_CONNECTED = 'vms_freeagent_connected';

// Consts
const SESSION_EXPIRY = 3600000; // 1 hour
const CACHE_EXPIRY = 86400000; // 24 hours

export default {
    consts: {
        SESSION_KEY: SESSION_KEY,
        SESSION_CHECKED: SESSION_CHECKED,
        USER_KEY: USER_KEY,
        FREEAGENT_CONNECTED: FREEAGENT_CONNECTED,
        CACHED_ENDPOINTS: CACHED_ENDPOINTS,
        SESSION_EXPIRY: SESSION_EXPIRY,
        CACHE_EXPIRY: CACHE_EXPIRY
    }
}