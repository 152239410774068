// Imports
import React, { useState } from 'react';
import './settingspage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import SettingsDetailsView from '../../views/settings/SettingsDetailsView';

// Utils
import session from '../../../utils/session';

// SettingsPage component
const SettingsPage = () => {

    let sessionData = session.get();
    let permissions = sessionData.user.permissions;

    // State
    const [activeView, setActiveView] = useState("Account Details");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    let nav = [
        {
            title: "Account Details",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="settingspage">

            <Meta
                title="Settings"
            />

            <SideNavigation nav={nav} title="SETTINGS" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "Account Details" ? <SettingsDetailsView /> : null}

        </div>

    )

};

// Export Component
export default SettingsPage;
