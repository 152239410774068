// Imports
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './projectcreweditpopup.scss';

// Components
import TextInput from '../../../inputs/TextInput';
import SubmitButton from '../../../buttons/SubmitButton';
import DateInput from '../../../inputs/DateInput';

// Utils
import notification from '../../../../utils/notification';
import api from '../../../../utils/api';
import format from '../../../../utils/format';

// ProjectCrewEditPopup component
const ProjectCrewEditPopup = (props) => {

    // State
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    // Refs
    const payInputRef = useRef(null);
    const venueInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setStartDate(new Date(props.project.startDate));
            setEndDate(new Date(props.project.endDate));
        }, 50);
    }, []);

    // Submit button clicked
    const submitButtonClicked = async () => {

        // Create object
        let details = {
            _id: props.crew._id,
            crew: props.crew.crew,
            pay: payInputRef.current.value,
            venue: venueInputRef.current.value,
            description: descriptionInputRef.current.value,
            startDate: format.projectDate(startDate),
            endDate: format.projectDate(endDate)
        };

        props.spinnerRef.current.show();

        let response = await api.post('/project/crew/update', {
            project: props.project,
            details: details
        }, true);

        if (response.status === 200) {

            if (response.error.code === "API_INTERNAL_24") {

                notification.error(response.error.detail);
                props.spinnerRef.current.hide();

            } else {

                notification.success(`Crew updated.`);
                props.spinnerRef.current.hide();
                props.successCallback(response.data.project);

            }

        } else {

            notification.error(`Error updating crew.`);
            props.spinnerRef.current.hide();


        }

    };

    // Return component
    return (

        <div className="pics-popup">

            <div className="pics-popup-header">
                
                <h2>Edit Crew Details</h2>

            </div>

            <div className="pics-popup-content">

                <TextInput title="Day Rate" placeholder="175" type="number" initialValue={props.crew.pay} ref={payInputRef} />

                <TextInput title="Venue" type="text" initialValue={props.crew.venue} ref={venueInputRef} />

                <TextInput title="Description" type="text" longText initialValue={props.crew.description} ref={descriptionInputRef} />
                
                <div className="form-dates">

                    <DateInput title="Start Date" value={startDate} setter={setStartDate} />

                    <DateInput title="End Date" value={endDate} setter={setEndDate} />

                </div>

            </div>    

            <SubmitButton title="Next" callback={() => { submitButtonClicked(); }} />  

        </div>

    )

};

ProjectCrewEditPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired,
    crew: PropTypes.object.isRequired
};

// Export Component
export default ProjectCrewEditPopup;
