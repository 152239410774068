// Imports
import React, { useState, useEffect, useRef } from 'react';
import './usersviewview.scss';

// Components
import Meta from '../../../Meta';
import Spinner from '../../../spinners/Spinner';
import TextInput from '../../../inputs/TextInput';
import CheckboxInput from '../../../inputs/CheckboxInput';
import SubmitButton from '../../../buttons/SubmitButton';
import Table from '../../../Table';
import TableDataPopup from '../../../popups/TableDataPopup';

// Utils
import api from '../../../../utils/api';
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import validate from '../../../../utils/validate';

// UsersViewView component
const UsersViewView = () => {

    let permissionsDefaults = [
        {
            value: "crew",
            isChecked: false,
        },
        {
            value: "stock",
            isChecked: false,
        },
        {
            value: "clients",
            isChecked: false,
        },
        {
            value: "projects",
            isChecked: false,
        },
        {
            value: "users",
            isChecked: false,
        },
        {
            value: "freeagent",
            isChecked: false,
        }
    ]

    // Refs
    const spinnerRef = useRef(null);
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    // State
    const [users, setUsers] = useState([]);
    const [usersStatic, setUsersStatic] = useState([]);
    const [selectedUserDetailsEditing, setSelectedUserDetailsEditing] = useState(false);
    const [selectedUserIsOpen, setSelectedUserIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [permissionsValues, setPermissionsValues] = useState(permissionsDefaults);

    // Get clients
    useEffect(() => {

        getUsers();

    }, []);

    // Get users
    const getUsers = async (force = false) => {

        spinnerRef.current.show();

        let response = await api.get('/users', true, force);

        if (response.status === 200) {

            let formatted = format.sortObjs(response.data.users, 'name', 'asc');
            formatted.forEach((user) => {
                user.lastLogin = format.prettyDate(user.lastLogin, "Never logged in.");
            });
            setUsers(formatted);
            setUsersStatic(formatted);
            notification.success('Users loaded.');
            spinnerRef.current.hide();

        } else {

            notification.error("Something went wrong, please try again soon.");
            spinnerRef.current.hide();

        }

    }

    // Checkbox callback
    const checkboxCallback = (index) => {

        let newPermissionsValues = permissionsValues;

        newPermissionsValues[index].isChecked = !newPermissionsValues[index].isChecked;

        setPermissionsValues([...newPermissionsValues]);

    }

    // User clicked
    const tableRowClickedCallback = (user) => {

        let permissions = [];
        permissionsDefaults.forEach((permission) => {

            let isChecked = false;

            switch (permission.value) {
                case "crew":
                    isChecked = user.permissions.crew;
                    break;
                case "stock":
                    isChecked = user.permissions.stock;
                    break;
                case "clients":
                    isChecked = user.permissions.clients;
                    break;
                case "projects":
                    isChecked = user.permissions.projects;
                    break;
                case "users":
                    isChecked = user.permissions.users;
                    break;
                case "freeagent":
                    isChecked = user.permissions.freeagent;
                    break;
            }

            permissions.push({
                value: permission.value,
                isChecked: isChecked
            })

        });

        setSelectedUser(user);
        setPermissionsValues([...permissions]);
        setSelectedUserIsOpen(true);

    };

    // Close selected user
    const closeSelectedUser = () => {

        setSelectedUser({});
        setSelectedUserDetailsEditing(false);
        setSelectedUserIsOpen(false);

    }

    // Search input changed
    const searchInputChangedCallback = (value) => {

        let results = [];

        if (!value || value === "" || value.length < 1) {

            results = usersStatic;

        } else {

            usersStatic.forEach((user) => {

                // Check name
                if (user.name.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === user.email)) {
                        results.push(user);
                    }
                }

                // Check email
                if (user.email.toLowerCase().includes(value.toLowerCase())) {
                    if (!results.some(x => x.email === user.email)) {
                        results.push(user);
                    }
                }

            });

        }

        setUsers(results);

    };

    // Update user callback
    const updateUserCallback = async () => {

        // Get values
        let name = nameRef.current.value;
        let email = emailRef.current.value;
        let password = passwordRef.current.value;
        let permissions = permissionsValues

        // Validate
        let readyToSubmit = true;

        if (!validate.fullName(name)) {
            notification.error("Please enter a valid full name");
            readyToSubmit = false;
        }

        if (!validate.email(email)) {
            notification.error("Please enter a valid email address");
            readyToSubmit = false;
        }

        if (password) {
            if (password.length < 8) {
                notification.error("Please enter a password that is at least 8 characters long");
                readyToSubmit = false;
            }
        }

        let permissionsCount = 0;
        permissions.forEach((permission) => {
            if (permission.isChecked) {
                permissionsCount++;
            }
        });
        if (permissionsCount === 0) {
            notification.error("Please select at least one permission");
            readyToSubmit = false;
        }

        // Submit
        if (readyToSubmit) {

            spinnerRef.current.show();

            let user = {
                _id: selectedUser._id,
                name: name,
                email: email,
                password: password,
                permissions: permissions
            }

            let response = await api.post('/users/update', {
                user: user
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("User updated.");
                    spinnerRef.current.hide();

                    setSelectedUser(user);
                    setSelectedUserDetailsEditing(false);

                }

            } else {

                notification.error("Error updating user.");
                spinnerRef.current.hide();

            }

        }

    }

    // Selected user delete
    const selectedUserDelete = async () => {

        if (window.confirm(`Are you sure you want to delete ${selectedUser.name}? This cannot be undone and will complete remove them from the system.`)) {

            spinnerRef.current.show();

            let response = await api.post('/users/delete', {
                user: selectedUser
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    spinnerRef.current.hide();

                } else {

                    notification.success("User deleted.");
                    spinnerRef.current.hide();
                    let userIndex = users.findIndex(x => x._id === selectedUser._id);
                    let userStaticIndex = usersStatic.findIndex(x => x._id === selectedUser._id);
                    let tempUsers = users;
                    let tempUsersStatic = usersStatic;
                    if (userIndex > -1) {
                        tempUsers.splice(userIndex, 1);
                    }
                    if (userStaticIndex > -1) {
                        tempUsersStatic.splice(userStaticIndex, 1);
                    }
                    setUsers(tempUsers);
                    setUsersStatic(tempUsersStatic);
                    closeSelectedUser();

                }

            } else {

                notification.error("Error deleting user.");
                spinnerRef.current.hide();

            }

        }

    };

    // Return component
    return (

        <div className="view">

            <Meta
                title="Users - View"
            />

            <h1>Users</h1>

            <Table headerItems={[
                {
                    title: "Name",
                    width: "290px",
                    isSortable: true,
                },
                {
                    title: "Email",
                    width: "450px",
                    isSortable: true,
                },
                {
                    title: "Last Login",
                    width: "340px",
                    isSortable: true,
                }
            ]} 
            data={users} 
            tableRowClickedCallback={tableRowClickedCallback} 
            searchInputChangedCallback={searchInputChangedCallback} 
            reloadCallback={() => { getUsers(true); }}
            />

            {selectedUserIsOpen ?

                <TableDataPopup closeCallback={closeSelectedUser} isEditingState={selectedUserDetailsEditing} setIsEditingState={setSelectedUserDetailsEditing}>

                    {selectedUserDetailsEditing ?

                    <div className="tdp-section">

                        <TextInput title="Full Name" type="text" ref={nameRef} initialValue={selectedUser.name} />

                        <TextInput title="Email" type="email" ref={emailRef} initialValue={selectedUser.email} />

                        <TextInput title="Password" type="password" ref={passwordRef} />

                        <CheckboxInput title="Permissions" values={permissionsValues} callback={checkboxCallback} hideOther />

                        <SubmitButton title="Update" callback={updateUserCallback} />

                    </div>

                    :

                    <div className="tdp-section">

                        <span><strong>Name:</strong> {selectedUser.name}</span>

                        <span><strong>Email:</strong> <a href={`mailto:${selectedUser.email}`}>{selectedUser.email}</a></span>

                        <span><strong>Last Login:</strong> {selectedUser.lastLogin}</span>

                        <span><strong>Permissions:</strong></span>

                        <div className="tdp-section-tags">

                            {selectedUser.permissions.crew ? <div className="tdp-section-tag">Crew</div> : ''}
                            {selectedUser.permissions.stock ? <div className="tdp-section-tag">Stock</div> : ''}
                            {selectedUser.permissions.clients ? <div className="tdp-section-tag">Clients</div> : ''}
                            {selectedUser.permissions.projects ? <div className="tdp-section-tag">Projects</div> : ''}
                            {selectedUser.permissions.users ? <div className="tdp-section-tag">Users</div> : ''}
                            {selectedUser.permissions.freeagent ? <div className="tdp-section-tag">FreeAgent</div> : ''}

                        </div>

                    </div>

                    }

                    <div className="tdp-delete" onClick={() => { selectedUserDelete(); }}>Delete</div>

                </TableDataPopup>

            :
                ''
            }

            <Spinner small theme="white" ref={spinnerRef} />

        </div>

    )

};

// Export Component
export default UsersViewView;
