// Imports
import React, { useState } from 'react';
import './stockpage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import StockOverviewView from '../../views/stock/StockOverviewView';
import StockViewView from '../../views/stock/StockViewView';
import StockAddView from '../../views/stock/StockAddView';
import StockDamagesView from '../../views/stock/StockDamagesView';
import StockMaintenanceView from '../../views/stock/StockMaintenanceView';
import StockWriteOffsView from '../../views/stock/StockWriteOffsView';

// StockPage component
const StockPage = () => {

    // State
    const [activeView, setActiveView] = useState("Overview");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    const nav = [
        {
            title: "Overview",
            callback: navButtonClicked,
        },
        {
            title: "View",
            callback: navButtonClicked,
        },
        {
            title: "Add",
            callback: navButtonClicked,
        },
        {
            title: "Maintenance",
            callback: navButtonClicked,
        },
        {
            title: "Write Offs",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="stockpage">

            <Meta
                title="Stock"
            />

            <SideNavigation nav={nav} title="STOCK" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "Overview" ? <StockOverviewView /> : null}

            {activeView === "View" ? <StockViewView /> : null}

            {activeView === "Add" ? <StockAddView /> : null}

            {activeView === "Maintenance" ? <StockMaintenanceView /> : null}

            {activeView === "Write Offs" ? <StockWriteOffsView /> : null}

        </div>

    )

};

// Export Component
export default StockPage;
