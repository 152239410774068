// Imports
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './projecteditstockpopup.scss';

// Components
import Form from '../../../Form';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';

// Utils
import notification from '../../../../utils/notification';
import format from '../../../../utils/format';
import api from '../../../../utils/api';

// ProjectEditStockPopup component
const ProjectEditStockPopup = (props) => {

    const [stockItem, setStockItem] = useState(null);

    // Refs
    const quantityRef = useRef(null);

    useEffect(() => {

        let stock = props.project.availability.find(x => x._id === props.stockItem.item._id);
        let updatedStockItem = props.stockItem
        updatedStockItem.item.available = parseInt(stock.available) + parseInt(props.stockItem.quantity);
        setStockItem(updatedStockItem);

    }, []);

    // Submit clicked
    const submitClicked = async () => {

        // Get values
        let item = stockItem;
        let quantity = quantityRef.current.value;

        // Validate
        let readyToSubmit = true;
        if (!quantity) {
            notification.error("Please enter a quantity");
            readyToSubmit = false;
        }
        if (parseInt(quantity) > parseInt(item.item.quantity)) {
            notification.error("Quantity exceeds stock quantity");
            readyToSubmit = false;
        }
        if (parseInt(quantity) > parseInt(item.item.available)) {
            notification.error("Quantity exceeds available stock quantity");
            readyToSubmit = false;
        }

        if (readyToSubmit) {

            props.spinnerRef.current.show();

            let response = await api.post(`/project/stock/update`, {
                stock: {
                    item: item,
                    quantity: parseInt(quantity),
                    project: props.project._id,
                    projectStock: props.project.stock,
                    startDate: props.project.dates.start.raw,
                    endDate: props.project.dates.end.raw
                }
            }, true);

            if (response.status === 200) {

                if (response.error.code === "API_INTERNAL_24") {

                    notification.error(response.error.detail);
                    props.spinnerRef.current.hide();

                } else {

                    notification.success('Stock added.');
                    props.spinnerRef.current.hide();
                    props.successCallback(response.data.project);

                }


            } else {

                notification.error("Something went wrong, please try again soon.");
                props.spinnerRef.current.hide();

            }

        }

    };

    // Return component
    return (

        <div className="pes-popup">

            <h2>Edit Stock</h2>

            {stockItem ?

                <Form submitTitle="Update" submitCallback={submitClicked}>

                    <span>{stockItem.item.name}</span>

                    <div className="pas-popup-available">
                            <span><strong>Quantity:</strong> {stockItem.item.quantity}</span>
                            <span><strong>Available:</strong> {stockItem.item.available}</span>
                    </div>

                    <TextInput title="Quantity" type="number" initialValue={stockItem.quantity} ref={quantityRef} />

                </Form>

            :
                null
            }

        </div>

    )

};

ProjectEditStockPopup.propTypes = {
    project: PropTypes.object.isRequired,
    spinnerRef: PropTypes.object.isRequired,
    successCallback: PropTypes.func.isRequired,
    stockItem: PropTypes.object.isRequired
};

// Export Component
export default ProjectEditStockPopup;
