// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './table.scss';

// Components
import TextInput from '../inputs/TextInput';

// Utils
import format from '../../utils/format';

// Assets
import ReloadIcon from '../../assets/icons/reload-icon-primary.png';
import StarIcon from '../../assets/icons/star-icon-dark.png';
import StarFilledIcon from '../../assets/icons/star-filled-icon-primary.png';

// Table component
const Table = (props) => {

    // Work out default sort key
    let defaultSortKey = null;
    let foundDefaultSortKey = false;
    props.headerItems.forEach((item, index) => {
        if (item.isSortable && !foundDefaultSortKey) {
            defaultSortKey = item.title.toLowerCase();
            foundDefaultSortKey = true;
        }
    });

    // State
    const [sortKey, setSortKey] = useState(defaultSortKey);
    const [sortOrder, setSortOrder] = useState("asc");

    // Format data
    let formattedData = [];
    const formatData = (dataToFormat) => {

        dataToFormat.forEach((item, index) => {
            let dataItem = [];
            props.headerItems.forEach((headerItem, headerIndex) => {
                dataItem.push(item[format.camelCase(headerItem.title)]);
            });
            formattedData.push(dataItem);
        });

    }
    formatData(props.data);

    // Sorting
    const upArrow = "↑";
    const downArrow = "↓";
    props.headerItems.forEach((item, index) => {

        if (format.camelCase(item.title) === sortKey) {

            if (sortOrder === "asc") {
                item.sortIcon = upArrow;
            } else {
                item.sortIcon = downArrow;
            }

        } else {
            item.sortIcon = "";
        }

    });

    // Update sort
    const updateSort = (title) => {

        let key = format.camelCase(title);

        let newSortOrder = "";

        if (sortKey === key) {

            if (sortOrder === "asc") {
                newSortOrder = "desc";
            } else {
                newSortOrder = "asc";
            }

        } else {

            newSortOrder = "asc";

        }

        let sorted = format.sortObjs(props.data, key, newSortOrder === "desc" ? true : false);
        formattedData = formatData(sorted);
        setSortKey(key);
        setSortOrder(newSortOrder);

    };

    // Searching
    const searchInputRef = useRef(null);
    const searchInputChanged = (value) => {
        props.searchInputChangedCallback(value);
    }

    // Clear search
    const clearSearch = () => {
        props.searchInputChangedCallback("");
        setTimeout(() => {
            searchInputRef.current.value = "";
        }, 25);
    };

    // Work out classes
    let classes = "table-controls";
    if (props.noMargin) {
        classes += " table-controls-no-margin";
    }

    // Return component
    return (

        <div className="table">

            <div className={classes}>

                <div className="table-search">

                    <div className="table-search-input">

                        <TextInput title="Search" type="text" ref={searchInputRef} onChangeCallback={searchInputChanged} />

                    </div>

                    <div className="table-search-clear" onClick={() => { clearSearch(); }}> Clear</div>

                </div>

                <div className="table-refresh" onClick={() => { props.reloadCallback(); }}>
                    <img src={ReloadIcon} alt="Reload" />
                </div>

            </div>

            <div className="table-header">

                {props.headerItems.map((item, index) => (

                    <div className="table-cell" style={{width:item.width}} key={index} onClick={item.isSortable ? () => { updateSort(item.title); } : null}>
                        <span>{item.title}</span>
                        <span className="table-cell-sort">{item.sortIcon}</span>
                    </div>

                ))}

            </div>

            {formattedData.map((item, index) => (

                <div className="table-row" key={index}>

                    {item.map((cellItem, cellIndex) => (

                        <div className="table-cell" style={{width:props.headerItems[cellIndex].width}} key={cellIndex}>

                            {(props.headerItems[cellIndex].title === "Download") && <span onClick={() => { props.downloadCallback(props.data[index]) }}>Download</span>}

                            {(props.headerItems[cellIndex].title === "Edit") && <span onClick={() => { props.editCallback(props.data[index]) }}>Edit</span>}

                            {(props.headerItems[cellIndex].title === "Favourite") && <img src={cellItem ? StarFilledIcon : StarIcon} alt={cellItem ? "Remove" : "Favourite"} onClick={ () => { props.favouriteCallback(props.data[index]) } } />}

                            {(props.headerItems[cellIndex].title !== "Download" && props.headerItems[cellIndex].title !== "Edit" && props.headerItems[cellIndex].title !== "Favourite") && <span onClick={() => { props.tableRowClickedCallback(props.data[index]) }}>{cellItem}</span>}

                        </div>

                    ))}

                </div>

            ))}

        </div>

    )

};

Table.propTypes = {
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    tableRowClickedCallback: PropTypes.func.isRequired,
    searchInputChangedCallback: PropTypes.func.isRequired,
    reloadCallback: PropTypes.func.isRequired,
    noMargin: PropTypes.bool,
    downloadCallback: PropTypes.func,
    editCallback: PropTypes.func,
    favouriteCallback: PropTypes.func
};

// Export Component
export default Table;
