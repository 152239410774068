// Imports
import React from 'react';
import PropTypes from 'prop-types';
import './tabledatapopup.scss';

// Components
import PrimaryButton from '../../buttons/PrimaryButton';

// TableDataPopup component
const TableDataPopup = (props) => {

    // Return component
    return (
        <div className="tdp">

            <div className="tdp-close" onClick={() => { props.closeCallback() }}>X</div>

            <div className="tdp-inner">

                <div className="tdp-header">

                    <h2>Details</h2>

                    <PrimaryButton title={props.isEditingState ? "Cancel" : "Edit"} callback={() => { props.setIsEditingState(!props.isEditingState) }} />

                </div>

                {props.children}

            </div>

        </div>

    )

};

TableDataPopup.propTypes = {
    closeCallback: PropTypes.func.isRequired,
    isEditingState: PropTypes.bool.isRequired,
    setIsEditingState: PropTypes.func.isRequired
}

// Export Component
export default TableDataPopup;
