// Imports
import React, { useState } from 'react';
import './userspage.scss';

// Components
import Meta from '../../Meta';
import SideNavigation from '../../navigation/SideNavigation';

// Views
import UsersViewView from '../../views/users/UsersViewView';
import UsersAddView from '../../views/users/UsersAddView';

// UsersPage component
const UsersPage = () => {

    // State
    const [activeView, setActiveView] = useState("View");

    // Nav button clicked
    const navButtonClicked = (view) => {
        setActiveView(view);
    }

    // Navigation
    const nav = [
        {
            title: "View",
            callback: navButtonClicked,
        },
        {
            title: "Add",
            callback: navButtonClicked,
        }
    ]

    // Return component
    return (

        <div className="userspage">

            <Meta
                title="Users"
            />

            <SideNavigation nav={nav} title="USERS" />

            <div style={{ width: '250px', height: '100px'}}></div>

            {activeView === "View" ? <UsersViewView /> : null}

            {activeView === "Add" ? <UsersAddView /> : null}

        </div>

    )

};

// Export Component
export default UsersPage;
