// Imports
import React from 'react';
import './stockmaintenanceview.scss';

// Components
import Meta from '../../../Meta';;

// StockMaintenanceView component
const StockMaintenanceView = () => {

    // Return component
    return (

        <div className="view">

            <Meta
                title="Stock - Maintenance"
            />

            <h1>Maintenance</h1>

        </div>


    )

};

// Export Component
export default StockMaintenanceView;
